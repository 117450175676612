import { getUser } from "actions/Auth";
import { createTrasaction } from "actions/Payments";
import { getMoneyBalance } from "actions/Payments";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import React, { useEffect, useState } from "react";
import { Table, Card, CardHeader, Form, Row, Col, FormGroup, ButtonGroup, Button, Input, Label, Modal } from "reactstrap";

const MoneyBalance = () => {
    const date = new Date();
    const [totalPay, setTotalPayAmount] = useState(null);
    const [isAllTransactions, setIsAllTransactions] = useState(false);
    const [allTransactions, setAllTransactiona] = useState(null);
    const [rSelected, setRSelected] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [user, setUser] = useState(null);
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");

    const [isData, setIsData] = useState({
        description: '',
        amount: '',
        payment_method: ''
    });
    const [errorMsg, setErrorMsg] = useState({});

    const calculateBalance = async () => {
        const payResponse = await getMoneyBalance();
        if (payResponse.data.length > 0) {
            setAllTransactiona(payResponse.data);
            setIsAllTransactions(true);
        } else {
            setIsAllTransactions(false);
        }
    }

    const fetchData = async () => {
        try {
            const response = await getUser();
            setUser(response);
        } catch (error) {
        }
    };

    useEffect(() => {
        calculateBalance();
        fetchData();
    }, []);

    const handleInputChange = (event) => {
        setIsError({ ...isError, [event.target.name]: "" });
        setIsData({ ...isData, [event.target.name]: event.target.value });
    };

    const arrangeData = async (value, transactionType) => {
        const insertData = {};
        const errors = {};
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const newDate = year + "-" + month + "-" + day;
        insertData.date = newDate;
        if (isData.description === '') {
            errors.description = 'Description is required.';
        } else {
            insertData.description = value.description;
        }
        if (isData.amount === '') {
            errors.amount = 'Amount is required.';
        }
        if (rSelected === null) {
            errors.transaction = 'Transaction type is required.';
        } else {
            if (transactionType === 1) {
                insertData.amount = -value.amount;
            }
            if (transactionType === 2) {
                insertData.amount = +(value.amount);
            }
        }
        if (isData.payment_method === '') {
            errors.payment_method = 'Payment Method is required.';
        } else {
            insertData.payment_method = isData.payment_method
        }
        insertData.userId = +(user.id);

        if (Object.keys(errors).length > 0) {
            setIsError(errors);
            return false;
        } else {
            return insertData;
        }
    };

    const insertNewTransaction = async () => {
        try {
            const insertData = await arrangeData(isData, rSelected);
            if (insertData !== false) {
                setinsertButtonDisabled(true);
                const body = JSON.stringify(insertData);
                const response = await createTrasaction(body);
                if (response.data.success === true) {
                    setSuccess(true);
                    setSuccessMessage(response.data.message);
                    setIsData({
                        description: '',
                        amount: '',
                        payment_method: ''
                    });
                    setSelectedType('')
                    setinsertButtonDisabled(false);
                    await calculateBalance();
                } else {
                    setFailed(true);
                    setinsertButtonDisabled(false);
                    setErrorMessage(response.data.message)
                }
            }
        } catch (error) {
            setFailed(true);
            setinsertButtonDisabled(false);
            setErrorMessage(error.message);
        }
    }
    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Card className="mb-4 shadow">
                    <CardHeader className="border-0">
                        <h3 className="mb-0">Add New Transaction</h3>
                    </CardHeader>
                    <Form className="ml-4 mb-4 mr-4">
                        <Row>
                            <Col md="12" lg="7">
                                <FormGroup>
                                    <ButtonGroup>
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => {setRSelected(1);
                                                setIsError({ ...isError, transaction: '' });}
                                            }
                                            active={rSelected === 1}
                                        >
                                            Credit
                                        </Button>
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => {setRSelected(2);
                                                setIsError({ ...isError, transaction: '' });
                                            }}
                                            active={rSelected === 2}
                                        >
                                            Debit
                                        </Button>
                                    </ButtonGroup>
                                    {isError.transaction && (
                                        <p className="text-danger">{isError.transaction}</p>
                                    )}
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Input
                                                id="description"
                                                placeholder="Description"
                                                type="text"
                                                value={isData.description}
                                                onChange={handleInputChange}
                                                name="description"
                                            />
                                            {isError.description && (
                                                <p className="text-danger">{isError.description}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Amount</Label>
                                            <Input
                                                id="amount"
                                                type="text"
                                                placeholder="amount"
                                                value={isData.amount}
                                                name="amount"
                                                pattern="\d*"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        setIsData({ ...isData, amount: Number(value) });
                                                        setIsError({ ...isError, amount: '' });
                                                    }
                                                }}
                                            />
                                            {isError.amount && (
                                                <p className="text-danger">{isError.amount}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                            <Label>Payment Method</Label>
                                            <div className="radioOptions">
                                                <FormGroup check>
                                                    <Input
                                                        id="cashRadio"
                                                        name="typeRadio"
                                                        type="radio"
                                                        checked={selectedType === 'CASH'}
                                                        onChange={() => {
                                                            setSelectedType('CASH');
                                                            setIsData((prevData) => ({ ...prevData, payment_method: 'CASH' }));
                                                            setIsError({ ...isError, payment_method: '' });
                                                        }}
                                                        style={{ transform: 'scale(1.5)' }}
                                                    />
                                                    {' '}
                                                    <Label check for="cashRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                                                        Cash
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        id="bocRadio"
                                                        name="typeRadio"
                                                        type="radio"
                                                        checked={selectedType === 'BOC'}
                                                        onChange={() => {
                                                            setSelectedType('BOC');
                                                            setIsData((prevData) => ({ ...prevData, payment_method: 'BOC' }));
                                                            setIsError({ ...isError, payment_method: '' });

                                                        }}
                                                        style={{ transform: 'scale(1.5)' }}
                                                    />
                                                    {' '}
                                                    <Label check for="bocRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }} >
                                                        BOC
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        id="sampathRadio"
                                                        name="typeRadio"
                                                        type="radio"
                                                        checked={selectedType === 'SAMPATH'}
                                                        onChange={() => {
                                                            setSelectedType('SAMPATH');
                                                            setIsData((prevData) => ({ ...prevData, payment_method: 'SAMPATH' }));
                                                            setIsError({ ...isError, payment_method: '' });
                                                        }}
                                                        style={{ transform: 'scale(1.5)' }}
                                                    />
                                                    {' '}
                                                    <Label check for="sampathRadio" className="ml-2" style={{ fontSize: "18px" }} >
                                                        Sampath
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                            {isError.payment_method && (
                                                <p className="text-danger">{isError.payment_method}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        insertNewTransaction();
                                    }}
                                    disabled={
                                        isInsertButtonDisabled
                                    }
                                >
                                    Insert
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Card className="mb-4 pt-6 pb-6 shadow">
                    <Table className="align-items-center" responsive style={{ width: "80%", margin: "auto" }}>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Payment Method</th>
                                <th scope="col" style={{ textAlign: "right" }}>Amount (Rs.)</th>
                            </tr>
                        </thead>
                        {!isAllTransactions ? (
                            <tbody>
                                <tr style={{ textAlign: "center" }}>
                                    <td colSpan="12" style={{ textAlign: "center" }}>
                                        <img src={""} className="noDataImage" alt="No Data Available" style={{ margin: "auto" }} />
                                    </td>
                                </tr>
                            </tbody>) : null
                            // <tbody>
                            //     {allTransactions?.map((transaction, index) => (
                            //         <tr key={index}>
                            //             <td>BOC Total</td>
                            //             <td style={{ textAlign: "right" }}>{transaction.boc_total}.00</td>                                       
                            //         </tr>

                            //     ))}
                            // </tbody>
                        }
                        {allTransactions !== null ?
                            <tbody>
                                <tr className="debitTransaction classFeesBalanceRow">
                                    <td style={{ textAlign: "left", fontSize: "16px" }}>Cash Total</td>
                                    <td style={{ textAlign: "right", fontSize: "16px" }}>{(+allTransactions[0].cash_total).toFixed(2)}</td>
                                </tr>
                                <tr className="debitTransaction classFeesBalanceRow">
                                    <td style={{ textAlign: "left", fontSize: "16px" }}>BOC Total</td>
                                    <td style={{ textAlign: "right", fontSize: "16px" }}>{(+allTransactions[0].boc_total).toFixed(2)}</td>
                                </tr>
                                <tr className="debitTransaction classFeesBalanceRow">
                                    <td style={{ textAlign: "left", fontSize: "16px" }}>SAMPATH Total</td>
                                    <td style={{ textAlign: "right", fontSize: "16px" }}>{(+allTransactions[0].sampath_total).toFixed(2)}</td>
                                </tr>
                                <tr className="debitTransaction classFeesBalanceRow">
                                    <td style={{ textAlign: "left", fontSize: "20px", fontWeight: '700' }}>Total</td>
                                    <td style={{ textAlign: "right", fontSize: "20px", fontWeight: '700' }}>{(+allTransactions[0].total).toFixed(2)}</td>
                                </tr>
                            </tbody> : null}
                    </Table>
                </Card>
            </div>
        </>
    )
}

export default MoneyBalance;