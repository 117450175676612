import AcademicData from "views/pages/AcademicData";
import Profile from "views/pages/Profile";

var teacherRoutes = [
 
  {
    path: "/academic",
    name: "Academic Data",
    icon: "ni fa fa-graduation-cap text-primary",
    component: <AcademicData />,
    layout: "/sense",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-primary",
    component: <Profile />,
    layout: "/sense",
  },
];
export default teacherRoutes;
