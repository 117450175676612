import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
    Label,
    Collapse,
} from "reactstrap";
import { useState, useEffect, useRef } from "react"; import SecondaryHeader from "components/Headers/SecondaryHeader";
import React from "react";
import { getRegisteredStudent } from "actions/Students";
import { getUser } from "actions/Auth";
import { getStaffList } from "actions/Auth";
import DatePicker from "react-datepicker";
import { filterVouchers } from "actions/Payments";
import { editVoucher } from "actions/Payments";
import { Link } from "react-router-dom";

const OldVoucher = () => {

    const Bill = {
        PENDING: "PENDING",
        OK: "OK",
    }

    const [voucherNumberInput, setVoucherNumberInput] = useState('');
    const [isReceivedData, setReceivedData] = useState(false);
    const [isStudentList, setStudentList] = useState(false);
    const [isData, setIsData] = useState({});
    const [allSubjects, setAllSubjects] = useState(null);
    const [user, setUser] = useState(null);
    const [staffList, setStaffList] = useState([]);
    const [isStaffDropDownOpen, setStaffDropDownOpen] = useState(false);
    const [selectedStaffMember, setSelectedStaffMember] = useState();
    const [selectedType, setSelectedType] = useState();

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    const [startDate, setStartDate] = useState(date);
    const [filterDate, setFilterDate] = useState();
    const [disabledSaveBtn, setDisableSaveBtn] = useState(false);

    const handleUpdateStaffList = (response) => {
        const newStaffList = response.map((staffMember) => ({
            id: staffMember.id,
            full_name: staffMember.full_name,
        }));

        setStaffList(newStaffList);
    };

    const fetchData = async () => {
        try {
            const response1 = await getUser();
            setUser(response1);
            const response = await getStaffList();
            if (response.length > 0) {
                handleUpdateStaffList(response);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getStudentSearch = async (e) => {
        setVoucherNumberInput(e.target.value);
    }
    const filterAttendanceByDate = async (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const newDate = year + "-" + month + "-" + day;
        setStartDate(newDate);
        setFilterDate(newDate);
    }

    const getVoucher = async () => {
        try {
            const response = await filterVouchers(
                selectedType || null,
                filterDate || null,
                voucherNumberInput || null,
                selectedStaffMember?.id || null
            );
            if (response.data.length > 0) {
                setStudentList(true);
                setAllSubjects(response.data);
            } else {
                setStudentList(false);
            }
        } catch (error) {
        }
    };

    const resetFilter = async () => {
        setSelectedType();
        setFilterDate();
        setVoucherNumberInput('');
        setSelectedStaffMember();
        setStartDate(date);
    }

    const [editModal, setEditmodal] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [editErrors, setEditErrors] = useState({});
    const [initialSubject, setInitialSubject] = useState({});
    const [isEdited, setEdit] = useState(false);

    const [imagePreviews, setImagePreviews] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [editImageStatus, setEditImageStatus] = useState(false);
    const [isError, setIsError] = useState({});
    const [docs, setDocs] = useState([]);

    const viewStudentFromList = async (response) => {
        setDocs([]);
        setSelectedSubject(response);
        setInitialSubject(response);
        setEditmodal(true);
    }

    // const handleImageChange = (event) => {
    //     let images = [];
    //     for (let i = 0; i < event.target.files.length; i++) {
    //         images.push(URL.createObjectURL(event.target.files[i]));
    //     }
    //     setImagePreviews(images);
    //     const selectedImages = Array.from(event.target.files);
    //     setImageList([...imageList, ...selectedImages]);
    //     setIsError({ ...isError, images: "" });
    //     setDocs([...imageList, ...selectedImages]);
    // setEditImageStatus(true);
    // setEdit(true);
    // };

    const removeImage = (index) => {
        const updatedImages = [...imageList];
        updatedImages.splice(index, 1);
        setImageList(updatedImages);
        setDocs(updatedImages);
        setEditImageStatus(true);
    };

    const [files, setFiles] = useState([]);

    const handleImageChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setDocs((prevFiles) => [...prevFiles, ...selectedFiles]);
        setEditImageStatus(true);
        setEdit(true);
    };

    const handleRemoveFile = (index) => {
        setDocs((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setEditImageStatus(true);
    };

    function getChangedValues(selectedVehicle) {
        const changedValues = {};
        changedValues.id = selectedVehicle.id;
        const filesOnly = docs.filter(item => item instanceof File);
        changedValues.images = filesOnly;
        return changedValues;
    }


    const editSubjectDetails = async () => {
        const data = getChangedValues(selectedSubject);
        setDisableSaveBtn(true);
        if (data.images.length !== 0) {
            const resone = await editVoucher(selectedSubject.id, data.images, selectedSubject.bill);
            if (resone.success === true) {
                setEditmodal(false);
                setDisableSaveBtn(false);
            } else {
                setIsError(true);
                setDisableSaveBtn(false);
            }
        }
    }

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Card className="mb-4 pt-4 shadow">
                    <div className="ml-4">
                        <Row>
                            <Col sm="12" md="5" lg="5">
                                <FormGroup>
                                    <Label>
                                        Staff Member
                                    </Label>
                                    <br />
                                    <FormGroup>
                                        <Dropdown
                                            isOpen={isStaffDropDownOpen}
                                            toggle={() => setStaffDropDownOpen(!isStaffDropDownOpen)}
                                        >
                                            <DropdownToggle caret>
                                                {selectedStaffMember
                                                    ? selectedStaffMember.full_name
                                                    : "Select a Staff Member"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {staffList.map((staffMember) => (
                                                    <DropdownItem
                                                        key={staffMember.id}
                                                        value={staffMember}
                                                        onClick={() => {
                                                            setSelectedStaffMember(staffMember);
                                                        }}
                                                    >
                                                        {staffMember.full_name}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="6" lg="6">
                                <FormGroup>
                                    <Label>Voucher Number</Label>
                                    <Input
                                        id="voucherNumberInput"
                                        placeholder="Voucher Number"
                                        type="text"
                                        value={voucherNumberInput || ''}
                                        onChange={(e) => setVoucherNumberInput(e.target.value)}
                                        name="voucherNumberInput"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ display: "flex" }}>
                            <Col sm="12" md="5" lg="5">
                                <FormGroup>
                                    <Label>
                                        Date
                                    </Label> <br />
                                    <DatePicker
                                        showIcon
                                        selected={startDate}
                                        onChange={(date) => filterAttendanceByDate(date)}
                                        className="dateSelector"
                                        icon="fa fa-calendar"
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="3" lg="3">
                                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                    <Label>Bill</Label>
                                    <div className="radioOptions">
                                        <FormGroup check>
                                            <Input
                                                id="cashRadio"
                                                name="typeRadio"
                                                type="radio"
                                                checked={selectedType === 'OK'}
                                                onChange={() => {
                                                    setSelectedType('OK');
                                                }}
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                            {' '}
                                            <Label check for="cashRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                                                OK
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="bocRadio"
                                                name="typeRadio"
                                                type="radio"
                                                checked={selectedType === 'PENDING'}
                                                onChange={() => {
                                                    setSelectedType('PENDING');
                                                }}
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                            {' '}
                                            <Label check for="bocRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }} >
                                                Pending
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="3" lg="3" style={{ display: "flex" }} className="justify-content-end">
                                <FormGroup>
                                    <Button color="primary"
                                        className="mt-3 mr-2"
                                        style={{ width: "100px" }}
                                        type="button"
                                        onClick={() => {
                                            getVoucher();
                                        }}>Filter</Button>
                                </FormGroup>
                                <FormGroup>
                                    <Button color="primary"
                                        className="mt-3"
                                        style={{ width: "100px" }}
                                        type="button"
                                        onClick={() => {
                                            resetFilter();
                                        }}>Reset</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <Card>
                    {isStudentList ?
                        <div className="m-4">
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Voucher Number</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Bill</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allSubjects?.map((subject, index) => (
                                        <tr key={index} style={{ backgroundColor: subject.bill === 'PENDING' ? '#F8C5C5' : '' }}>
                                            <td>{subject.voucher_number}</td>
                                            <td>{subject.staff.full_name}</td>
                                            <td>{subject.category}</td>
                                            <td>{(subject.amount).toFixed(2)}</td>
                                            <td>{subject.bill}</td>
                                            <td>
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    id="editSubject"
                                                    onClick={() => viewStudentFromList(subject)}
                                                >
                                                    Edit
                                                </Button>
                                                {user.role.name === 'admin' ?
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        tag={Link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        to={`/admin/bill/${subject.voucher_number}`}
                                                    >
                                                        View
                                                    </Button>
                                                    :
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        tag={Link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        to={`/accountant/bill/${subject.voucher_number}`}
                                                    >
                                                        View
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        :
                        <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
                    }
                </Card>
            </div >
            <Modal
                className="modal-dialog-centered"
                isOpen={editModal}
                toggle={() => {
                    setEditmodal(false);
                    editErrors({ name: "" });
                }}
            >
                <div className="modal-header pb-0">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Edit Voucher
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setEditmodal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Label>Voucher Number</Label>
                                    <Input
                                        id="exampleFormControlInput1"
                                        placeholder="Subject Name"
                                        type="text"
                                        value={selectedSubject ? selectedSubject.voucher_number : ""}
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Staff Member</Label>
                                    <Input
                                        id="exampleFormControlInput1"
                                        placeholder="Subject Name"
                                        type="text"
                                        value={selectedSubject ? selectedSubject.staff?.full_name : ""}
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Date</Label>
                                    <Input
                                        id="exampleFormControlInput1"
                                        placeholder="Subject Name"
                                        type="text"
                                        value={selectedSubject ? selectedSubject.date : ""}
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Amount</Label>
                                    <Input
                                        id="exampleFormControlInput1"
                                        placeholder="Subject Name"
                                        type="text"
                                        value={selectedSubject ? (selectedSubject.amount)?.toFixed(2) : ""}
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                    <Label>Bill</Label>
                                    <div className="radioOptions">
                                        <FormGroup check>
                                            <Input
                                                id="cashRadio"
                                                name="typeRadio"
                                                type="radio"
                                                checked={selectedSubject?.bill === 'OK'}
                                                onChange={() => {
                                                    setSelectedSubject((prevData) => ({ ...prevData, bill: 'OK' }));
                                                }}
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                            {' '}
                                            <Label check for="cashRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                                                OK
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="bocRadio"
                                                name="typeRadio"
                                                type="radio"
                                                checked={selectedSubject?.bill === 'PENDING'}
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                            {' '}
                                            <Label check for="bocRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }} >
                                                Pending
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="ImageList"
                                        type="file"
                                        name="image"
                                        accept="image/*,application/pdf"
                                        multiple
                                        onChange={handleImageChange}
                                    />
                                    {isError.images && (
                                        <p className="text-danger">{isError.images}</p>
                                    )}
                                    {/* <div>
                                        {docs.length !== 0 &&
                                            docs.map((image, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: "inline-block",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <img
                                                        src={image.image_url ? image.image_url : URL.createObjectURL(image)}
                                                        alt={`Image ${index + 1}`}
                                                        style={{
                                                            maxWidth: "100px",
                                                            maxHeight: "100px",
                                                            marginRight: "5px",
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "5px",
                                                            right: "5px",
                                                            backgroundColor: "white",
                                                            color: "black",
                                                            borderRadius: "50%",
                                                            cursor: "pointer",
                                                            width: "20px",
                                                            height: "20px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        onClick={() => removeImage(index)}
                                                    >
                                                        <span
                                                            style={{ fontSize: "14px", fontWeight: "bold" }}
                                                        >
                                                            x
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div> */}
                                    <div className="mt-4">
                                        {docs.map((file, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    marginBottom: '10px',
                                                    border: '1px solid #ddd',
                                                    padding: '5px',
                                                    borderRadius: '4px',
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {file.type.startsWith('image/') ? (
                                                        <img
                                                            src={URL.createObjectURL(file)}
                                                            alt={file.name}
                                                            style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
                                                        />
                                                    ) : (
                                                        <p style={{ marginRight: '10px' }}>{file.name}</p>
                                                    )}
                                                </div>
                                                <p
                                                    onClick={() => handleRemoveFile(index)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                        marginLeft: '10px',
                                                        fontWeight: 'bold',
                                                        padding: '2px 8px',
                                                        borderRadius: '50%',
                                                        textAlign: 'center',
                                                        lineHeight: '1',
                                                    }}
                                                >
                                                    X
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                color="primary"
                                type="button"
                                disabled={!isEdited || disabledSaveBtn}
                                onClick={() => editSubjectDetails()}
                                style={{ marginLeft: "15px" }}
                            >
                                Save
                            </Button>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default OldVoucher;