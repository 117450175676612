import Index from "views/Index.js";
import Profile from "views/pages/Profile.js";
import Login from "views/pages/Login.js";
import AlSubjects from "views/pages/AlSubjects";
import Students from "views/pages/Students";
import OlSubjects from "views/pages/OlSubjects";
import Admin from "views/pages/Admin";
import RegisteredStudent from "views/pages/RegisteredStudent";
import AcademicData from "views/pages/AcademicData";
import NewPayment from "views/pages/NewPayment";
import MoneyBalance from "views/pages/MoneyBalance";
import NewVoucher from "views/pages/NewVoucher";
import OldVoucher from "views/pages/OldVoucher";
import Expenses from "views/pages/Expenses";
import VoucherVeiw from "views/pages/VoucherView";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Index />,
  //   layout: "/admin",
  // }, 
  {
    path: "/index",
    name: "New Student",
    icon: "ni fa fa-registered text-primary",
    component: <Students />,
    layout: "/admin",
  },
  {
    path: "/student",
    name: "Registered Students",
    icon: "ni fa fa-users text-primary",
    component: <RegisteredStudent />,
    layout: "/admin",
  },
  {
    path: "/academic",
    name: "Academic Data",
    icon: "ni fa fa-graduation-cap text-primary",
    component: <AcademicData />,
    layout: "/admin",
  },
  {
    path: "/payment",
    name: "Student Payment",
    icon: "ni fa fa-money-bill text-primary",
    component: <NewPayment />,
    layout: "/admin",
  },
  {
    path: "/voucher",
    name: "New Voucher",
    icon: "ni fa fa-ticket text-primary",
    component: <NewVoucher />,
    layout: "/admin",
  },
  {
    path: "/old-vouchers",
    name: "Voucher Filter",
    icon: "ni fa fa-filter text-primary",
    component: <OldVoucher />,
    layout: "/admin",
  },
  {
    path: "/expenses",
    name: "Expenses",
    icon: "ni fa fa-credit-card text-primary",
    component: <Expenses />,
    layout: "/admin",
  },
  {
    path: "/staff",
    name: "New Staff Member",
    icon: "ni ni-circle-08 text-primary",
    component: <Admin />,
    layout: "/admin",
  },  
  {
    path: "/balance",
    name: "Money Balance",
    icon: "ni fa fa-clipboard-list text-primary",
    component: <MoneyBalance />,
    layout: "/admin",
  },
  {
    path: "/ol-subjects",
    name: "O/L Subjects",
    icon: "ni fa fa-bookmark text-primary",
    component: <OlSubjects/>,
    layout: "/admin",
  },
   {
    path: "/subjects",
    name: "A/L Subjects",
    icon: "ni fa fa-regular fa-bookmark text-primary",
    component: <AlSubjects/>,
    layout: "/admin",
  },
  {
    path: "/bill/:voucher_number",
    name: "Voucher Details",
    icon: "ni fa fa-regular fa-bookmark text-primary",
    component: <VoucherVeiw/>,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

export default routes;
