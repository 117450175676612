import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";

import { getUser } from "actions/Auth";
import { useNavigate } from "react-router-dom";
import Loader from "views/pages/Loader";
import assistantRoutes from "assistantRoutes";
import AssistantSidebar from "components/Sidebar/AssistantSidebar";

const Assistant = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getUser();
        setUser(user);
        setUserDetails(true);
        setUserLoading(false);
      } catch (error) {
        setUserDetails(true);
        setUserLoading(false);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (userLoading === false) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/assistant") {
        if (userLoading === true) {
          <Loader></Loader>;
        } else if (userLoading === false) {
          if (user && userDetails) {
            if (user.role.name === "admin") {
              return navigate("/admin/index");
            }
            else if(user.role.name === 'sense') {
              navigate("/sense/academic");
            }
            else if(user.role.name === 'accountant') {
              navigate("/accountant/index");
            }
             else if (user.role.name === "office assistant") {
              return (
                <Route
                  path={prop.path}
                  element={prop.component}
                  key={key}
                  exact
                />
              );
            }
          } else if (!user && userDetails) {
            return navigate("/auth/login");
          }
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < assistantRoutes.length; i++) {
      if (
        location?.pathname.indexOf(
          assistantRoutes[i].layout + assistantRoutes[i].path
        ) !== -1
      ) {
        return assistantRoutes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      {userLoading ? (
        <Loader></Loader>
      ) : (
        <><AssistantSidebar
            {...props}
            routes={assistantRoutes}
            logo={{
              innerLink: "/assistant/index",
              imgSrc: require("../assets/img/brand/Logo.png"),
              imgAlt: "...",
            }} /><div className="main-content" ref={mainContent}>
              <AdminNavbar {...props} brandText={getBrandText(location?.pathname)} />
              <Routes>
                {getRoutes(assistantRoutes)}
                <Route path="*" />
              </Routes>
              <Container fluid>
                <AdminFooter />
              </Container>
            </div></>     
    )}
    </>
  );
};

export default Assistant;
