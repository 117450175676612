import axios from "axios";
const apiUrl = "https://ims-backend.ardillalabs.cloud/api"; 
// Subjects
export const getAlSubjects = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/a-level-subject`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const addNewSubject = async (addSubjectFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const body = JSON.stringify({
        name: addSubjectFormData,
      });

      const response = await axios.post(
        `${apiUrl}/a-level-subject/create-a-level-subject`,
        body,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const editSubject = async (editSubjectFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/a-level-subject/update-a-level-subject-info`,
        editSubjectFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getSubjectsByLimit = async (limit, skip) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/a-level-subject?skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getSubjectsBySearch = async (limit, skip, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/a-level-subject?skip=${skip}&limit=${limit}&search=${search}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getAllActiveSubjects = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/a-level-subject?status=true`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

// O/L Subjects
export const getOLSubjects = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/o-level-subject`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const addNewOLSubject = async (addSubjectFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const body = JSON.stringify({
        name: addSubjectFormData,
      });

      const response = await axios.post(
        `${apiUrl}/o-level-subject/create-o-level-subject`,
        body,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const editOLSubject = async (editSubjectFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/o-level-subject/update-o-level-subject-info`,
        editSubjectFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getOLSubjectsByLimit = async (limit, skip) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/o-level-subject?skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getOLSubjectsBySearch = async (limit, skip, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/o-level-subject?skip=${skip}&limit=${limit}&search=${search}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getOLActiveSubjects = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/o-level-subject?status=true`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getEduQualifications = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/edu-qualification?status=true`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

