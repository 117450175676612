import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
    Label,
    Collapse,
} from "reactstrap";
import { useState, useEffect, useRef } from "react"; import SecondaryHeader from "components/Headers/SecondaryHeader";
import React from "react";
import { getRegisteredStudent } from "actions/Students";
import defaultprofile from "../../assets/img/brand/blank_profile_pic.png";
import Rating from "react-rating";
import { updateAcademicDetails } from "actions/Students";

const AcademicData = () => {
    const testList = [
        {
            id: 1,
            name: 'Test 01'
        },
        {
            id: 2,
            name: 'Test 02'
        },
        {
            id: 3,
            name: 'Test 03'
        },
        {
            id: 4,
            name: 'Test 04'
        },
        {
            id: 5,
            name: 'Test 05'
        }
    ]
    const inputImgRef = useRef(null);
    const nicInputRef = useRef(null);
    const registrationNumberInputRef = useRef(null);
    const batchNumberInputRef = useRef(null);
    const [nicInput, setNicInput] = useState('');
    const [registrationNumberInput, setRegistrationNumberInput] = useState('');
    const [batchNoInput, setBatchNoInput] = useState('');
    const [isReceivedData, setReceivedData] = useState(false);
    const [isStudentList, setStudentList] = useState(false);
    const [isData, setIsData] = useState({});
    const [allSubjects, setAllSubjects] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredStudents, setFilteredStudents] = useState(null);
    const [isPersonalBioOpen, setPersonalBioOpen] = useState(true);
    const [previewImage, setPreviewImage] = useState(defaultprofile);
    const [isAcademicDetailsOpen, setAcademicDetailsOpen] = useState(true);
    const [isAcademicData, setAcademicData] = useState({});
    const [isAlphabet, setAlphabet] = useState(false);
    const [isListening, setListening] = useState(false);
    const [isSpoken, setSpoken] = useState(false);
    const [isIdori1, setIdori1] = useState(false);
    const [isIdori2, setIdori2] = useState(false);
    const [isIdori3, setIdori3] = useState(false);
    const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");

    const getStudentSearch = async (e) => {
        if (e.target.name === 'nic') {
            setNicInput(e.target.value);
        }
        if (e.target.name === 'registrationNumberInput') {
            setRegistrationNumberInput(e.target.value);
        }
        if (e.target.name === 'batchNo') {
            setBatchNoInput(e.target.value);
        }
    };

    const [academic_exam_results, setAcademicDataResults] = useState(
        testList.map(test => ({
            academic_exam_id: test.id,
            limit: '',
            mark: ''
        }))
    );

    const handleInputChange1 = (academic_exam_id, field, value) => {
        setAcademicDataResults((prevResults) => {
            return prevResults.map(result =>
                result.academic_exam_id === parseInt(academic_exam_id)
                    ? { ...result, [field]: value }
                    : result
            );
        });
    };

    const getStudent = async (type, searchValue) => {
        setReceivedData(false);
        setAllSubjects(null);
        setAcademicData({});
        setFilteredStudents(null)
        setStudentList(false);
        if (type === 'nic' || type === 'student_number') {
            setStudentList(false);
        }
        const body = { [type]: searchValue };
        try {
            const response = await getRegisteredStudent(body);
            if (response.length > 0 && response.length < 2) {
                setIsData(response[0]);
                setAcademicData({
                    learned_hours: response[0].learned_hours,
                    is_alphabet: response[0].is_alphabet,
                    is_listening: response[0].is_listening,
                    is_spoken_practice: response[0].is_spoken_practice,
                    is_irodori_book_1: response[0].is_irodori_book_1,
                    is_irodori_book_2: response[0].is_irodori_book_2,
                    is_irodori_book_3: response[0].is_irodori_book_3,
                    kanji: response[0].kanji,
                    recommendation: response[0].recommendation,
                    language_skill: response[0].language_skill,
                    attitude: response[0].attitude,
                    working_skill: response[0].working_skill,
                    cleanliness: response[0].cleanliness
                });
                setAlphabet(response[0].is_alphabet);
                setListening(response[0].is_listening);
                setSpoken(response[0].is_spoken_practice);
                setIdori1(response[0].is_irodori_book_1);
                setIdori2(response[0].is_irodori_book_2);
                setIdori3(response[0].is_irodori_book_3);
                if (response[0].academic_exam_results.length > 0) {
                    response[0].academic_exam_results.forEach((qualification) => {
                        handleInputChange1(qualification.academic_exam.id, 'mark', qualification.mark);
                        handleInputChange1(qualification.academic_exam.id, 'limit', qualification.limit);
                    })
                } else {
                    setAcademicDataResults(testList.map(test => ({
                        academic_exam_id: test.id,
                        limit: '',
                        mark: ''
                    })))
                }
                setReceivedData(true);
            }
            if (response.length > 1) {
                setStudentList(true);
                setAllSubjects(response);
                setReceivedData(false);
            }
            if (response.success === false) {
            }
        } catch (error) {
            setIsData({});
            setReceivedData(false);
        }
    };

    const searchSubject = async (searchQuery) => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredStudents(allSubjects.filter(student =>
            student.full_name.toLowerCase().includes(lowerCaseQuery)
        ));
    };

    const viewStudentFromList = async (response) => {
        getStudent('nic', response.nic);
        setStudentList(false);
        setReceivedData(true);
        setFilteredStudents(null);
    }

    const handleInputChange = (index, field, value) => {
        if (/^\d*$/.test(value)) {
            setAcademicDataResults((prevResults) => {
                const updatedResults = [...prevResults];
                updatedResults[index][field] = value;
                return updatedResults;
            });
        }
    };

    const insertAcademicData = async () => {
        try {
            setDisabledSaveBtn(true);
            isAcademicData.is_alphabet = isAlphabet;
            isAcademicData.is_listening = isListening;
            isAcademicData.is_spoken_practice = isSpoken;
            isAcademicData.is_irodori_book_1 = isIdori1;
            isAcademicData.is_irodori_book_2 = isIdori2;
            isAcademicData.is_irodori_book_3 = isIdori3;
            isAcademicData.id = isData.id;
            isAcademicData.academic_exam_results = academic_exam_results;
            const response = await updateAcademicDetails(isAcademicData);
            if (response.data.success === true) {
                setSuccess(true);
                setSuccessMessage(response.data.message);
                setDisabledSaveBtn(false);
                getStudent('nic', isData.nic);
            } else {
                setFailed(true);
                setDisabledSaveBtn(false);
                setErrorMessage(response.data.message);
            }
        }
        catch (error) {
            setFailed(true);
            setDisabledSaveBtn(false);
            setErrorMessage(error.message);
        }
    }

    const [languageSkills, setLanguageSkills] = useState(0);
    const handleRatingChange = (rate) => {
        setLanguageSkills(rate);
    };
    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Card className="mb-4 pt-4 shadow">
                    <div className="ml-4">
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Input
                                        id="nic"
                                        placeholder="NIC"
                                        type="text"
                                        value={nicInput}
                                        onChange={getStudentSearch}
                                        name="nic"
                                        ref={nicInputRef}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Button color="primary"
                                    type="button"
                                    onClick={() => {
                                        getStudent('nic', nicInput);
                                    }}>Search</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Input
                                        id="registrationNumberInput"
                                        placeholder="Registration Number"
                                        type="text"
                                        value={registrationNumberInput}
                                        onChange={getStudentSearch}
                                        name="registrationNumberInput"
                                        ref={registrationNumberInputRef}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Button color="primary"
                                    type="button"
                                    onClick={() => {
                                        getStudent('student_number', registrationNumberInput);
                                    }}>Search</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Input
                                        id="batchNo"
                                        placeholder="Batch Number"
                                        type="text"
                                        value={batchNoInput}
                                        onChange={getStudentSearch}
                                        name="batchNo"
                                        ref={batchNumberInputRef}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Button color="primary"
                                    type="button"
                                    onClick={() => {
                                        getStudent('batch_number', batchNoInput);
                                    }}>Search</Button>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <Card>
                    {isStudentList ?
                        <div className="m-4">
                            <Row>
                                <Col>
                                </Col>
                                <Col className="justify-content-end mb-4" md="4">
                                    <Input
                                        type="text"
                                        placeholder="Search by name"
                                        value={searchQuery}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                            searchSubject(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Registration Number</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">NIC</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStudents !== null ?
                                        filteredStudents?.map((subject, index) => (
                                            <tr key={index}>
                                                <td>{subject.student_number}</td>
                                                <td>{subject.full_name}</td>
                                                <td>{subject.nic}</td>
                                                <td>{subject.mobile_phone_number}</td>
                                                <td>
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        id="editSubject"
                                                        onClick={() => viewStudentFromList(subject)}
                                                    >
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                        )) :
                                        allSubjects?.map((subject, index) => (
                                            <tr key={index}>
                                                <td>{subject.student_number}</td>
                                                <td>{subject.full_name}</td>
                                                <td>{subject.nic}</td>
                                                <td>{subject.mobile_phone_number}</td>
                                                <td>
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        id="editSubject"
                                                        onClick={() => viewStudentFromList(subject)}
                                                    >
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                        : null}
                    {isReceivedData ?
                        <Form className="ml-4 mb-4 mr-4 pt-4">
                            <div className="mb-4 studentSectionTitles" onClick={() => setPersonalBioOpen(!isPersonalBioOpen)} style={{ cursor: "pointer" }}>
                                Personal Details  {!isPersonalBioOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
                            </div>
                            <Collapse isOpen={isPersonalBioOpen}>
                                <Row className="nameWithImage">
                                    <Col md="12" lg="8">
                                        <FormGroup>
                                            <Label>Registration Number</Label>
                                            <Input
                                                id="student_number"
                                                type="text"
                                                value={isData.student_number}
                                                name="student_number"
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Full Name</Label>
                                            <Input
                                                id="full_name"
                                                type="text"
                                                value={isData.full_name}
                                                name="full_name"
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Name with Initials</Label>
                                            <Input
                                                id="name_with_initials"
                                                type="text"
                                                value={isData.name_with_initials}
                                                name="name_with_initials"
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Address</Label>
                                            <Input
                                                id="address"
                                                type="textarea"
                                                value={isData.address}
                                                name="address"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" className="stuImgSection mt-3">
                                        <FormGroup>
                                            <div className="studentImgPrv">
                                                <img
                                                    src={isData?.image_url ? isData.image_url : 'https://ape-sense.s3.amazonaws.com/student-image/image1724930888205'}
                                                    alt="Selected or Default Preview"
                                                    style={{
                                                        width: "200px",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "8px"
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>NIC</Label>
                                            <Input
                                                id="nic"
                                                type="text"
                                                value={isData.nic}
                                                name="nic"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Passport</Label>
                                            <Input
                                                id="passport_number"
                                                type="text"
                                                value={isData?.passport_number}
                                                name="passport"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="6" lg="3">
                                        <FormGroup>
                                            <Label>Sex</Label>  <br />
                                            <FormGroup>
                                                <Input
                                                    id="sex"
                                                    type="text"
                                                    value={isData?.sex}
                                                    name="sex"
                                                    disabled
                                                />
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6" lg="4">
                                        <FormGroup>
                                            <Label>Status</Label>  <br />
                                            <FormGroup>
                                                <Input
                                                    id="marital"
                                                    type="text"
                                                    value={isData.marital}
                                                    name="marital"
                                                    disabled
                                                />
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6" lg="5">
                                        <FormGroup>
                                            <Label>Date of Birth</Label>  <br />
                                            <Input
                                                id="dob"
                                                type="text"
                                                value={isData.dob}
                                                name="dob"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="4" sm="12">
                                        <FormGroup>
                                            <Label>Land phone Number</Label>
                                            <Input
                                                id="lane_phone_number"
                                                type="text"
                                                value={isData.lane_phone_number}
                                                name="lane_phone_number"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" sm="12">
                                        <FormGroup>
                                            <Label>Mobile Number</Label>
                                            <Input
                                                id="mobile_phone_number"
                                                type="text"
                                                value={isData.mobile_phone_number}
                                                name="mobile_phone_number"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" sm="12">
                                        <FormGroup>
                                            <Label>WhatsApp Number</Label>
                                            <Input
                                                id="whatsapp_number"
                                                type="text"
                                                value={isData.whatsapp_number}
                                                name="whatsapp_number"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Batch Number</Label>
                                            <Input
                                                id="batch_number"
                                                type="text"
                                                value={isData.batch_number}
                                                name="batch_number"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                            <Label>Course Type</Label>
                                            <div className="radioOptions">
                                                <Input
                                                    id="type"
                                                    type="text"
                                                    value={isData.course_type}
                                                    name="type"
                                                    disabled
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Collapse>
                            <div className="mb-4 studentSectionTitles" onClick={() => setAcademicDetailsOpen(!isAcademicDetailsOpen)} style={{ cursor: "pointer" }}>
                                Academic Details  {!isAcademicDetailsOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
                            </div>
                            <Collapse isOpen={isAcademicDetailsOpen}>
                                <Row>
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <Label>Japanese Language Learned Hours</Label>
                                            <Input
                                                id="learnHours"
                                                type="text"
                                                value={isAcademicData.learned_hours}
                                                name="learnHours"
                                                pattern="\d*"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        setAcademicData({ ...isAcademicData, learned_hours: Number(value) });
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="6">
                                        <Label className="boldTitle">Completed Areas</Label><br />
                                        <div className="ml-4">
                                            <FormGroup check inline className="areaCheck">
                                                <Label check className="areaLabel">
                                                    Alphabet
                                                </Label>
                                                <Input type="checkbox" className="large-checkbox"
                                                    onChange={() => setAlphabet(!isAlphabet)}
                                                    checked={isAlphabet} />
                                            </FormGroup><br />
                                            <FormGroup check inline className="areaCheck">
                                                <Label check className="areaLabel">
                                                    Listening
                                                </Label>
                                                <Input type="checkbox" className="large-checkbox"
                                                    onChange={() => setListening(!isListening)}
                                                    checked={isListening} />
                                            </FormGroup><br />
                                            <FormGroup check inline className="areaCheck">
                                                <Label check className="areaLabel">
                                                    Spoken Practice
                                                </Label>
                                                <Input type="checkbox" className="large-checkbox"
                                                    onChange={() => setSpoken(!isSpoken)}
                                                    checked={isSpoken} />
                                            </FormGroup><br />
                                            <FormGroup check inline className="areaCheck">
                                                <Label check className="areaLabel">
                                                    Irodori Book 1
                                                </Label>
                                                <Input type="checkbox" className="large-checkbox"
                                                    onChange={() => setIdori1(!isIdori1)}
                                                    checked={isIdori1} />
                                            </FormGroup><br />
                                            <FormGroup check inline className="areaCheck">
                                                <Label check className="areaLabel">
                                                    Irodori Book 2
                                                </Label>
                                                <Input type="checkbox" className="large-checkbox"
                                                    onChange={() => setIdori2(!isIdori2)}
                                                    checked={isIdori2} />
                                            </FormGroup><br />
                                            <FormGroup check inline className="areaCheck">
                                                <Label check className="areaLabel">
                                                    Irodori Book 3
                                                </Label>
                                                <Input type="checkbox" className="large-checkbox"
                                                    onChange={() => setIdori3(!isIdori3)}
                                                    checked={isIdori3} />
                                            </FormGroup>
                                        </div>
                                        <FormGroup>
                                            <Label className="areaLabel">Kanji</Label>
                                            <Input
                                                id="kanji"
                                                type="text"
                                                value={isAcademicData.kanji}
                                                name="kanji"
                                                pattern="\d*"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        setAcademicData({ ...isAcademicData, kanji: Number(value) });
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                        <Label className="boldTitle">Academic Exam Results</Label><br />
                                        <div>
                                            {testList.map((test, index) => (
                                                <FormGroup
                                                    key={test.id}
                                                    className="areaCheck"
                                                    style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
                                                >
                                                    <Label
                                                        className="areaLabel"
                                                        style={{ marginRight: '10px', minWidth: '80px' }}
                                                    >
                                                        {test.name}
                                                    </Label>
                                                    <Input
                                                        id={`mark${index}`}
                                                        type="text"
                                                        value={academic_exam_results[index].mark}
                                                        name={`mark${index}`}
                                                        pattern="\d*"
                                                        onChange={(e) => handleInputChange(index, 'mark', e.target.value)}
                                                        className="academicInput"
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                    <span className="fdSlash" style={{ marginRight: '5px' }}>/</span>
                                                    <Input
                                                        id={`limit${index}`}
                                                        type="text"
                                                        value={academic_exam_results[index].limit}
                                                        name={`limit${index}`}
                                                        pattern="\d*"
                                                        onChange={(e) => handleInputChange(index, 'limit', e.target.value)}
                                                        className="academicInput"
                                                    />
                                                </FormGroup>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <Label>Recommendations</Label>
                                            <Input
                                                id="recommendation"
                                                type="textarea"
                                                value={isAcademicData?.recommendation}
                                                onChange={(e) => {
                                                    setAcademicData({ ...isAcademicData, recommendation: e.target.value });
                                                }}
                                                name="recommendation"
                                                style={{ height: '150px' }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup check inline className="areaCheck">
                                            <Label check className="areaLabel">
                                                Language Skills
                                            </Label>
                                            <Rating
                                                emptySymbol="fa-regular fa-star fa-2x"
                                                fullSymbol="fa fa-star fa-2x"
                                                fractions={2}
                                                className="custom-rating"
                                                onClick={(rate) => setAcademicData({ ...isAcademicData, language_skill: rate })}
                                                initialRating={isAcademicData.language_skill}
                                            />
                                        </FormGroup><br />
                                        <FormGroup check inline className="areaCheck">
                                            <Label check className="areaLabel">
                                                Attitude
                                            </Label>
                                            <Rating emptySymbol="fa-regular fa-star fa-2x"
                                                fullSymbol="fa fa-star fa-2x"
                                                fractions={2} style={{ color: '#AD1D21' }}
                                                onClick={(rate) => setAcademicData({ ...isAcademicData, attitude: rate })}
                                                initialRating={isAcademicData.attitude} />
                                        </FormGroup><br />
                                        <FormGroup check inline className="areaCheck">
                                            <Label check className="areaLabel">
                                                Working Skills
                                            </Label>
                                            <Rating emptySymbol="fa-regular fa-star fa-2x"
                                                fullSymbol="fa fa-star fa-2x"
                                                fractions={2} style={{ color: '#AD1D21' }}
                                                onClick={(rate) => setAcademicData({ ...isAcademicData, working_skill: rate })}
                                                initialRating={isAcademicData.working_skill} />
                                        </FormGroup><br />
                                        <FormGroup check inline className="areaCheck">
                                            <Label check className="areaLabel">
                                                Cleanliness
                                            </Label>
                                            <Rating emptySymbol="fa-regular fa-star fa-2x"
                                                fullSymbol="fa fa-star fa-2x"
                                                fractions={2} style={{ color: '#AD1D21' }}
                                                onClick={(rate) => setAcademicData({ ...isAcademicData, cleanliness: rate })}
                                                initialRating={isAcademicData.cleanliness} />
                                        </FormGroup><br />
                                    </Col>
                                </Row>
                                <FormGroup className="mt-4">
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={() => {
                                            insertAcademicData();
                                        }}
                                        disabled={disabledSaveBtn}
                                    >
                                        Save
                                    </Button>
                                </FormGroup>
                            </Collapse>
                        </Form>
                        : null
                    }
                </Card>
            </div >
        </>
    )
}

export default AcademicData;