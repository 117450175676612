import axios from "axios";

const apiUrl = "https://ims-backend.ardillalabs.cloud/api"; 
// Course Categories
export const getAllStudents = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users/get-all-students-for-admin`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.students);
      } else {
        reject(response.data.students);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getRegisteredStudent = async (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const body = JSON.stringify(formData);
      const response = await axios.post(
        `${apiUrl}/auth/get-student`,
        body,
        config
      );

      if (response.status === 201) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const updateStudent = async (editStudentFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/auth/update-student`,
        editStudentFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const createStudent = async (createStudentFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const body = JSON.stringify(createStudentFormData);
      const responseUser = await axios.post(
        `${apiUrl}/auth/create-student`,
        body,
        config
      );

      if (responseUser.status === 201) {
        resolve(responseUser);
      } else {
        reject(responseUser.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        resolve({
              message: "Network Error",
              status: 404,
              statusText: err.code,
        });
      } else {
        resolve(err.response.data);
      }
    }
  });
};

export const updateAcademicDetails = async (createStudentFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const body = JSON.stringify(createStudentFormData);
      const responseUser = await axios.post(
        `${apiUrl}/auth/create-performance`,
        body,
        config
      );

      if (responseUser.status === 201) {
        resolve(responseUser);
      } else {
        reject(responseUser.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        resolve({
              message: "Network Error",
              status: 404,
              statusText: err.code,
        });
      } else {
        resolve(err.response.data);
      }
    }
  });
};

export const editProfileImage = async (image, studentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };
      const formData = new FormData();

      formData.append("id", studentId);
      formData.append("image", image.image);

      const response = await axios.put(
        `${apiUrl}/auth/update-student`,
        formData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
};

export const findByUserIdForAdmin = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-user/find-by-user-id-for-admin/${id}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getLastYearClassFees = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-fee/get-last-5-class-fees/${id}/12`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const editStudent = async (editStudentFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/users/update-student-info`,
        editStudentFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const editStudentClass = async (editStudentClassFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
       `${apiUrl}/class-user/update-class-user-info`,
        editStudentClassFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const enrollStudent = async (enrollStudentFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const body = JSON.stringify(enrollStudentFormData);

      const responseUser = await axios.post(
        `${apiUrl}/class-user/create-class-user-for-admin`,
        body,
        config
      );

      if (responseUser.status === 201) {
        resolve(responseUser.data);
      } else {
        reject(responseUser.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        resolve(err.response.data);
      }
    }
  });
};

export const getStudentsByLimit = async (limit, skip) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users?skip=${skip}&limit=${limit}&role=student`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getStudentsBySearch = async (limit, skip, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users?skip=${skip}&limit=${limit}&role=student&search=${search}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getClassStudentsByLimit = async (limit, skip, teacherClassId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-user/find-by-teacher-class-id?skip=${skip}&limit=${limit}&teacherClassId=${teacherClassId}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const getClassStudentsBySearch = async (limit, skip, search, teacherClassId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-user/find-by-teacher-class-id?skip=${skip}&limit=${limit}&teacherClassId=${teacherClassId}&search=${search}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
      }
    }
  });
}

export const bulkStudentRegister = async (bulkFile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };

      const response = await axios.post(
        `${apiUrl}/users/bulk-student-registration`,
        bulkFile,
        config
      );

      if (response.status === 201) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getStudent = async (regNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users/student/${regNumber}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.message,
              status: err.code,
              statusText: err.code,
            },
          },
        });
      }
    }
  });
};