import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
    Label,
    Collapse,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import React from "react";
import { getUser } from "actions/Auth";
import { getStaffList } from "actions/Auth";
import { getExpensesByBatch } from "actions/Payments";
import { getTotalExpenseByBatch } from "actions/Payments";

const Expenses = () => {

    const Category = {
        KITCHEN_ITEM: "KITCHEN_ITEM",
        CLASS: "CLASS",
        HOSTAL: "HOSTAL",
        MARKETING: "MARKETING",
        FOOD: "FOOD",
        OFFICE: "OFFICE",
        TRANSPORT: "TRANSPORT",
        SALARY: "SALARY",
        EXTRA: "EXTRA",
        RENTAL: "RENTAL",
        DASH: "-",
        OTHER: "OTHER",
    };

    const categoryArray = Object.values(Category);

    const [batchNo, setBatchNo] = useState();
    const [isReceivedData, setReceivedData] = useState(false);
    const [isStudentList, setStudentList] = useState(false);
    const [isData, setIsData] = useState({});
    const [allSubjects, setAllSubjects] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [user, setUser] = useState(null);
    const [staffList, setStaffList] = useState([]);
    const [other, setOther] = useState(null);
    const [isOther, setIsOther] = useState(false);
    const [clickedbatchNo, setClickedBatchNo]  = useState(false);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;

    const handleUpdateStaffList = (response) => {
        const newStaffList = response.map((staffMember) => ({
            id: staffMember.id,
            full_name: staffMember.full_name,
        }));

        setStaffList(newStaffList);
    };

    const fetchData = async () => {
        try {
            const response1 = await getUser();
            setUser(response1);
            const response = await getStaffList();
            if (response.length > 0) {
                handleUpdateStaffList(response);
            }
            const other = await getExpensesByBatch('other');
            if (other.data.length > 0) {
                setIsOther(true);
                setOther(other.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getVoucher = async () => {
        try {
            setClickedBatchNo(false);
            const response = await getExpensesByBatch(batchNo);
            const total = await getTotalExpenseByBatch(batchNo);
            setTotalData(total.data);
            if (response.data.length > 0) {
                setStudentList(true);
                setAllSubjects(response.data);
            } else {
                setStudentList(false);
                setClickedBatchNo(true);
            }
        } catch (error) {
            setStudentList(false);
            setClickedBatchNo(true);
        }
    };

    const viewStudentFromList = async (response) => {
    }

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Card className="mb-4 pt-4 shadow">
                    <div className="ml-4">
                        <Row>
                            <Col sm="12" md="6" lg="6">
                                <FormGroup>
                                    <Input
                                        id="batchNo"
                                        placeholder="Batch Number"
                                        type="text"
                                        value={batchNo}
                                        onChange={(e) => setBatchNo(e.target.value)}
                                        name="batchNo"
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="3" lg="3" style={{ display: "flex" }}>
                                <FormGroup>
                                    <Button color="primary"
                                        style={{ width: "100px" }}
                                        type="button"
                                        onClick={() => {
                                            getVoucher();
                                        }}                                        
                                        disabled={batchNo === undefined || !(batchNo)}>Filter</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <Card className="mb-4" >
                    {isStudentList ?
                        <div className="m-4">
                            <Table bordered className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th colSpan="14" style={{ textAlign: 'center', color: "black", fontSize: "16px" }}>BATCH {batchNo} ANALYSIS
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Voucher No</th>
                                        {categoryArray.map((category) => (
                                            <th scope="col" key={category}>
                                                {category}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allSubjects?.map((item) => (
                                        <tr key={item.id}>
                                            {/* Static columns */}
                                            <td>{item.date || 'N/A'}</td> {/* Replace 'N/A' with a default or formatted date if needed */}
                                            <td>{item.voucher_number}</td>
                                            {/* Dynamic columns based on category */}
                                            {categoryArray.map((category) => (
                                                <td key={category} style={{textAlign: "right"}}>
                                                    {/* Check if the item's category matches the column */}
                                                    {item.category === category ? (item.amount).toFixed(2) : ""}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    <tr>
                                        {/* Merge first two cells */}
                                        <td colSpan="2"><strong style={{ color: "black", fontSize: "16px" }}>Total</strong></td>
                                        {/* Render totals for each category */}
                                        {categoryArray.map((category) => {
                                            // Find total for the current category
                                            const total = totalData.find((totalItem) => totalItem.category === category);
                                            return (
                                                <td key={category} style={{ color: "black", fontSize: "16px", textAlign: "right"}}>
                                                    {/* Display the total amount if exists, otherwise display empty */}
                                                    {total ? Number(total.total).toFixed(2) : ""}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        : clickedbatchNo?
                        <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
                        : null
                    }
                </Card>
                <Card>
                    {isOther ?                    
                    <div className="m-4">
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                            <tr>
                                        <th colSpan="14" style={{ textAlign: 'center', color: "black", fontSize: "16px" }}>OTHER EXPENSES
                                        </th>
                                    </tr>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Voucher</th>
                                    <th scope="col">Remark</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Authorized</th>
                                </tr>
                            </thead>
                            <tbody>
                                {other?.map((subject, index) => (
                                    <tr key={index}>
                                        <td>{subject.date}</td>
                                        <td>{subject.voucher_number}</td>
                                        <td>{subject.remark}</td>
                                        <td>{(subject.amount).toFixed(2)}</td>
                                        <td>{subject.staff.full_name}</td>
                                        <td>{subject.auth_user.full_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                        : null}
                </Card>
            </div >
        </>
    )
}

export default Expenses;