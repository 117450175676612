import axios from "axios";
const authApi = "https://ims-backend.ardillalabs.cloud/api/auth";

export const signInSubmit = async (signInFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const body = JSON.stringify({
        email: signInFormData.email,
        password: signInFormData.password,
      });

      const response = await axios.post(`${authApi}/staff-signin`, body, config);

      if (response.data.success === true) {
        const user = await getUser();
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getUser = async () => {
  return new Promise(async (resolve, reject) => {
    try {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(`${authApi}/get-user`, config);
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              statusText: err.code,
            },
          },
        });
      }
    }
  });
};

export const createUser = async (createUserFormData) => {
  return new Promise(async (resolve, reject) => {
    try {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const body = JSON.stringify({
        full_name: createUserFormData.fullName,
        email: createUserFormData.email,
        phone_number: createUserFormData.phoneNumber,
        password: createUserFormData.password,
      });

      const response = await axios.post(`${authApi}/create-user`, body, config);

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const signOutSubmit = async () => {
  return new Promise(async (resolve, reject) => {
    try {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(`${authApi}/logout`, config);

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getStaffList = async () => {
  return new Promise(async (resolve, reject) => {
    try {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(`${authApi}/get-staff/staff`, config);
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              statusText: err.code,
            },
          },
        });
      }
    }
  });
};

export const getAuthorizedList = async () => {
  return new Promise(async (resolve, reject) => {
    try {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(`${authApi}/get-staff/authorized person`, config);
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              statusText: err.code,
            },
          },
        });
      }
    }
  });
};

export const getEmployeeList = async () => {
  return new Promise(async (resolve, reject) => {
    try {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(`${authApi}/get-all-staff`, config);
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              statusText: err.code,
            },
          },
        });
      }
    }
  });
};
