import Profile from "views/pages/Profile.js";
import Login from "views/pages/Login.js";
import AlSubjects from "views/pages/AlSubjects";
import Students from "views/pages/Students";
import OlSubjects from "views/pages/OlSubjects";
import RegisteredStudent from "views/pages/RegisteredStudent";

var assistantRoutes = [
  {
    path: "/index",
    name: "New Student",
    icon: "ni fa fa-registered text-primary",
    component: <Students />,
    layout: "/assistant",
  },
  {
    path: "/student",
    name: "Registered Students",
    icon: "ni fa fa-users text-primary",
    component: <RegisteredStudent />,
    layout: "/assistant",
  },
  {
    path: "/ol-subjects",
    name: "O/L Subjects",
    icon: "ni fa fa-bookmark text-primary",
    component: <OlSubjects/>,
    layout: "/assistant",
  },
   {
    path: "/subjects",
    name: "A/L Subjects",
    icon: "ni fa fa-regular fa-bookmark text-primary",
    component: <AlSubjects/>,
    layout: "/assistant",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-primary",
    component: <Profile />,
    layout: "/assistant",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

export default assistantRoutes;
