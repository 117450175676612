import Profile from "views/pages/Profile.js";
import Login from "views/pages/Login.js";
import Students from "views/pages/Students";
import NewPayment from "views/pages/NewPayment";
import MoneyBalance from "views/pages/MoneyBalance";
import NewVoucher from "views/pages/NewVoucher";
import OldVoucher from "views/pages/OldVoucher";
import Expenses from "views/pages/Expenses";
import VoucherVeiw from "views/pages/VoucherView";

var accountantRoutes = [
  {
    path: "/index",
    name: "New Student",
    icon: "ni fa fa-registered text-primary",
    component: <Students />,
    layout: "/accountant",
  },
  {
    path: "/payment",
    name: "Student Payment",
    icon: "ni fa fa-money-bill text-primary",
    component: <NewPayment />,
    layout: "/accountant",
  },
  {
    path: "/voucher",
    name: "New Voucher",
    icon: "ni fa fa-ticket text-primary",
    component: <NewVoucher />,
    layout: "/accountant",
  },
  {
    path: "/old-vouchers",
    name: "Voucher Filter",
    icon: "ni fa fa-filter text-primary",
    component: <OldVoucher />,
    layout: "/accountant",
  },
  {
    path: "/expenses",
    name: "Expenses",
    icon: "ni fa fa-credit-card text-primary",
    component: <Expenses />,
    layout: "/accountant",
  },
  {
    path: "/balance",
    name: "Money Balance",
    icon: "ni fa fa-clipboard-list text-primary",
    component: <MoneyBalance />,
    layout: "/accountant",
  },  
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-primary",
    component: <Profile />,
    layout: "/accountant",
  },
  {
    path: "/bill/:voucher_number",
    name: "Voucher Details",
    icon: "ni fa fa-regular fa-bookmark text-primary",
    component: <VoucherVeiw/>,
    layout: "/accountant",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

export default accountantRoutes;
