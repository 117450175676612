import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  Label,
  Collapse,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import defaultprofile from "../../assets/img/brand/blank_profile_pic.png";
import "../../assets/css/styles.css";
import { getOLActiveSubjects } from "actions/Subjects";
import { getAllActiveSubjects } from "actions/Subjects";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdRefresh } from "react-icons/io";
import { GoPlusCircle } from "react-icons/go";
import { Link } from "react-router-dom";
import { getEduQualifications } from "actions/Subjects";
import { getUser } from "actions/Auth";
import { getYear, getMonth } from 'date-fns';
import { getRegisteredStudent } from "actions/Students";
import { updateStudent } from "actions/Students";
import { editProfileImage } from "actions/Students";
import QRCard from "components/QR/QRCard";
import { createRoot } from "react-dom/client";
import html2canvas from "html2canvas";
import { LiaQrcodeSolid } from "react-icons/lia";
import { HiOutlineDownload } from 'react-icons/hi'

const RegisteredStudent = () => {
  const Grades = {
    A: "A",
    B: "B",
    C: "C",
    S: "S",
    F: "F",
  };

  const Sex = {
    Male: "Male",
    Female: "Female"
  };

  const Status = {
    Single: "Single",
    Married: "Married",
  };

  const VisaTypes = {
    1: "Student Visa",
    2: "Working Visa",
    3: "SSW Visa",
  };

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [alSubjects, setAlSubjects] = useState(null);
  const [olSubjects, setOlSubjects] = useState(null);
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
  const [OlsubjectDropdownOpen, setOlSubjectDropdownOpen] = useState(false);
  const [selectedOlSubject, setSelectedOlSubject] = useState(null);
  const [selectedOlGrade, setSelectedOLGrade] = useState(null);
  const [gradeOlDropdownOpen, setGradeOlDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [birthDate, setBirthDate] = useState(new Date());
  const [sexDropdownOpen, setSexDropdownOpen] = useState(false);
  const [selectedSex, setSelectedSex] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [eduQualifications, setEduQualifications] = useState(null);
  const [olYearDropdownOpen, setOlYearDropdownOpen] = useState(false);
  const [alYearDropdownOpen, setAlYearDropdownOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [visaDropdownOpen, setVisaDropdownOpen] = useState(false);
  const [selectedVisa, setSelectedVisa] = useState(null);
  const [previewEditThumbnailImage, setPreviewEditThumbnailImage] = useState(null);
  const [selectedVisaType, setSelectedVisaType] = useState(null);

  const [initialStudent, setInitialStudent] = useState({
    ol_results: [],
    al_results: [],
  });

  const [isData, setIsData] = useState({
  });

  const [isError, setIsError] = useState({
    full_name: "",
    email: "",
    phone_number: "",
  });

  const [isEditError, setEditError] = useState({
    full_name: "",
    phone_number: "",
  });

  const getSubjects = async () => {
    try {
      const alSubjects = await getAllActiveSubjects();
      setAlSubjects(alSubjects);
      const olSubjects = await getOLActiveSubjects();
      setOlSubjects(olSubjects);
    } catch (error) {
    }
  }

  const getEducationQualifications = async () => {
    try {
      const response = await getEduQualifications();
      setEduQualifications(response);
    } catch (error) {

    }
  }

  const fetchData = async () => {
    try {
      const response = await getUser();
      setUser(response);
      await getSubjects();
      await getEducationQualifications();
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [selectedClasses, setSelectedClasses] = useState([]);

  const removeSelectedClass = (indexToRemove) => {
    setSelectedClasses(prevSelectedClasses => {
      return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
    });
  };

  const addClass = async () => {
    const newEnrolledClass = {
      subjectId: selectedSubject.id,
      grade: selectedGrade,
    };

    setInitialStudent((prevState) => {
      const isAlreadyEnrolled = prevState.al_results.some(
        (enrolledClass) => enrolledClass.subjectId === newEnrolledClass.subjectId
      );

      if (isAlreadyEnrolled) {
        setFailed(true);
        setErrorMessage('This result is already inserted.');
        return prevState;
      }

      const updatedEnrolledClasses = [
        ...prevState.al_results,
        newEnrolledClass,
      ];

      setIsData((prevData) => ({
        ...prevData,
        a_level_result: updatedEnrolledClasses,
      }));

      return {
        ...prevState,
        al_result: updatedEnrolledClasses,
      };
    });

    setSelectedClasses((prevSelectedClasses) => {
      const isAlreadySelected = prevSelectedClasses.some(
        (selectedClass) => selectedClass.subject === selectedSubject.name
      );
      if (!isAlreadySelected) {
        return [
          ...prevSelectedClasses,
          { grade: selectedGrade, subject: selectedSubject.name, subjectId: selectedSubject.id },
        ];
      }
      return prevSelectedClasses;
    });
  };

  const removeEnrolledClass = (enrolledClassIdToRemove) => {
    setInitialStudent((prevState) => {
      const updatedEnrolledClasses = prevState.al_results.filter(
        (_, index) => index !== enrolledClassIdToRemove
      );

      setIsData((prevData) => ({
        ...prevData,
        a_level_results: updatedEnrolledClasses,
      }));

      return {
        ...prevState,
        al_results: updatedEnrolledClasses,
      };
    });

    removeSelectedClass(enrolledClassIdToRemove);
  };

  const [selectedOLClasses, setSelectedOLClasses] = useState([]);

  const removeSelectedOLClass = (indexToRemove) => {
    setSelectedOLClasses(prevSelectedClasses => {
      return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
    });
  };

  const addOLClass = async () => {
    const newEnrolledClass = {
      subjectId: selectedOlSubject.id,
      grade: selectedOlGrade,
    };

    setInitialStudent((prevState) => {
      const isAlreadyEnrolled = prevState.ol_results.some(
        (ol_result) => ol_result.subjectId === newEnrolledClass.subjectId
      );

      if (isAlreadyEnrolled) {
        setFailed(true);
        setErrorMessage('This result is already inserted.');
        return prevState;
      }

      const updatedEnrolledClasses = [...prevState.ol_results, newEnrolledClass];
      setIsData((prevData) => ({
        ...prevData,
        o_level_result: updatedEnrolledClasses,
      }));

      return {
        ...prevState,
        ol_results: updatedEnrolledClasses,
      };
    });

    setSelectedOLClasses((prevSelectedClasses) => {
      const isAlreadySelected = prevSelectedClasses.some(
        (selectedOLClass) => selectedOLClass.subject === selectedOlSubject.name
      );
      if (!isAlreadySelected) {
        return [
          ...prevSelectedClasses,
          { grade: selectedOlGrade, subject: selectedOlSubject.name, subjectId: selectedOlSubject.id },
        ];
      }
      return prevSelectedClasses;
    });
  };

  const removeEnrolledOlClass = (enrolledClassIdToRemove) => {
    setInitialStudent((prevState) => {
      const updatedEnrolledClasses = prevState.ol_results.filter(
        (_, index) => index !== enrolledClassIdToRemove
      );

      setIsData((prevData) => ({
        ...prevData,
        o_level_results: updatedEnrolledClasses,
        o_level_result: updatedEnrolledClasses,
      }));

      return {
        ...prevState,
        ol_results: updatedEnrolledClasses,
      };
    });
    removeSelectedOLClass(enrolledClassIdToRemove);
  };

  useEffect(() => {
  }, [isData]);

  const handleInputChange = (event) => {
    setIsError({ ...isError, [event.target.name]: "" });
    setIsData({ ...isData, [event.target.name]: event.target.value });
  };

  const isValidStudent = (value) => {
    const errors = {};
    if (value.full_name === '') {
      errors.full_name =
        "Full Name is required.";
    }
    if (value.nic === '') {
      errors.nic =
        "NIC is required.";
    }
    if (value.sex === '') {
      errors.sex =
        "Sex is required.";
    }
    if (value.course_type === '') {
      errors.course_type =
        "Course Type is required.";
    }
    if (value.dob === '') {
      errors.dob =
        "Date of Birth is required.";
    }
    if (value.whatsapp_number === '') {
      errors.whatsapp_number =
        "WhatsApp Number is required.";
    }
    if ((value.whatsapp_number.length !== 10)) {
      errors.whatsapp_number = "Value must be a valid phone number.";
    }
    if (value.mobile_phone_number === '') {
      errors.mobile_phone_number =
        "Phone Number is required.";
    }
    if ((value.mobile_phone_number.length !== 10)) {
      errors.mobile_phone_number = "Value must be a valid phone number.";
    }
    if (value.course_type === '') {
      errors.course_type =
        "Course Type is required.";
    }
    if (Object.keys(errors).length > 0) {
      setIsError(errors);
      return false;
    } else {
      return true;
    }
  };

  const insertNewStudent = async () => {
    if (isValidStudent(isData)) {
      setinsertButtonDisabled(true);
      const { nic, ...isDataWithoutNic } = isData;
      isDataWithoutNic.o_level_result = selectedOLClasses.map(classItem => ({
        grade: classItem.grade,
        subjectId: parseInt(classItem.subjectId, 10)
      }));
      isDataWithoutNic.a_level_result = selectedClasses.map(classItem => ({
        grade: classItem.grade,
        subjectId: parseInt(classItem.subjectId, 10)
      }));
      const updatedQualifications = eduQualifications
        .filter((item) => checkboxStates[item.name])
        .map((item) => ({ id: parseInt(item.id, 10) }));
      isDataWithoutNic.edu_qualifications = updatedQualifications;
      isDataWithoutNic.failed_visa = [{
        visa_type_id: +selectedVisa,
        year: selectedStartyear
      }];
      const response = await updateStudent(isDataWithoutNic);
      if (response.success === true) {
        setSuccessMessage("Succesfully Updated the Student!");
        setSuccess(true);
        fetchData();
        setinsertButtonDisabled(false);
      } else {
        setErrorMessage(response.message);
        setinsertButtonDisabled(false);
        setFailed(true);
      }
    }
  };

  const [previewImage, setPreviewImage] = useState(defaultprofile);
  const inputImgRef = useRef(null);
  const [isPersonalBioOpen, setPersonalBioOpen] = useState(true);
  const [isEduOpen, setEduOpen] = useState(false);
  const [isProfOpen, setProfOpen] = useState(false);
  const [isOtherOpen, setOtherOpen] = useState(false);
  const [isMoreOpen, setMoreOpen] = useState(false);

  const handleThumbnailImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [qualifications, setQualifications] = useState([{ description: "" }]);
  const [errors, setErrors] = useState([]);

  const handleQualificationInputChange = (index, event) => {
    const values = [...isData.qualifications];
    values[index][event.target.name] = event.target.value;
    setQualifications(values);

    setIsData((prevData) => ({
      ...prevData,
      qualifications: values
    }));
  };

  const addQualification = () => {
    const newQualification = { description: "" };
    setQualifications((prevQualifications) => [...prevQualifications, newQualification]);
    setIsData((prevData) => ({
      ...prevData,
      qualifications: [...prevData.qualifications, newQualification]
    }));
  };

  const [otherQualifications, setOtherQualifications] = useState([{ description: "" }]);
  const [otherErrors, setOtherErrors] = useState([]);

  const handleOtherQualificationInputChange = (index, event) => {
    const values = [...isData.other_qualifications];
    values[index][event.target.name] = event.target.value;
    setOtherQualifications(values);

    // Update the isData state with the new qualifications
    setIsData((prevData) => ({
      ...prevData,
      other_qualifications: values
    }));
  };

  const addOtherQualification = () => {
    const newQualification = { description: "" };
    setOtherQualifications((prevQualifications) => [...prevQualifications, newQualification]);
    setIsData((prevData) => ({
      ...prevData,
      other_qualifications: [...prevData.other_qualifications, newQualification]
    }));
  };

  const [exams, setExams] = useState([{ exam: "" }]);
  const [examErrors, setExamErrors] = useState([]);

  const handleJapanseExamInputChange = (index, event) => {
    const values = [...isData.completed_exams];
    values[index][event.target.name] = event.target.value;
    setExams(values);
    setIsData((prevData) => ({
      ...prevData,
      completed_exams: values
    }));
  };

  const addExams = () => {
    const newQualification = { exam: "" };
    setExams((prevQualifications) => [...prevQualifications, newQualification]);

    setIsData((prevData) => ({
      ...prevData,
      completed_exams: [...prevData.completed_exams, newQualification]
    }));
  };

  const [selectedGuardian, setSelectedGuardian] = useState('');
  const [selectedBusinessOption, setSelectedBusinessOption] = useState('');
  const [selectedFamilyBusinessOption, setSelectedFamilyBusinessOption] = useState('');
  const [selectedSponsor, setSelectedSponsor] = useState('');
  const [selectedExams, setSelectedExams] = useState('');
  const [beforeTry, setBeforeTry] = useState('');
  const [loadingOLSubjects, setLoadingOLSubjects] = useState(false);
  const [loadingAlSubjects, setLoadingALSubjects] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  const handleRadioChange = (guardian) => {
    setSelectedGuardian(guardian)
  };

  const handleTypeChange = (type) => {
    setSelectedType(type)
  };

  const filterAttendanceByDate = async (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setStartDate(newDate);
    setIsData((prevData) => ({ ...prevData, guardian_dob: newDate }))
  }

  const [yearListDropdownOpen, setYearsDropdownOpen] = useState(false);
  const [selectedStartyear, setSelectedStartYear] = useState(null);

  const yearList = Array.from({ length: 45 }, (v, i) => {
    return { name: i + 1980 };
  });

  const setDateOfBirth = async (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setBirthDate(newDate);
    setIsData((prevData) => ({ ...prevData, dob: newDate }))
  }

  const getSubjectsOL = async () => {
    setLoadingOLSubjects(true);
    const response = await getOLActiveSubjects();
    setOlSubjects(response);
    setLoadingOLSubjects(false);
  };

  const getSubjectsAL = async () => {
    setLoadingALSubjects(true);
    const response = await getAllActiveSubjects();
    setAlSubjects(response);
    setLoadingALSubjects(false);
  };
  const [checkboxStates, setCheckboxStates] = useState({});

  const [selectedEduQualifications, setSelectedEduQualifications] = useState({ edu_qualifications: [] })

  const handleCheckboxChange = (item) => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [item.name]: !prevStates[item.name],
    }));
  };

  const [selectedOlyear, setSelectedOlyear] = useState(null);

  const handleOlYear = (year) => {
    setSelectedOlyear(year);
    setIsError({ ...isError, o_level_exam_year: "" });
    setIsData({ ...isData, o_level_exam_year: year.name });
    setOlYearDropdownOpen(false);
  };

  const [selectedAlyear, setSelectedAlyear] = useState(null);

  const handleAlYear = (year) => {
    setSelectedAlyear(year);
    setIsError({ ...isError, a_level_exam_year: "" });
    setIsData({ ...isData, a_level_exam_year: year.name });
    setAlYearDropdownOpen(false);
  };

  const range = (start, end, step) => {
    let output = [];
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  };

  const years = range(1950, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const nicInputRef = useRef(null);
  const registrationNumberInputRef = useRef(null);
  const batchNumberInputRef = useRef(null);
  const [nicInput, setNicInput] = useState('');
  const [registrationNumberInput, setRegistrationNumberInput] = useState('');
  const [batchNoInput, setBatchNoInput] = useState('');
  const [isReceivedData, setReceivedData] = useState(false);
  const [isStudentList, setStudentList] = useState(false);

  const getStudentSearch = async (e) => {
    if (e.target.name === 'nic') {
      setNicInput(e.target.value);
    }
    if (e.target.name === 'registrationNumberInput') {
      setRegistrationNumberInput(e.target.value);
    }
    if (e.target.name === 'batchNo') {
      setBatchNoInput(e.target.value);
    }
  };

  const [dataALresults, setDataAlResults] = useState([]);
  const [allSubjects, setAllSubjects] = useState(null);

  const getStudent = async (type, searchValue) => {
    setAllSubjects(null);
    setFilteredStudents(null)
    setStudentList(false);
    setReceivedData(false);
    if (type === 'nic' || type === 'student_number') {
      setStudentList(false);
    }
    const body = { [type]: searchValue };
    try {
      const response = await getRegisteredStudent(body);
      if (response.length > 0 && response.length < 2) {
        setIsData(response[0]);
        if (response[0].student_edu_qualifications.length > 0) {
          const initialStates = {};
          response[0].student_edu_qualifications.forEach((qualification) => {
            initialStates[qualification.edu_qualification.name] = true;
          });
          setCheckboxStates(initialStates);
        }
        setReceivedData(true);
        const newOLClasses = response[0].o_level_results.map((qualification) => ({
          subject: qualification.o_level_subject.name,
          subjectId: qualification.o_level_subject.id,
          grade: qualification.grade,
        }));
        setSelectedOLClasses(newOLClasses);
        const newALClasses = response[0].a_level_results.map((qualification) => ({
          subject: qualification.a_level_subject.name,
          subjectId: qualification.a_level_subject.id,
          grade: qualification.grade,
        }));
        setSelectedClasses(newALClasses);
        setSelectedVisaType(response[0].failed_visa[0]?.visa_type.id);
        setSelectedStartYear(response[0].failed_visa[0]?.year);
      }
      if (response.length > 1) {
        setStudentList(true);
        setAllSubjects(response);
      }
      if (response.success === false) {
      }
    } catch (error) {
      setIsData({});
      setReceivedData(false);
    }
  };

  const viewStudentFromList = async (response) => {
    setIsData(response);
    setStudentList(false);
    setFilteredStudents(null);
    response.student_edu_qualifications.forEach((qualification) => {
      handleCheckboxChange(qualification.edu_qualification);
    });
    setReceivedData(true);
    const newOLClasses = response.o_level_results.map((qualification) => ({
      subject: qualification.o_level_subject.name,
      subjectId: qualification.o_level_subject.id,
      grade: qualification.grade,
    }));
    setSelectedOLClasses(newOLClasses);
    const newALClasses = response.a_level_results.map((qualification) => ({
      subject: qualification.a_level_subject.name,
      subjectId: qualification.a_level_subject.id,
      grade: qualification.grade,
    }));
    setSelectedClasses(newALClasses);
    setSelectedVisaType(response.failed_visa[0]?.visa_type.id);
    setSelectedStartYear(response.failed_visa[0]?.year);
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(null);

  const searchSubject = async (searchQuery) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    setFilteredStudents(allSubjects.filter(student =>
      student.full_name.toLowerCase().includes(lowerCaseQuery)
    ));
  };

  const [editImage, setEditImage] = useState(null);

  const handleEditImageChange = (event) => {
    const file = event.target.files[0];
    setEditImage({ image: file });
    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewEditThumbnailImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const addProfileImage = async () => {
    try {
      const response = await editProfileImage(editImage, isData.id);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
      } else {
        setErrorMessage(response.message);
        setFailed(true);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setFailed(true);
    }
  }

  const downloadQRCode = async (student) => {
    try {
      // Create a container for the card component
      const container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.top = '-9999px';
      document.body.appendChild(container);

      // Create a root for rendering the CardComponent
      const root = createRoot(container);

      // Render the CardComponent into the container
      root.render(<QRCard student={student.student_number} />);

      // Wait for a moment to allow the content to render
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Use html2canvas to capture the content and convert it to an image
      const canvas = await html2canvas(container, {
        scale: 3 // Increase the scale factor to improve resolution
      });

      // Resize the canvas to the desired width (1080px)
      const targetWidth = 360;
      const targetHeight = (canvas.height / canvas.width) * targetWidth;
      const resizedCanvas = document.createElement('canvas');
      resizedCanvas.width = targetWidth;
      resizedCanvas.height = targetHeight;
      const context = resizedCanvas.getContext('2d');
      context.drawImage(canvas, 0, 0, targetWidth, targetHeight);

      // Convert the resized canvas to a data URL
      const imgData = resizedCanvas.toDataURL('image/png');

      // Create a temporary link element to trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = imgData;
      downloadLink.download = `${student.student_number}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      root.unmount();
      document.body.removeChild(container);
    } catch (error) {
    }
  };
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 pt-4 shadow">
          <div className="ml-4">
            <Row>
              <Col sm="6" md="6" lg="6">
                <FormGroup>
                  <Input
                    id="nic"
                    placeholder="NIC"
                    type="text"
                    value={nicInput}
                    onChange={getStudentSearch}
                    name="nic"
                    ref={nicInputRef}
                  />
                </FormGroup>
              </Col>
              <Col sm="6" md="6" lg="6">
                <Button color="primary"
                  type="button"
                  onClick={() => {
                    getStudent('nic', nicInput);
                  }}>Search</Button>
              </Col>
            </Row>
            <Row>
              <Col sm="6" md="6" lg="6">
                <FormGroup>
                  <Input
                    id="registrationNumberInput"
                    placeholder="Registration Number"
                    type="text"
                    value={registrationNumberInput}
                    onChange={getStudentSearch}
                    name="registrationNumberInput"
                    ref={registrationNumberInputRef}
                  />
                </FormGroup>
              </Col>
              <Col sm="6" md="6" lg="6">
                <Button color="primary"
                  type="button"
                  onClick={() => {
                    getStudent('student_number', registrationNumberInput);
                  }}>Search</Button>
              </Col>
            </Row>
            <Row>
              <Col sm="6" md="6" lg="6">
                <FormGroup>
                  <Input
                    id="batchNo"
                    placeholder="Batch Number"
                    type="text"
                    value={batchNoInput}
                    onChange={getStudentSearch}
                    name="batchNo"
                    ref={batchNumberInputRef}
                  />
                </FormGroup>
              </Col>
              <Col sm="6" md="6" lg="6">
                <Button color="primary"
                  type="button"
                  onClick={() => {
                    getStudent('batch_number', batchNoInput);
                  }}>Search</Button>
              </Col>
            </Row>
          </div>
        </Card>
        <Card>
          {isStudentList ?
            <div className="m-4">
              <Row>
                <Col>
                </Col>
                <Col className="justify-content-end mb-4" md="4">
                  <Input
                    type="text"
                    placeholder="Search by name"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      searchSubject(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Registration Number</th>
                    <th scope="col">Name</th>
                    <th scope="col">NIC</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredStudents !== null ?
                    filteredStudents?.map((subject, index) => (
                      <tr key={index}>
                        <td>{subject.student_number}</td>
                        <td>{subject.full_name}</td>
                        <td>{subject.nic}</td>
                        <td>{subject.mobile_phone_number}</td>
                        <td>
                          <Button
                            color="primary"
                            type="button"
                            id="editSubject"
                            onClick={() => viewStudentFromList(subject)}
                          >
                            View
                          </Button>
                          <Button color="secondary"
                            type="button"
                            id="downloadId"
                            onClick={() => downloadQRCode(subject)}>
                            <LiaQrcodeSolid />
                          </Button>
                        </td>
                      </tr>
                    )) :
                    allSubjects?.map((subject, index) => (
                      <tr key={index}>
                        <td>{subject.student_number}</td>
                        <td>{subject.full_name}</td>
                        <td>{subject.nic}</td>
                        <td>{subject.mobile_phone_number}</td>
                        <td>
                          <Button
                            color="primary"
                            type="button"
                            id="editSubject"
                            onClick={() => viewStudentFromList(subject)}
                          >
                            View
                          </Button>
                          <Button color="secondary"
                            type="button"
                            id="downloadId"
                            onClick={() => downloadQRCode(subject)}>
                            <LiaQrcodeSolid />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            : null}
          {isReceivedData ?
            <Form className="ml-4 mb-4 mr-4 pt-4">
              <div className="mb-4 studentSectionTitles" onClick={() => setPersonalBioOpen(!isPersonalBioOpen)} style={{ cursor: "pointer" }}>
                Personal Details  {!isPersonalBioOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
              </div>
              <Collapse isOpen={isPersonalBioOpen}>
                <Row className="nameWithImage">
                  <Col md="12" lg="8">
                    <FormGroup>
                      <Label>Registration Number</Label>
                      <Input
                        id="student_number"
                        type="text"
                        value={isData.student_number}
                        name="student_number"
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Full Name <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        id="full_name"
                        type="text"
                        value={isData.full_name}
                        onChange={handleInputChange}
                        name="full_name"
                      />
                      {isError.full_name && (
                        <p className="text-danger">{isError.full_name}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label>Name with Initials</Label>
                      <Input
                        id="name_with_initials"
                        type="text"
                        value={isData.name_with_initials}
                        onChange={handleInputChange}
                        name="name_with_initials"
                      />
                      {isError.name_with_initials && (
                        <p className="text-danger">{isError.name_with_initials}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label>Address</Label>
                      <Input
                        id="address"
                        type="textarea"
                        value={isData.address}
                        onChange={handleInputChange}
                        name="address"
                      />
                      {isError.address && (
                        <p className="text-danger">{isError.address}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" className="stuImgSection mt-3">
                    <FormGroup>
                      <div className="studentImgPrv">
                        <img
                          src={
                            previewEditThumbnailImage
                              ? previewEditThumbnailImage
                              : isData?.image_url ? isData.image_url : 'https://ape-sense.s3.amazonaws.com/student-image/image1724930888205'
                          }
                          alt="Selected or Default Preview"
                          style={{
                            width: "200px",
                            height: "100%",
                            objectFit: "cover",
                            border: "1px solid #ccc",
                            borderRadius: "8px"
                          }}
                        />
                        <input
                          id="thumbnailImage"
                          type="file"
                          name="image"
                          accept="image/*"
                          ref={inputImgRef}
                          onChange={handleEditImageChange}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          addProfileImage();
                        }}
                        style={{
                          width: "200px",
                          marginTop: "20px"
                        }}
                        className="imageUploadBtn"
                      >
                        Save
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>NIC <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        id="nic"
                        type="text"
                        value={isData.nic}
                        onChange={handleInputChange}
                        name="nic"
                      />
                      {isError.nic && (
                        <p className="text-danger">{isError.nic}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Passport</Label>
                      <Input
                        id="passport_number"
                        type="text"
                        value={isData.passport_number}
                        onChange={handleInputChange}
                        name="passport"
                      />
                      {isError.passport_number && (
                        <p className="text-danger">{isError.passport_number}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6" lg="3">
                    <FormGroup>
                      <Label>Sex</Label>  <br />
                      <FormGroup>
                        <Dropdown
                          isOpen={sexDropdownOpen}
                          toggle={() => setSexDropdownOpen(!sexDropdownOpen)}
                        >
                          <DropdownToggle caret>
                            {isData
                              ? isData.sex
                              : "Select a Sex"}
                          </DropdownToggle>
                          <DropdownMenu>
                            {Object.entries(Sex).map(([key, value]) => (
                              <DropdownItem
                                key={value}
                                value={value}
                                onClick={() => [
                                  setIsData((prevData) => ({ ...prevData, sex: value.toUpperCase() })),
                                ]}
                              >
                                {value}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label>Status</Label>  <br />
                      <FormGroup>
                        <Dropdown
                          isOpen={statusDropdownOpen}
                          toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                        >
                          <DropdownToggle caret>
                            {isData.marital !== null
                              ? isData.marital
                              : "Select a Status"}
                          </DropdownToggle>
                          <DropdownMenu>
                            {Object.entries(Status).map(([key, value]) => (
                              <DropdownItem
                                key={value}
                                value={value}
                                onClick={() => [
                                  setIsData((prevData) => ({ ...prevData, marital: value.toUpperCase() })),
                                ]}
                              >
                                {value}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="5">
                    <FormGroup>
                      <Label>Date of Birth</Label>  <br />
                      <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        showIcon
                        selected={isData ? isData.dob : birthDate}
                        onChange={(date) => {
                          setDateOfBirth(date)
                        }}
                        className="dateSelector"
                        icon="fa fa-calendar"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <Label>Land phone Number</Label>
                      <Input
                        id="lane_phone_number"
                        type="text"
                        value={isData.lane_phone_number}
                        onChange={handleInputChange}
                        name="lane_phone_number"
                      />
                      {isError.lane_phone_number && (
                        <p className="text-danger">{isError.lane_phone_number}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <Label>Mobile Number <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        id="mobile_phone_number"
                        type="text"
                        value={isData.mobile_phone_number}
                        onChange={handleInputChange}
                        name="mobile_phone_number"
                      />
                      {isError.mobile_phone_number && (
                        <p className="text-danger">{isError.mobile_phone_number}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <Label>WhatsApp Number</Label>
                      <Input
                        id="whatsapp_number"
                        type="text"
                        value={isData.whatsapp_number}
                        onChange={handleInputChange}
                        name="whatsapp_number"
                      />
                      {isError.whatsapp_number && (
                        <p className="text-danger">{isError.whatsapp_number}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Batch Number</Label>
                      <Input
                        id="batch_number"
                        type="text"
                        value={isData.batch_number}
                        onChange={handleInputChange}
                        name="batch_number"
                      />
                      {isError.batch_number && (
                        <p className="text-danger">{isError.batch_number}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup tag="fieldset" className="fieldSetPayOptions">
                      <Label>Course Type <span style={{ color: "red" }}>*</span></Label>
                      <div className="radioOptions">
                        <FormGroup check>
                          <Input
                            id="inRadio"
                            name="typeRadio"
                            type="radio"
                            checked={isData?.course_type === 'IN'}
                            onChange={() => {
                              handleTypeChange('IN');
                              setIsData((prevData) => ({ ...prevData, course_type: 'IN' }));
                            }}
                            style={{ transform: 'scale(1.5)' }}
                          />
                          {' '}
                          <Label check for="inRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                            In
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input
                            id="outRadio"
                            name="typeRadio"
                            type="radio"
                            checked={isData?.course_type === 'OUT'}
                            onChange={() => {
                              handleTypeChange('OUT');
                              setIsData((prevData) => ({ ...prevData, course_type: 'OUT' }));
                            }}
                            style={{ transform: 'scale(1.5)' }}
                          />
                          {' '}
                          <Label check for="outRadio" className="ml-2" style={{ fontSize: "18px" }} >
                            Out
                          </Label>
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              <div className="mb-4 studentSectionTitles" onClick={() => setEduOpen(!isEduOpen)} style={{ cursor: "pointer" }}>
                Educational Qualifications  {!isEduOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
              </div>
              <Collapse isOpen={isEduOpen} >
                <Row>
                  <Col md="12" lg="12">
                    <div className="eduChecks">
                      {eduQualifications !== null ? eduQualifications.map((item) => (
                        <FormGroup key={item.id} check inline className="mb-2">
                          <Input
                            className="large-checkbox"
                            type="checkbox"
                            id={item.id}
                            checked={checkboxStates[item.name]}
                            onChange={() => handleCheckboxChange(item)}
                          />
                          <Label className="ml-2 mr-4" check htmlFor={item.id}>
                            {item.name}
                          </Label>
                        </FormGroup>
                      )) : null}
                    </div>
                    <div className="alSection mt-4">
                      <Label style={{ fontWeight: "bold" }}>GCE Ordinary Level Examination</Label>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Exam No</Label>
                            <Input
                              id="o_level_exam_no"
                              type="text"
                              value={isData.o_level_exam_no}
                              onChange={handleInputChange}
                              name="o_level_exam_no"
                            />
                            {isError.o_level_exam_no && (
                              <p className="text-danger">{isError.o_level_exam_no}</p>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Year</Label>
                            <Dropdown
                              isOpen={olYearDropdownOpen}
                              toggle={() =>
                                setOlYearDropdownOpen(!olYearDropdownOpen)
                              }
                            >
                              <DropdownToggle caret>
                                {isData.o_level_exam_year
                                  ? isData.o_level_exam_year
                                  : "Year"}
                              </DropdownToggle>
                              <DropdownMenu
                                style={{ maxHeight: 250, overflowY: "auto" }}
                              >
                                {yearList?.map((year) => (
                                  <DropdownItem
                                    key={year.id}
                                    value={year.id}
                                    onClick={() =>
                                      handleOlYear(year)
                                    }
                                  >
                                    {year.name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label>School</Label>
                        <Input
                          id="o_level_exam_school"
                          type="text"
                          value={isData.o_level_exam_school}
                          onChange={handleInputChange}
                          name="o_level_exam_school"
                        />
                        {isError.o_level_exam_school && (
                          <p className="text-danger">{isError.o_level_exam_school}</p>
                        )}
                      </FormGroup>
                      <div>
                        {selectedOLClasses.length > 0 ?
                          <Table bordered className="align-items-center resultTable mb-4" responsive>
                            <thead>
                              <tr>
                                <th>Subject</th>
                                <th>Grade</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedOLClasses?.map((selectedClass, index) => (
                                <tr key={index}>
                                  <td>{selectedClass.subject}</td>
                                  <td>{selectedClass.grade}  </td>
                                  <td><span style={{ cursor: "pointer", color: "red" }} onClick={() => removeEnrolledOlClass(index)}>Remove</span></td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          : null}
                      </div>
                      <Row style={{ justifyContent: "space-between" }}>
                        <Col md="4">
                          <FormGroup>
                            <Label>Subject
                              {user?.role.name === 'admin' ?
                                <Link to="/admin/ol-subjects" target="_blank" style={{ cursor: "pointer", color: "black", marginLeft: "5px" }}><GoPlusCircle /></Link>
                                : user?.role.name === 'office assistant' ?
                                  <Link to="/assistant/ol-subjects" target="_blank" style={{ cursor: "pointer", color: "black", marginLeft: "5px" }}><GoPlusCircle /></Link>
                                  : null}
                              {loadingOLSubjects ?
                                <Spinner className="m-10" size={"sm"}></Spinner> : <IoMdRefresh onClick={getSubjectsOL} style={{ cursor: "pointer" }} />
                              }
                            </Label>
                            <br />
                            <Dropdown
                              isOpen={OlsubjectDropdownOpen}
                              toggle={() =>
                                setOlSubjectDropdownOpen(!OlsubjectDropdownOpen)
                              }
                            >
                              <DropdownToggle caret>
                                {selectedOlSubject ? selectedOlSubject.name : "Subject"}
                              </DropdownToggle>
                              {olSubjects && olSubjects.length > 0 ? (
                                <DropdownMenu>
                                  {olSubjects && olSubjects.map((subject) => (
                                    <DropdownItem
                                      key={subject.name}
                                      value={subject.id}
                                      onClick={() => {
                                        setSelectedOlSubject(subject);
                                        setOlSubjectDropdownOpen(false);
                                      }}
                                    >
                                      {subject.name}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              ) : null}
                            </Dropdown>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Grade</Label>
                            <Dropdown
                              isOpen={gradeOlDropdownOpen}
                              toggle={() => setGradeOlDropdownOpen(!gradeOlDropdownOpen)}
                            >
                              <DropdownToggle caret>
                                {selectedOlGrade
                                  ? selectedOlGrade
                                  : "Select a Grade"}
                              </DropdownToggle>
                              <DropdownMenu>
                                {Object.entries(Grades).map(([key, value]) => (
                                  <DropdownItem
                                    key={value}
                                    value={value}
                                    onClick={() => [
                                      setSelectedOLGrade(value)
                                    ]}
                                  >
                                    {value}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="addBtnWithIcon">
                            <Button color="primary" style={{ height: "100%" }} onClick={() => addOLClass()} disabled={selectedOlSubject === null || selectedOlGrade === null}>✓</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="alSection mt-4">
                      <Label style={{ fontWeight: "bold" }}>GCE Advance Level Examination</Label>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Exam No</Label>
                            <Input
                              id="a_level_exam_no"
                              type="text"
                              value={isData.a_level_exam_no}
                              onChange={handleInputChange}
                              name="a_level_exam_no"
                            />
                            {isError.a_level_exam_no && (
                              <p className="text-danger">{isError.a_level_exam_no}</p>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Year</Label>
                            <Dropdown
                              isOpen={alYearDropdownOpen}
                              toggle={() =>
                                setAlYearDropdownOpen(!alYearDropdownOpen)
                              }
                            >
                              <DropdownToggle caret>
                                {isData.a_level_exam_year
                                  ? isData.a_level_exam_year
                                  : "Year"}
                              </DropdownToggle>
                              <DropdownMenu
                                style={{ maxHeight: 250, overflowY: "auto" }}
                              >
                                {yearList?.map((year) => (
                                  <DropdownItem
                                    key={year.id}
                                    value={year.id}
                                    onClick={() =>
                                      handleAlYear(year)
                                    }
                                  >
                                    {year.name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label>School</Label>
                        <Input
                          id="a_level_exam_school"
                          type="text"
                          value={isData.a_level_exam_school}
                          onChange={handleInputChange}
                          name="a_level_exam_school"
                        />
                        {isError.a_level_exam_school && (
                          <p className="text-danger">{isError.a_level_exam_school}</p>
                        )}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <div>
                  {selectedClasses.length > 0 ?
                    <Table bordered className="align-items-center resultTable mb-4" responsive>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>Grade</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedClasses?.map((selectedClass, index) => (
                          <tr key={index}>
                            <td>{selectedClass.subject}</td>
                            <td>{selectedClass.grade}  </td>
                            <td><span style={{ cursor: "pointer", color: "red" }} onClick={() => removeEnrolledClass(index)}>Remove</span></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    : null}
                </div>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col md="4">
                    <FormGroup>
                      <Label>Subject
                        {user?.role.name === 'admin' ?
                          <Link to="/admin/subjects" target="_blank" style={{ cursor: "pointer", color: "black", marginLeft: "5px" }}><GoPlusCircle /></Link>
                          : user?.role.name === 'office assistant' ?
                            <Link to="/assistant/subjects" target="_blank" style={{ cursor: "pointer", color: "black", marginLeft: "5px" }}><GoPlusCircle /></Link>
                            : null}
                        {loadingAlSubjects ?
                          <Spinner className="m-10" size={"sm"}></Spinner> : <IoMdRefresh onClick={getSubjectsAL} style={{ cursor: "pointer" }} />
                        }
                      </Label>
                      <br />
                      <Dropdown
                        isOpen={subjectDropdownOpen}
                        toggle={() =>
                          setSubjectDropdownOpen(!subjectDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedSubject ? selectedSubject.name : "Subject"}
                        </DropdownToggle>
                        {alSubjects && alSubjects.length > 0 ? (
                          <DropdownMenu>
                            {alSubjects && alSubjects.map((subject) => (
                              <DropdownItem
                                key={subject.name}
                                value={subject.id}
                                onClick={() => {
                                  setSelectedSubject(subject);
                                  setSubjectDropdownOpen(false);
                                }}
                              >
                                {subject.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Grade</Label>
                      <Dropdown
                        isOpen={gradeDropdownOpen}
                        toggle={() => setGradeDropdownOpen(!gradeDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedGrade
                            ? selectedGrade
                            : "Select a Grade"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(Grades).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setSelectedGrade(value)
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="addBtnWithIcon">
                      <Button color="primary" style={{ height: "100%" }} onClick={() => addClass()} disabled={selectedSubject === null || selectedGrade === null}>✓</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              <div className="mb-4 studentSectionTitles" onClick={() => setProfOpen(!isProfOpen)} style={{ cursor: "pointer" }}>
                Professional Qualifications  {!isProfOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
              </div>
              <Collapse isOpen={isProfOpen}>
                <Label>Qualifications</Label>
                <Row className="nameWithImage">
                  {isData.qualifications?.map((qualification, index) => (
                    <Col md="10" key={index}>
                      <FormGroup>
                        <Input
                          id={`description${index}`}
                          type="text"
                          value={qualification.description || ''}
                          onChange={(event) => handleQualificationInputChange(index, event)}
                          name="description"
                        />
                        {errors[index] && errors[index].profQualification && (
                          <p className="text-danger">{errors[index].profQualification}</p>
                        )}
                      </FormGroup>
                    </Col>
                  ))}
                  {isData.qualifications.every((qualification) => qualification.description) && (
                    <Col md="2">
                      <i
                        className="fa-solid fa-circle-plus fa-2x mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={addQualification}
                      ></i>
                    </Col>
                  )}
                </Row>
                <Label>Other</Label>
                <Row className="nameWithImage">
                  {isData?.other_qualifications?.map((qualification, index) => (
                    <Col md="10" key={index}>
                      <FormGroup>
                        <Input
                          id={`description${index}`}
                          type="text"
                          value={qualification.description || ''}
                          onChange={(event) => handleOtherQualificationInputChange(index, event)}
                          name="description"
                        />
                        {otherErrors[index] && otherErrors[index].otherQualification && (
                          <p className="text-danger">{otherErrors[index].otherQualification}</p>
                        )}
                      </FormGroup>
                    </Col>
                  ))}
                  {isData.other_qualifications.every((qualification) => qualification.description) && (
                    <Col md="2">
                      <i
                        className="fa-solid fa-circle-plus fa-2x mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={addOtherQualification}
                      ></i>
                    </Col>
                  )}
                </Row>
              </Collapse>
              <div className="mb-4 studentSectionTitles" onClick={() => setOtherOpen(!isOtherOpen)} style={{ cursor: "pointer" }}>
                Other  {!isOtherOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
              </div>
              <Collapse isOpen={isOtherOpen} >
                <FormGroup tag="fieldset" className="fieldSetPayOptions ml-1">
                  <div className="radioOptions">
                    <FormGroup check>
                      <Input
                        id="motherRadio"
                        name="guardian"
                        type="radio"
                        checked={isData?.guardian === "MOTHER"}
                        onChange={() => {
                          handleRadioChange('MOTHER');
                          setIsData((prevData) => ({ ...prevData, guardian: 'MOTHER' }));
                        }
                        }
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="motherRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Mother
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="fatherRadio"
                        name="guardian"
                        type="radio"
                        checked={isData?.guardian === "FATHER"}
                        onChange={() => {
                          handleRadioChange('FATHER');
                          setIsData((prevData) => ({ ...prevData, guardian: 'FATHER' }));
                        }
                        }
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="fatherRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Father
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="guardianRadio"
                        name="guardian"
                        type="radio"
                        checked={isData?.guardian === "GUARDIAN"}
                        onChange={() => {
                          handleRadioChange('GUARDIAN');
                          setIsData((prevData) => ({ ...prevData, guardian: 'GUARDIAN' }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="guardianRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Guardian
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Full Name</Label>
                      <Input
                        id="guardianName"
                        type="text"
                        value={isData?.guardian_full_name}
                        onChange={handleInputChange}
                        name="guardian_full_name"
                      />
                      {isError.guardianName && (
                        <p className="text-danger">{isError.guardianName}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="5">
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <Input
                        id="guardianPhoneNo"
                        type="text"
                        value={isData?.guardian_phone_number}
                        onChange={handleInputChange}
                        name="guardian_phone_number"
                      />
                      {isError.guardianPhoneNo && (
                        <p className="text-danger">{isError.guardianPhoneNo}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="7">
                    <FormGroup>
                      <Label>Date of Birth</Label>  <br />
                      <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        showIcon
                        selected={isData ? isData.guardian_dob : birthDate}
                        onChange={(date) => filterAttendanceByDate(date)}
                        icon="fa fa-calendar"
                        className="dateSelector"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Do you have a government registered busiess?</div> <br />
                    <FormGroup check>
                      <Input
                        id="bYesRadio"
                        name="businessReg"
                        type="radio"
                        checked={isData?.own_business === true}
                        onChange={() => {
                          setSelectedBusinessOption(true);
                          setIsData((prevData) => ({ ...prevData, own_business: true }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="bYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="bNoRadio"
                        name="businessReg"
                        type="radio"
                        checked={isData?.own_business === false}
                        onChange={() => {
                          setSelectedBusinessOption(false);
                          setIsData((prevData) => ({ ...prevData, own_business: false }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="bNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        id="businessDescription"
                        type="text"
                        value={isData?.own_business_description}
                        onChange={handleInputChange}
                        name="own_business_description"
                      />
                      {isError.businessDescription && (
                        <p className="text-danger">{isError.businessDescription}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Do your mother/father owns a government registered busiess?</div> <br />
                    <FormGroup check>
                      <Input
                        id="familyYesRadio"
                        name="familyBusinessReg"
                        type="radio"
                        checked={isData?.guardian_business === true}
                        onChange={() => {
                          setSelectedFamilyBusinessOption(true);
                          setIsData((prevData) => ({ ...prevData, guardian_business: true }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="familyYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="familyNoRadio"
                        name="familyBusinessReg"
                        type="radio"
                        checked={isData?.guardian_business === false}
                        onChange={() => {
                          setSelectedFamilyBusinessOption(false);
                          setIsData((prevData) => ({ ...prevData, guardian_business: false }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="familyNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        id="familyBusinessDescription"
                        type="text"
                        value={isData?.guardian_business_description}
                        onChange={handleInputChange}
                        name="familyBusinessDescription"
                      />
                      {isError.familyBusinessDescription && (
                        <p className="text-danger">{isError.familyBusinessDescription}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Do you have a sponsor who supports going to Japan?</div> <br />
                    <FormGroup check>
                      <Input
                        id="sponsorYesRadio"
                        name="sponsorDetails"
                        type="radio"
                        checked={isData?.have_sponsor === true}
                        onChange={() => {
                          setSelectedSponsor(true);
                          setIsData((prevData) => ({ ...prevData, have_sponsor: true }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="sponsorYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="sponsorNoRadio"
                        name="sponsorDetails"
                        type="radio"
                        checked={isData?.have_sponsor === false}
                        onChange={() => {
                          setSelectedSponsor(false);
                          setIsData((prevData) => ({ ...prevData, have_sponsor: false }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="sponsorNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Relationship with the sponsor</Label>
                      <Input
                        id="sponsorRelation"
                        type="text"
                        value={isData?.sponsor_relationship}
                        onChange={handleInputChange}
                        name="sponsor_relationship"
                      />
                      {isError.sponsorRelation && (
                        <p className="text-danger">{isError.sponsorRelation}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Do you have faced Japanese examinations before?</div> <br />
                    <FormGroup check>
                      <Input
                        id="examYesRadio"
                        name="examDetails"
                        type="radio"
                        checked={isData?.faced_japanese_exam === true}
                        onChange={() => {
                          setSelectedExams(true);
                          setIsData((prevData) => ({ ...prevData, faced_japanese_exam: true }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="examYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="examNoRadio"
                        name="examDetails"
                        type="radio"
                        checked={isData?.faced_japanese_exam === false}
                        onChange={() => {
                          setSelectedExams(false);
                          setIsData((prevData) => ({ ...prevData, faced_japanese_exam: false }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="examNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Label>Exams</Label><br />
                <Row className="nameWithImage">
                  {isData?.completed_exams?.map((exam, index) => (
                    <Col md="10" key={index}>
                      <FormGroup>
                        <Input
                          id={`exam${index}`}
                          type="text"
                          value={exam.exam}
                          onChange={(event) => handleJapanseExamInputChange(index, event)}
                          name="exam"
                        />
                        {examErrors[index] && examErrors[index].japanseExams && (
                          <p className="text-danger">{examErrors[index].japanseExams}</p>
                        )}
                      </FormGroup>
                    </Col>
                  ))}
                  {isData.completed_exams.every((exam) => exam.exam) && (
                    <Col md="2">
                      <i
                        className="fa-solid fa-circle-plus fa-2x mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={addExams}
                      ></i>
                    </Col>
                  )}
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Have you tried going to Japan before?</div> <br />
                    <FormGroup check>
                      <Input
                        id="beforeTryYesRadio"
                        name="beforeDetails"
                        type="radio"
                        checked={isData?.tried_to_visit_japan === true}
                        onChange={() => {
                          setBeforeTry(true);
                          setIsData((prevData) => ({ ...prevData, tried_to_visit_japan: true }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="beforeTryYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="beforeTryNoRadio"
                        name="beforeDetails"
                        type="radio"
                        checked={isData?.tried_to_visit_japan === false}
                        onChange={() => {
                          setBeforeTry(false);
                          setIsData((prevData) => ({ ...prevData, tried_to_visit_japan: false }));
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="beforeTryNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label>If you failed to visit, explain the reasons.</Label>
                      <Input
                        id="failedReasons"
                        type="text"
                        value={isData?.failed_reason}
                        onChange={handleInputChange}
                        name="failed_reason"
                      />
                      {isError.failedReasons && (
                        <p className="text-danger">{isError.failedReasons}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label>Visa Type</Label>
                      <Dropdown
                        isOpen={visaDropdownOpen}
                        toggle={() => setVisaDropdownOpen(!visaDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedVisaType
                            ? selectedVisaType === "1" ? "Student Visa" :
                              selectedVisaType === "2" ? "Working Visa" : "SSW Visa"
                            : "Select a Visa Type"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(VisaTypes).map(([key, value]) => (
                            <DropdownItem
                              key={key}
                              value={value}
                              onClick={() => [
                                setSelectedVisaType(key),
                                setSelectedVisa(key, value),
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Year</Label>
                      <Dropdown
                        isOpen={yearListDropdownOpen}
                        toggle={() =>
                          setYearsDropdownOpen(!yearListDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedStartyear ? selectedStartyear : "Start Year"}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: 250, overflowY: "auto" }}
                        >
                          {yearList?.map((year) => (
                            <DropdownItem
                              key={year.id}
                              value={year.id}
                              onClick={() => {
                                setSelectedStartYear(year.name);
                                setIsData((prevState) => ({
                                  ...prevState,
                                  failed_visa: {
                                    ...prevState.failed_visa,
                                    year: year.name
                                  }
                                }));
                              }
                              }
                            >
                              {year.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              <Button
                color="primary"
                type="button"
                onClick={() => {
                  insertNewStudent();
                }}
                disabled={isInsertButtonDisabled}
              >
                Save
              </Button>
              <Button color="primary"
                type="button"
                id="downloadId"
                onClick={() => downloadQRCode(isData)}>
                <HiOutlineDownload/>  QR Code
              </Button>
            </Form>
            : null}
        </Card>
      </div >
    </>
  );
};

export default RegisteredStudent;
