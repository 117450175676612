import {
    Card,
    CardHeader,
    Row,
    Col,
} from "reactstrap";
import React from 'react';
import QRCode from 'react-qr-code';
import "../../assets/css/qr-card-styles.css";

const QRCard = ({ student }) => (
    <Card className="qRCard">
        <div className="emailId">
            <QRCode value={`https://apesense.ardillalabs.cloud/student/${student}`} size={90} className="code" />
        </div>
    </Card>
);

export default QRCard;
