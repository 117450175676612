import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import { FaExpeditedssl } from "react-icons/fa";
import { getAllAdmins } from "actions/Admins";
import { editAdminPassword } from "actions/Admins";
import { createStaff } from "actions/Admins";
import { getEmployeeList } from "actions/Auth";
import { editStaff } from "actions/Admins";
import { BiEdit } from "react-icons/bi";

const Admin = () => {
  const StaffType = {
    Admin: "admin",
    Accountant: "accountant",
    staff: "staff",
    sense: "sense",
    officeAssistant: "office assistant",
    authorizedPerson: "authorized person",
  };

  const Status = {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
    INACTIVE: "INACTIVE",
    PENDING: "PENDING",
    DEACTIVATED: "DEACTIVATED",
  };

  const [allAdmins, setAllAdmins] = useState(null);
  const [isAllAdmins, setIsAllAdmins] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [editPasswordModal, setEditPasswordmodal] = useState(false);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingAdmins, setLoadingAdmins] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [staffTypeDropdownOpen, setStaffTypeDropdownOpen] = useState(false);
  const [selectedType, setSelectedType] = useState({ type: "" });
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isEditError, setEditError] = useState({});
  const [staffTypeDropdownOpenEdit, setStaffTypeDropdownOpenEdit] =
    useState(false);

  const [initialAdmin, setInitialAdmin] = useState({
    id: null,
    full_name: "",
    email: "",
    phone_number: "",
    password: "",
    status: "",
  });
  const [isData, setIsData] = useState({
    full_name: "",
    email: "",
    mobile_phone_number: "",
    password: "",
    role_name: "",
  });

  const [isError, setIsError] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    password: "",
  });

  const [editPassword, setEditPassword] = useState({
    teacherId: null,
    newPassword: "",
    confirmPassword: "",
  });

  const [isEditPasswordError, setEditPasswordError] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [initialTeacher, setInitialTeacher] = useState({
    id: "",
  });

  const fetchData = async () => {
    try {
      const admins = await getEmployeeList();
      setAllAdmins(admins);
      setLoadingAdmins(false);
      if (admins.length > 0) {
        setIsAllAdmins(true);
      }
    } catch (error) {
      setLoadingAdmins(false);
      setIsAllAdmins(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (event) => {
    setIsError({ ...isError, [event.target.name]: "" });
    setIsData({ ...isData, [event.target.name]: event.target.value });
  };

  // Validate input
  const isValidadmin = (value) => {
    const errors = {};
    if (value.full_name.length < 6) {
      errors.full_name =
        "Teacher Full Name must be at least 6 characters long.";
    }
    if (value.email.length > 0 && !isEmail(value.email)) {
      errors.email = "Value must be a valid email.";
    }
    if (!isMobilePhone(value.mobile_phone_number)) {
      errors.phone_number = "Value must be a valid phone number.";
    }
    if (value.password.length > 0 && value.password.length < 5) {
      errors.password = "Password must be at least 5 characters long.";
    }
    if (Object.keys(errors).length > 0) {
      setIsError(errors);
      return false; // Exit early if there are errors
    } else {
      return true;
    }
  };

  const insertNewadmin = async () => {
    if (isValidadmin(isData)) {
      setinsertButtonDisabled(true);
      if (
        isData.role_name === "staff" ||
        isData.role_name === "authorized person"
      ) {
        const newData = {
          full_name: isData.full_name,
          mobile_phone_number: isData.mobile_phone_number,
          role_name: isData.role_name,
        };
        const response = await createStaff(newData);

        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          setIsData({
            ...isData,
            full_name: "",
            email: "",
            mobile_phone_number: "",
            password: "",
            role_name: "",
          });
          fetchData();
          setinsertButtonDisabled(false);
        } else {
          setErrorMessage(response.message);
          setinsertButtonDisabled(false);
          setFailed(true);
        }
      } else {
        const response = await createStaff(isData);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          setIsData({
            ...isData,
            full_name: "",
            email: "",
            mobile_phone_number: "",
            password: "",
            role_name: "",
          });
          fetchData();
          setinsertButtonDisabled(false);
        } else {
          setErrorMessage(response.message);
          setinsertButtonDisabled(false);
          setFailed(true);
        }
      }
    }
  };

  const [selectedmemberRole, setSelectedmemberRole] = useState("");

  const handleEditClick = (admin) => {
    setSelectedTeacher(admin);
    setSelectedmemberRole(admin.role.name);
    setInitialTeacher(admin);
    setSelectedAdmin(admin);
    setInitialAdmin(admin);
    setEditmodal(true);
  };

  const handleEditPasswordClick = (admin) => {
    setSelectedAdmin(admin);
    setEditPassword({
      ...editPassword,
      teacherId: admin.id,
    });
    setEditPasswordmodal(true);
  };

  // Validate input
  const isValidPassword = (value) => {
    const errors = {};
    if (value.newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters long.";
    }
    if (value.newPassword !== value.confirmPassword) {
      errors.confirmPassword = "Password and Confirm Password must be matched.";
    }
    if (Object.keys(errors).length > 0) {
      setEditPasswordError(errors);
      setDisabledEditButton(false);
      return false;
    } else {
      return true;
    }
  };
  const editTeacherPassword = async () => {
    if (isValidPassword(editPassword)) {
    }
  };

  const isValidEditTeacher = (value) => {
    const errors = {};
    if (value.full_name.length < 6) {
      errors.full_name =
        "Teacher Full Name must be at least 6 characters long.";
    }
    if (!isEmail(value.email)) {
      errors.email = "Value must be a valid email.";
    }
    if (!isMobilePhone(value.mobile_phone_number)) {
      errors.phone_number = "Value must be a valid phone number.";
    }
    if (Object.keys(errors).length > 0) {
      setEditError(errors);
      setDisabledEditButton(false);
      return false;
    } else {
      return true;
    }
  };

  function getChangedValues(selectedTeacher) {
    const changedValues = {};
    for (const key in selectedTeacher) {
      if (
        key !== "id" &&
        selectedTeacher.hasOwnProperty(key) &&
        selectedTeacher[key] !== initialTeacher[key]
      ) {
        changedValues[key] = selectedTeacher[key];
      }

      if (key === "id") {
        changedValues[key] = selectedTeacher[key];
      }
    }
    return changedValues;
  }

  const editTeacherDetails = async () => {
    const validityCheck = isValidEditTeacher(selectedTeacher);
    if (validityCheck === true) {
      setEditedInput(false);
      const data = getChangedValues(selectedTeacher);
      const body = JSON.stringify(data);
      const response = await editStaff(body);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        await fetchData();
        setEditmodal(false);
      } else {
        setFailed(true);
      }
    }
  };

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Row>
          <Col>
            <Card className="mb-4 shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Add New Staff Member</h3>
                  </Col>
                </Row>
              </CardHeader>
              {/* Insert Form */}
              <Form className="ml-4 mb-4 mr-4">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        id="fullName"
                        placeholder="Full Name"
                        type="text"
                        value={isData.full_name}
                        onChange={handleInputChange}
                        name="full_name"
                      />
                      {isError.full_name && (
                        <p className="text-danger">{isError.full_name}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="phone_number"
                        placeholder="Phone Number"
                        type="text"
                        value={isData.mobile_phone_number}
                        onChange={handleInputChange}
                        name="mobile_phone_number"
                      />
                      {isError.phone_number && (
                        <p className="text-danger">{isError.phone_number}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Dropdown
                        isOpen={staffTypeDropdownOpen}
                        toggle={() =>
                          setStaffTypeDropdownOpen(!staffTypeDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {isData.role_name !== ""
                            ? isData.role_name
                            : "Select a Type"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(StaffType).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setIsData({
                                  ...isData,
                                  role_name: value,
                                }),
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                    {isData.role_name === "admin" ||
                    isData.role_name === "sense" ||
                    isData.role_name === "accountant" ||
                    isData.role_name === "office assistant" ? (
                      <>
                        <FormGroup>
                          <Input
                            id="email"
                            placeholder="Email Address"
                            type="test"
                            value={isData.email}
                            onChange={handleInputChange}
                            name="email"
                          />
                          {isError.email && (
                            <p className="text-danger">{isError.email}</p>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Input
                            id="password"
                            placeholder="Password"
                            type="password"
                            value={isData.password}
                            onChange={handleInputChange}
                            name="password"
                          />
                          {isError.password && (
                            <p className="text-danger">{isError.password}</p>
                          )}
                        </FormGroup>
                      </>
                    ) : null}

                    <Button
                      color="primary"
                      type="button"
                      onClick={insertNewadmin}
                      disabled={
                        !isData.full_name ||
                        !isData.mobile_phone_number ||
                        isInsertButtonDisabled
                      }
                    >
                      Insert
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* Display Tabel */}
        {isLoadingAdmins ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isAllAdmins ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img
              src={require("../../assets/img/brand/nodata.png")}
              className="noDataImage"
            />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Admins</h3>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Full Name</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Role</th>
                  <th scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {allAdmins?.map((admin, index) => (
                  <tr key={index}>
                    <td>{admin.full_name}</td>
                    <td>{admin.mobile_phone_number}</td>
                    <td>{admin.role.name}</td>
                    <td>                        
                    <Button
                      color="primary"
                      type="button"
                      id="editTeacher"
                      onClick={() => handleEditClick(admin)}
                    >
                      <BiEdit />
                    </Button>
                    </td>
                    {/* {admin.role.name !== 'staff' && admin.role.name !== 'authorized person' ?
                    <Button
                      color="primary"
                      type="button"
                      id="editPassword"
                      onClick={() => handleEditPasswordClick(admin)}
                    >
                      <FaExpeditedssl />
                    </Button> : null } */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
      <Modal
        className="modal-dialog-centered"
        isOpen={editModal}
        toggle={() => [setEditmodal(false)]}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit Staff Member
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => [
              setEditmodal(false),
              setDisabledEditButton(true),
              setEditError({
                full_name: "",
                phone_number: "",
                password: "",
              }),
            ]}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    id="editFullName"
                    placeholder="Full Name"
                    type="text"
                    value={
                      selectedTeacher?.full_name
                        ? selectedTeacher?.full_name
                        : ""
                    }
                    name="full_name"
                    onChange={(e) => {
                      setSelectedTeacher({
                        ...selectedTeacher,
                        full_name: e.target.value,
                      });
                      setEditedInput(true);
                      setDisabledEditButton(false);
                      setEditError({ full_name: "" });
                    }}
                  />
                  {isEditError.full_name && (
                    <p className="text-danger">{isEditError.full_name}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    value={selectedTeacher?.email ? selectedTeacher.email : ""}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    id="editPhoneNumber"
                    placeholder="Phone Number"
                    type="text"
                    value={
                      selectedTeacher?.mobile_phone_number
                        ? selectedTeacher?.mobile_phone_number
                        : ""
                    }
                    name="phone_number" // Added name attribute
                    onChange={(e) => {
                      setSelectedTeacher({
                        ...selectedTeacher,
                        mobile_phone_number: e.target.value,
                      });
                      setEditedInput(true);
                      setDisabledEditButton(false);
                      setEditError({ phone_number: "" });
                    }}
                  />
                  {isEditError.phone_number && (
                    <p className="text-danger">{isEditError.phone_number}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    isOpen={staffTypeDropdownOpenEdit}
                    toggle={() =>
                      setStaffTypeDropdownOpenEdit(!staffTypeDropdownOpenEdit)
                    }
                  >
                    <DropdownToggle caret>
                      {selectedmemberRole !== ""
                        ? selectedmemberRole
                        : "Select a Type"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.entries(StaffType).map(([key, value]) => (
                        <DropdownItem
                          key={value}
                          value={value}
                          onClick={() => [
                            setSelectedTeacher({
                              ...selectedTeacher,
                              role_name: value,
                            }),
                            setSelectedmemberRole(value),
                            setEditedInput(true),
                            setDisabledEditButton(false),
                          ]}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    isOpen={statusDropdownOpen}
                    toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {selectedTeacher
                        ? selectedTeacher.status
                        : "Select a Status"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.entries(Status).map(([key, value]) => (
                        <DropdownItem
                          key={value}
                          value={value}
                          onClick={() => [
                            setSelectedTeacher({
                              ...selectedTeacher,
                              status: value,
                            }),
                            setEditedInput(true),
                            setDisabledEditButton(false),
                          ]}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>

                <Button
                  color="primary"
                  type="button"
                  onClick={() => editTeacherDetails()}
                  disabled={!isEditedInput || isDisabledEditButton}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={editPasswordModal}
        toggle={() => [setEditPasswordmodal(false)]}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit Password
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => [
              setEditPasswordmodal(false),
              setEditPasswordError({
                newPassword: "",
                confirmPassword: "",
              }),
            ]}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    id="editNewPassword"
                    placeholder="New Password"
                    type="password"
                    name="newPassword"
                    onChange={(e) => {
                      setEditPassword({
                        ...editPassword,
                        newPassword: e.target.value,
                      });
                      setEditedInput(true);
                      setDisabledEditButton(false);
                      setEditPasswordError({ newPassword: "" });
                    }}
                  />
                  {isEditPasswordError.newPassword && (
                    <p className="text-danger">
                      {isEditPasswordError.newPassword}
                    </p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="editConfirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    name="confirmPassword" // Added name attribute
                    onChange={(e) => {
                      setEditPassword({
                        ...editPassword,
                        confirmPassword: e.target.value,
                      });
                      setEditedInput(true);
                      setDisabledEditButton(false);
                      setEditPasswordError({ confirmPassword: "" });
                    }}
                  />
                  {isEditPasswordError.confirmPassword && (
                    <p className="text-danger">
                      {isEditPasswordError.confirmPassword}
                    </p>
                  )}
                </FormGroup>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => editTeacherPassword()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Admin;
