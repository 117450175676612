import { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { getUser, signInSubmit } from "../../actions/Auth/index.js";
import { useNavigate } from "react-router-dom";
import "../../assets/css/styles.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [isFailed, setFailed] = useState(false);

  const navigate = useNavigate();

  const getUserDetails = async () => {
    const user = await getUser();
  }

  const handleSignIn = async () => {
    try {
      const result = await signInSubmit({ email, password });
      if (result.success === true) {
        const user = await getUser();
        if(user.role.name === 'admin'){         
        navigate("/admin/index");
        }
        else if (user.role.name === 'sense'){
          navigate("/sense/academic");
        }
        else if(user.role.name === 'accountant') {
          navigate("/accountant/index");
        }
        else if(user.role.name === 'office assistant') {
          navigate("/assistant/index");
        }
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setFailed(true);
    }
  };

  return (
    <>
    <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {errorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pt-5 border-bottom-0 loginHeader">
            <span>
              <img
                src={require("../../assets/img/brand/Logo.png")}
                width={180}
                alt="Ape Sense IMS Logo"
              />
            </span>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={handleSignIn}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Login;
