import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import TeacherLayout from "layouts/Teacher.js";
import AccountantLayout from "layouts/Accountant";
import AssistantLayout from "layouts/Assistant";
import StudentLayout from "layouts/Student";

const root = ReactDOM.createRoot(document.getElementById("root"));

function ExternalRedirect() {
  useEffect(() => {
    window.location.href = 'https://apesense.lk/';
  }, []);
  
  return null;
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/sense/*" element={<TeacherLayout />} />
      <Route path="/accountant/*" element={<AccountantLayout />} />
      <Route path="/assistant/*" element={<AssistantLayout />} />
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="*" element={<AuthLayout/>} />
      <Route path="/student/:registration_number" element={<StudentLayout />} />
      <Route path="/student" element={<StudentLayout />} />
    </Routes>
  </BrowserRouter>
);
