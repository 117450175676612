import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
    Label,
    Collapse,
} from "reactstrap";
import { useState, useEffect, useRef, useMemo } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import React from "react";
import { getRegisteredStudent } from "actions/Students";
import { getUser } from "actions/Auth";
import ReactDOM from 'react-dom';
import { PDFViewer, usePDF } from '@react-pdf/renderer';
import Invoice from "components/PDFDocuments/invoice";
import { getPaymentsByStudentId } from "actions/Payments";
import { createPayment } from "actions/Payments";
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer'
import { HiOutlineDownload, HiOutlinePrinter } from 'react-icons/hi'

const NewPayment = () => {
    const [data, setData] = useState({});
    // const [instance, updateInstance] = usePDF({ document: <Invoice data={data} /> });

    const nicInputRef = useRef(null);
    const registrationNumberInputRef = useRef(null);
    const batchNumberInputRef = useRef(null);
    const [nicInput, setNicInput] = useState('');
    const [registrationNumberInput, setRegistrationNumberInput] = useState('');
    const [batchNoInput, setBatchNoInput] = useState('');
    const [isReceivedData, setReceivedData] = useState(false);
    const [isStudentList, setStudentList] = useState(false);
    const [isData, setIsData] = useState({});
    const [allSubjects, setAllSubjects] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredStudents, setFilteredStudents] = useState(null);
    const [isPayData, setPayData] = useState({
        course_fee: '',
        amount: '',
        payment_method: ''
    });
    const [isError, setIsError] = useState({});
    const [selectedType, setSelectedType] = useState('');
    const [user, setUser] = useState(null);
    const [isAllTransactions, setIsAllTransactions] = useState(false);
    const [allTransactions, setAllTransactiona] = useState(null);
    const [balance, setBalance] = useState(null);
    const [classFeesBalance, setClassFeesBalance] = useState(null);
    const [disableCreate, setDisableCreate] = useState(false);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;

    const fetchData = async () => {
        try {
            const response = await getUser();
            setUser(response);
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getStudentSearch = async (e) => {
        if (e.target.name === 'nic') {
            setNicInput(e.target.value);
        }
        if (e.target.name === 'registrationNumberInput') {
            setRegistrationNumberInput(e.target.value);
        }
        if (e.target.name === 'batchNo') {
            setBatchNoInput(e.target.value);
        }
    };

    const [totalPay, setTotalPayAmount] = useState(null);
    const [downloadbtn, setDownloadbtn] = useState(false);

    const calculateBalance = async (data) => {
        const payResponse = await getPaymentsByStudentId(data);
        if (payResponse.data.length > 0) {
            const lastItem = payResponse.data[payResponse.data.length - 1];
            setAllTransactiona(payResponse.data);
            setIsAllTransactions(true);
            const totalAmount = payResponse.data.reduce((accumulator, current) => {
                return accumulator + (current.amount || 0);
            }, 0);
            setTotalPayAmount(totalAmount);
            const balance = isData.course_fee - totalAmount;
            setClassFeesBalance(totalAmount);
            setBalance(balance);
        } else {
            setIsAllTransactions(false);
        }
    }

    const calculateBalance1 = async (data) => {
        const payResponse = await getPaymentsByStudentId(data);
        if (payResponse.data.length > 0) {
            const body = { 'nic': isData.nic };
            const response = await getRegisteredStudent(body);
            setIsData(response[0]);
            const lastItem = payResponse.data[payResponse.data.length - 1];
            setAllTransactiona(payResponse.data);
            setIsAllTransactions(true);
            const totalAmount = payResponse.data.reduce((accumulator, current) => {
                return accumulator + (current.amount || 0);
            }, 0);
            setTotalPayAmount(totalAmount);
            const balance = response[0].course_fee - totalAmount;
            setClassFeesBalance(totalAmount);
            setBalance(balance);
            const dataSet = ({
                description: payResponse.data.length,
                amount: (lastItem.amount).toFixed(2),
                invoiceNumber: lastItem.invoice_number,
                paymentDate: lastItem.date,
                method: lastItem.payment_method,
                totalPayment: (totalAmount).toFixed(2),
                balance: (response[0].course_fee - (totalAmount + response[0].discount)).toFixed(2),
                courseFee: (response[0].course_fee).toFixed(2),
                discount: (response[0].discount).toFixed(2),
                name: isData.full_name,
                regNumber: isData.student_number,
                phoneNumber: isData.mobile_phone_number,
            });
            try {
                setData(dataSet);
                setDownloadbtn(true);
            } catch (error) {
            }
        } else {
            setIsAllTransactions(false);
        }
    }

    const getStudent = async (type, searchValue) => {
        setTotalPayAmount(null);
        setAllSubjects(null);
        setFilteredStudents(null)
        setStudentList(false);
        setReceivedData(false);
        if (type === 'nic' || type === 'student_number') {
            setStudentList(false);
        }
        const body = { [type]: searchValue };
        try {
            const response = await getRegisteredStudent(body);
            if (response.length > 0 && response.length < 2) {
                setIsData(response[0]);
                setPayData({ ...isPayData, course_fee: response[0].course_fee, discount: response[0].discount });
                await calculateBalance(response[0].id);
                setReceivedData(true);
            }
            if (response.length > 1) {
                setStudentList(true);
                setAllSubjects(response);
                setReceivedData(false);
            }
            if (response.success === false) {
            }
        } catch (error) {
            setIsData({});
            setReceivedData(false);
        }
    };

    const searchSubject = async (searchQuery) => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredStudents(allSubjects.filter(student =>
            student.full_name.toLowerCase().includes(lowerCaseQuery)
        ));
    };

    const viewStudentFromList = async (response) => {
        setDownloadbtn(false);
        getStudent('nic', response.nic);
        setStudentList(false);
        setFilteredStudents(null);
    }

    const handleTypeChange = (type) => {
        setSelectedType(type)
    }

    const isValidStudent = (value) => {
        const errors = {};
        if (isPayData.course_fee === '') {
            errors.course_fee =
                "Course Fee is required.";
        }
        if (isPayData.amount === '') {
            errors.amount =
                "Amount is required.";
        }
        if (isPayData.payment_method === '') {
            errors.payment_method =
                "Payment Method is required.";
        }
        if (Object.keys(errors).length > 0) {
            setIsError(errors);
            return false;
        } else {
            return true;
        }
    };

    const addNewPayment = async () => {
        try {
            setDownloadbtn(false);
            isPayData.staff_id = +(user.id);
            isPayData.date = newDate;
            isPayData.user_id = +(isData.id);
            if (isValidStudent(isPayData)) {
                setDisableCreate(true);
                const response = await createPayment(isPayData);
                if (response.data.success === true) {
                    await calculateBalance1(isData.id);
                    setDisableCreate(false);
                    setPayData({ amount: '' });
                    setSelectedType('');
                }
            }
        } catch (error) {
            setDisableCreate(false);
        }
    }

    const invoiceDocument = useMemo(() => <Invoice data={data} />, [data]);

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Card className="mb-4 pt-4 shadow">
                    <div className="ml-4">
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Input
                                        id="nic"
                                        placeholder="NIC"
                                        type="text"
                                        value={nicInput}
                                        onChange={getStudentSearch}
                                        name="nic"
                                        ref={nicInputRef}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Button color="primary"
                                    type="button"
                                    onClick={() => {
                                        getStudent('nic', nicInput);
                                    }}>Search</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Input
                                        id="registrationNumberInput"
                                        placeholder="Registration Number"
                                        type="text"
                                        value={registrationNumberInput}
                                        onChange={getStudentSearch}
                                        name="registrationNumberInput"
                                        ref={registrationNumberInputRef}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Button color="primary"
                                    type="button"
                                    onClick={() => {
                                        getStudent('student_number', registrationNumberInput);
                                    }}>Search</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Input
                                        id="batchNo"
                                        placeholder="Batch Number"
                                        type="text"
                                        value={batchNoInput}
                                        onChange={getStudentSearch}
                                        name="batchNo"
                                        ref={batchNumberInputRef}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Button color="primary"
                                    type="button"
                                    onClick={() => {
                                        getStudent('batch_number', batchNoInput);
                                    }}>Search</Button>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <Card className="mb-4">
                    {isStudentList ?
                        <div className="m-4">
                            <Row>
                                <Col>
                                </Col>
                                <Col className="justify-content-end mb-4" md="4">
                                    <Input
                                        type="text"
                                        placeholder="Search by name"
                                        value={searchQuery}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                            searchSubject(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Registration Number</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">NIC</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStudents !== null ?
                                        filteredStudents?.map((subject, index) => (
                                            <tr key={index}>
                                                <td>{subject.student_number}</td>
                                                <td>{subject.full_name}</td>
                                                <td>{subject.nic}</td>
                                                <td>{subject.mobile_phone_number}</td>
                                                <td>
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        id="editSubject"
                                                        onClick={() => viewStudentFromList(subject)}
                                                    >
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                        )) :
                                        allSubjects?.map((subject, index) => (
                                            <tr key={index}>
                                                <td>{subject.student_number}</td>
                                                <td>{subject.full_name}</td>
                                                <td>{subject.nic}</td>
                                                <td>{subject.mobile_phone_number}</td>
                                                <td>
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        id="editSubject"
                                                        onClick={() => viewStudentFromList(subject)}
                                                    >
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                        : null}
                    {isReceivedData ?
                        <>
                            <Form className="ml-4 mb-4 mr-4 pt-4">
                                <Row className="nameWithImage">
                                    <Col md="12" lg="8">
                                        <FormGroup>
                                            <Label>Registration Number</Label>
                                            <Input
                                                id="student_number"
                                                type="text"
                                                value={isData.student_number}
                                                name="student_number"
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Full Name</Label>
                                            <Input
                                                id="full_name"
                                                type="text"
                                                value={isData.full_name}
                                                name="full_name"
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Phone Number</Label>
                                            <Input
                                                id="mobile_phone_number"
                                                type="text"
                                                value={isData.mobile_phone_number}
                                                name="mobile_phone_number"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="mb-4 studentSectionTitles" style={{ cursor: "pointer" }}>
                                    Payment Details
                                </div>
                                <Row>
                                    <Col md="12" lg="8">
                                        <FormGroup>
                                            <Label>Course Fee</Label>
                                            <Input
                                                id="courseFee"
                                                type="text"
                                                value={isPayData.course_fee}
                                                name="course_fee"
                                                pattern="\d*"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        setPayData({ ...isPayData, course_fee: Number(value) });
                                                        setIsError({ ...isError, course_fee: '' });
                                                    }
                                                }}
                                            />
                                            {isError.course_fee && (
                                                <p className="text-danger">{isError.course_fee}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" lg="8">
                                        <FormGroup>
                                            <Label>Discount</Label>
                                            <Input
                                                id="discount"
                                                type="text"
                                                value={isPayData.discount}
                                                name="discount"
                                                pattern="\d*"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        setPayData({ ...isPayData, discount: Number(value) });
                                                    }
                                                }}
                                            />
                                            {isError.courseFee && (
                                                <p className="text-danger">{isError.courseFee}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" lg="8">
                                        <FormGroup>
                                            <Label>Paid Amount</Label>
                                            <Input
                                                id="amount"
                                                type="text"
                                                value={isPayData.amount}
                                                name="amount"
                                                pattern="\d*"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        setPayData({ ...isPayData, amount: Number(value) });
                                                        setIsError({ ...isError, amount: '' });
                                                    }
                                                }}
                                            />
                                            {isError.amount && (
                                                <p className="text-danger">{isError.amount}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                            <Label>Payment Method</Label>
                                            <div className="radioOptions">
                                                <FormGroup check>
                                                    <Input
                                                        id="cashRadio"
                                                        name="typeRadio"
                                                        type="radio"
                                                        checked={selectedType === 'CASH'}
                                                        onChange={() => {
                                                            handleTypeChange('CASH');
                                                            setPayData((prevData) => ({ ...prevData, payment_method: 'CASH' }));
                                                            setIsError({ ...isError, payment_method: '' });
                                                        }}
                                                        style={{ transform: 'scale(1.5)' }}
                                                    />
                                                    {' '}
                                                    <Label check for="cashRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                                                        Cash
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        id="bocRadio"
                                                        name="typeRadio"
                                                        type="radio"
                                                        checked={selectedType === 'BOC'}
                                                        onChange={() => {
                                                            handleTypeChange('BOC');
                                                            setPayData((prevData) => ({ ...prevData, payment_method: 'BOC' }));
                                                            setIsError({ ...isError, payment_method: '' });

                                                        }}
                                                        style={{ transform: 'scale(1.5)' }}
                                                    />
                                                    {' '}
                                                    <Label check for="bocRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }} >
                                                        BOC
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        id="sampathRadio"
                                                        name="typeRadio"
                                                        type="radio"
                                                        checked={selectedType === 'SAMPATH'}
                                                        onChange={() => {
                                                            handleTypeChange('SAMPATH');
                                                            setPayData((prevData) => ({ ...prevData, payment_method: 'SAMPATH' }));
                                                            setIsError({ ...isError, payment_method: '' });
                                                        }}
                                                        style={{ transform: 'scale(1.5)' }}
                                                    />
                                                    {' '}
                                                    <Label check for="sampathRadio" className="ml-2" style={{ fontSize: "18px" }} >
                                                        Sampath
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                            {isError.payment_method && (
                                                <p className="text-danger">{isError.payment_method}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                addNewPayment();
                                            }}
                                            disabled={disableCreate}
                                        >
                                            Create Payment
                                        </Button>
                                        {downloadbtn ?
                                            <div style={{ marginTop: "10px", display: 'flex', gap: '10px' }}>
                                                <BlobProvider document={invoiceDocument}>
                                                    {({ loading, url, blob }) => (
                                                        loading ? null :
                                                            <a href={url} target="_blank">
                                                                <HiOutlinePrinter size={16} />
                                                                <span>Print</span>
                                                            </a>
                                                    )}
                                                </BlobProvider>
                                                <PDFDownloadLink document={invoiceDocument} fileName='invoice.pdf' style={{ color: "white" }}>
                                                    {({ blob, url, loading, error }) =>
                                                        loading ? null :
                                                            <div style={{ color: '#AD1D21 ' }}>
                                                                <HiOutlineDownload size={16} />
                                                                <span>Download</span>
                                                            </div>
                                                    }
                                                </PDFDownloadLink>
                                            </div>
                                            : null}
                                    </Col>
                                </Row>
                            </Form>
                            <div className="m-4">
                                <Table className="align-items-center" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th colSpan="14" style={{ textAlign: 'center', color: "black", fontSize: "16px" }}> PAYMENT RECORDS
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Payment Method</th>
                                            <th scope="col" style={{ textAlign: "center" }}>Student Name</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Amount (Rs.)</th>
                                        </tr>
                                    </thead>
                                    {!isAllTransactions ? (
                                        <tbody>
                                            <tr style={{ textAlign: "center" }}>
                                                <td colSpan="12" style={{ textAlign: "center" }}>
                                                    <img src={""} className="noDataImage" alt="No Data Available" style={{ margin: "auto" }} />
                                                </td>
                                            </tr>
                                        </tbody>) :
                                        <tbody>
                                            {allTransactions?.map((transaction, index) => (
                                                <tr key={index} className={transaction.amount < 0 ? 'creditTransaction' : 'debitTransaction'}>
                                                    <td>{(transaction.date)}</td>
                                                    <td>{transaction.payment_method}</td>
                                                    <td style={{ textAlign: "center" }}>{isData.full_name}</td>
                                                    <td style={{ textAlign: "right" }}>{Math.abs(transaction.amount)}.00</td>
                                                </tr>
                                            ))}
                                        </tbody>}
                                    {classFeesBalance !== null ?
                                        <tbody>
                                            <tr className="debitTransaction classFeesBalanceRow">
                                                <td colSpan="3" style={{ textAlign: "right", fontSize: "16px" }}>Total Paid Amount</td>
                                                <td style={{ textAlign: "right", fontSize: "16px" }}>{(totalPay)?.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                        : null}
                                    {isData.course_fee !== null ?
                                        <tbody>
                                            <tr className="debitTransaction classFeesBalanceRow">
                                                <td colSpan="3" style={{ textAlign: "right", fontSize: "16px" }}>Course Fee</td>
                                                <td style={{ textAlign: "right", fontSize: "16px" }}>{(isData.course_fee).toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                        : null}
                                    {isData.discount !== null ?
                                        <tbody>
                                            <tr className="debitTransaction classFeesBalanceRow">
                                                <td colSpan="3" style={{ textAlign: "right", fontSize: "16px" }}>Discount</td>
                                                <td style={{ textAlign: "right", fontSize: "16px" }}>{(isData.discount).toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                        : null}
                                    {balance !== null ?
                                        <tbody>
                                            <tr>
                                                <th colSpan="3" style={{ textAlign: "right", fontSize: "16px" }}>Due Amount</th>
                                                <th style={{ textAlign: "right", fontSize: "16px" }}>{(isData.course_fee - (totalPay + isData.discount)).toFixed(2)}</th>
                                            </tr>
                                        </tbody>
                                        : null}
                                </Table>
                            </div>
                        </>
                        : null
                    }
                </Card>
            </div >
        </>
    )
}

export default NewPayment;