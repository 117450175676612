import { getVoucherByNumber } from "actions/Payments";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Card,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
    Label,
    Collapse,
} from "reactstrap";

const VoucherVeiw = () => {

    const { voucher_number } = useParams();
    const [billDetails, setBillDetails] = useState([]);
    const [loadingVoucher, setLoadingVoucher] = useState(false);
    const [voucherDetails, setIsVoucherDetails] = useState(false);

    const fetchData = async () => {
        try {
            const voucher = await getVoucherByNumber(voucher_number);
            setBillDetails(voucher.data.data[0]);
        } catch (error) {
            setLoadingVoucher(false);
            setIsVoucherDetails(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Card className="mb-4 pt-4 shadow">
                    <div className="ml-4">
                        <Form>
                            <Row>
                                <Col sm="10" md="10">
                                    <FormGroup>
                                        <Label>Voucher Number</Label>
                                        <Input
                                            id="exampleFormControlInput1"
                                            placeholder="Subject Name"
                                            type="text"
                                            value={billDetails?.voucher_number}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Staff Member</Label>
                                        <Input
                                            id="exampleFormControlInput1"
                                            placeholder="Subject Name"
                                            type="text"
                                            value={billDetails?.staff?.full_name}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Date</Label>
                                        <Input
                                            id="exampleFormControlInput1"
                                            placeholder="Subject Name"
                                            type="text"
                                            value={billDetails?.date}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Amount</Label>
                                        <Input
                                            id="exampleFormControlInput1"
                                            placeholder="Subject Name"
                                            type="text"
                                            value={(billDetails.amount)?.toFixed(2)}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                        <Label>Bill</Label>
                                        <div className="radioOptions">
                                            <FormGroup check>
                                                <Input
                                                    id="cashRadio"
                                                    name="typeRadio"
                                                    type="radio"
                                                    checked={billDetails?.bill === 'OK'}
                                                    style={{ transform: 'scale(1.5)' }}
                                                />
                                                {' '}
                                                <Label check for="cashRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                                                    OK
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input
                                                    id="bocRadio"
                                                    name="typeRadio"
                                                    type="radio"
                                                    checked={billDetails?.bill === 'PENDING'}
                                                    style={{ transform: 'scale(1.5)' }}
                                                />
                                                {' '}
                                                <Label check for="bocRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }} >
                                                    Pending
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Uploaded Document List</Label>
                                        <div>
                                            {billDetails?.bills?.length !== 0 &&
                                                billDetails?.bills?.map((image, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            display: "flex",
                                                            position: "relative",
                                                            flexDirection: "column"
                                                        }}
                                                    >
                                                        {/* <img
                                                            src={image.doc_url ? image.doc_url : URL.createObjectURL(image)}
                                                            alt={image.doc_url}
                                                            style={{
                                                                width: "400px",
                                                                height: "auto",
                                                                marginRight: "5px",
                                                            }}
                                                        /> */}
                                                        <a
                                                            href={image.doc_url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{ color: '#AD1D21', textDecoration: 'underline' }}
                                                        >
                                                            {image.doc_url}
                                                        </a>
                                                        {/* <object width="100%" height="400" data={image.doc_url}></object> */}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default VoucherVeiw;