import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
  UncontrolledTooltip,
  Label,
  Collapse,
  ButtonGroup,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { createStudent } from "actions/Students";
import defaultprofile from "../../assets/img/brand/blank_profile_pic.png";
import "../../assets/css/styles.css";
import { getOLActiveSubjects } from "actions/Subjects";
import { getAllActiveSubjects } from "actions/Subjects";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdRefresh } from "react-icons/io";
import { GoPlusCircle } from "react-icons/go";
import { Link } from "react-router-dom";
import { getEduQualifications } from "actions/Subjects";
import { getUser } from "actions/Auth";
import { getYear, getMonth } from 'date-fns';

const Students = () => {
  const Grades = {
    A: "A",
    B: "B",
    C: "C",
    S: "S",
    F: "F",
  };

  const Sex = {
    Male: "Male",
    Female: "Female",
  };

  const Status = {
    Single: "Single",
    Married: "Married",
  };

  const VisaTypes = {
    1: "Student Visa",
    2: "Working Visa",
    3: "SSW Visa",
  };

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [alSubjects, setAlSubjects] = useState(null);
  const [olSubjects, setOlSubjects] = useState(null);
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
  const [OlsubjectDropdownOpen, setOlSubjectDropdownOpen] = useState(false);
  const [selectedOlSubject, setSelectedOlSubject] = useState(null);
  const [selectedOlGrade, setSelectedOLGrade] = useState(null);
  const [gradeOlDropdownOpen, setGradeOlDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [birthDate, setBirthDate] = useState(new Date());
  const [sexDropdownOpen, setSexDropdownOpen] = useState(false);
  const [selectedSex, setSelectedSex] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [eduQualifications, setEduQualifications] = useState(null);
  const [olYearDropdownOpen, setOlYearDropdownOpen] = useState(false);
  const [alYearDropdownOpen, setAlYearDropdownOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [visaDropdownOpen, setVisaDropdownOpen] = useState(false);
  const [selectedVisa, setSelectedVisa] = useState(null);
  const [selectedVisaType, setSelectedVisaType] = useState(null);
  const [basicRegister, setBasicRegister] = useState(true);
  const [moreRegister, setMoreRegister] = useState(true);
  const [regDisabled, setRegDisabled] = useState(false);
  const [moreDisabled, setMoreDisabled] = useState(false);

  const [initialStudent, setInitialStudent] = useState({
    ol_results: [],
    al_results: [],
    failed_visa: [],
  });

  const [isData, setIsData] = useState({
    full_name: '',
    nic: '',
    sex: '',
    course_type: '',
    dob: '',
    mobile_phone_number: '',
    whatsapp_number: ''
  });

  const [isError, setIsError] = useState({
    full_name: "",
    email: "",
    phone_number: "",
  });

  const [isEditError, setEditError] = useState({
    full_name: "",
    phone_number: "",
  });

  const getSubjects = async () => {
    try {
      const alSubjects = await getAllActiveSubjects();
      setAlSubjects(alSubjects);
      const olSubjects = await getOLActiveSubjects();
      setOlSubjects(olSubjects);
    } catch (error) {
    }
  }

  const getEducationQualifications = async () => {
    try {
      const response = await getEduQualifications();
      setEduQualifications(response);
    } catch (error) {

    }
  }

  const fetchData = async () => {
    try {
      const response = await getUser();
      setUser(response);
      await getSubjects();
      await getEducationQualifications();
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [selectedClasses, setSelectedClasses] = useState([]);

  const removeSelectedClass = (indexToRemove) => {
    setSelectedClasses(prevSelectedClasses => {
      return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
    });
  };

  const addClass = async () => {
    const newEnrolledClass = {
      subjectId: selectedSubject.id,
      grade: selectedGrade,
    };

    setInitialStudent((prevState) => {
      const isAlreadyEnrolled = prevState.al_results.some(
        (enrolledClass) => enrolledClass.subjectId === newEnrolledClass.subjectId
      );

      if (isAlreadyEnrolled) {
        setFailed(true);
        setErrorMessage('This result is already inserted.');
        return prevState;
      }

      const updatedEnrolledClasses = [
        ...prevState.al_results,
        newEnrolledClass,
      ];

      setIsData((prevData) => ({
        ...prevData,
        a_level_result: updatedEnrolledClasses,
      }));

      return {
        ...prevState,
        al_results: updatedEnrolledClasses,
      };
    });

    setSelectedClasses((prevSelectedClasses) => {
      const isAlreadySelected = prevSelectedClasses.some(
        (selectedClass) => selectedClass.subject === selectedSubject.name
      );
      if (!isAlreadySelected) {
        return [
          ...prevSelectedClasses,
          { grade: selectedGrade, subject: selectedSubject.name },
        ];
      }
      return prevSelectedClasses;
    });
  };

  const removeEnrolledClass = (enrolledClassIdToRemove) => {
    setInitialStudent((prevState) => {
      const updatedEnrolledClasses = prevState.al_results.filter(
        (_, index) => index !== enrolledClassIdToRemove
      );

      setIsData((prevData) => ({
        ...prevData,
        a_level_result: updatedEnrolledClasses,
      }));

      return {
        ...prevState,
        al_results: updatedEnrolledClasses,
      };
    });

    removeSelectedClass(enrolledClassIdToRemove);
  };

  const [selectedOLClasses, setSelectedOLClasses] = useState([]);

  const removeSelectedOLClass = (indexToRemove) => {
    setSelectedOLClasses(prevSelectedClasses => {
      return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
    });
  };

  const addOLClass = async () => {
    const newEnrolledClass = {
      subjectId: selectedOlSubject.id,
      grade: selectedOlGrade,
    };

    setInitialStudent((prevState) => {
      const isAlreadyEnrolled = prevState.ol_results.some(
        (ol_result) => ol_result.subjectId === newEnrolledClass.subjectId
      );

      if (isAlreadyEnrolled) {
        setFailed(true);
        setErrorMessage('This result is already inserted.');
        return prevState;
      }

      const updatedEnrolledClasses = [...prevState.ol_results, newEnrolledClass];

      setIsData((prevData) => ({
        ...prevData,
        o_level_result: updatedEnrolledClasses,
      }));

      return {
        ...prevState,
        ol_results: updatedEnrolledClasses,
      };
    });

    setSelectedOLClasses((prevSelectedClasses) => {
      const isAlreadySelected = prevSelectedClasses.some(
        (selectedOLClass) => selectedOLClass.subject === selectedOlSubject.name
      );
      if (!isAlreadySelected) {
        return [
          ...prevSelectedClasses,
          { grade: selectedOlGrade, subject: selectedOlSubject.name },
        ];
      }
      return prevSelectedClasses;
    });
  };

  const removeEnrolledOlClass = (enrolledClassIdToRemove) => {
    setInitialStudent((prevState) => {
      const updatedEnrolledClasses = prevState.ol_results.filter(
        (_, index) => index !== enrolledClassIdToRemove
      );

      setIsData((prevData) => ({
        ...prevData,
        o_level_result: updatedEnrolledClasses,
      }));

      return {
        ...prevState,
        ol_results: updatedEnrolledClasses,
      };
    });
    removeSelectedOLClass(enrolledClassIdToRemove);
  };

  useEffect(() => {
  }, [isData]);

  const handleInputChange = (event) => {
    setIsError({ ...isError, [event.target.name]: "" });
    setIsData({ ...isData, [event.target.name]: event.target.value });
  };

  const isValidStudent = (value) => {
    const errors = {};
    if (value.full_name === '') {
      errors.full_name =
        "Full Name is required.";
    }
    if (value.nic === '') {
      errors.nic =
        "NIC is required.";
    }
    if (value.sex === '') {
      errors.sex =
        "Sex is required.";
    }
    if (value.course_type === '') {
      errors.course_type =
        "Course Type is required.";
    }
    if (value.dob === '') {
      errors.dob =
        "Date of Birth is required.";
    }
    if (value.whatsapp_number === '') {
      errors.whatsapp_number =
        "WhatsApp Number is required.";
    }
    if ((value.whatsapp_number.length !== 10)) {
      errors.whatsapp_number = "Value must be a valid phone number.";
    }
    if (value.mobile_phone_number === '') {
      errors.mobile_phone_number =
        "Phone Number is required.";
    }
    if ((value.mobile_phone_number.length !== 10)) {
      errors.mobile_phone_number = "Value must be a valid phone number.";
    }
    if (value.course_type === '') {
      errors.course_type =
        "Course Type is required.";
    }
    if (Object.keys(errors).length > 0) {
      setIsError(errors);
      return false;
    } else {
      return true;
    }
  };

  const [regNumber, setRegNumber] = useState(null);

  const insertNewStudent = async () => {
    if (isValidStudent(isData)) {
      setinsertButtonDisabled(true);
      setMoreDisabled(true);
      if (selectedVisa !== null) {
        isData.failed_visa = [{
          visa_type_id: +selectedVisa,
          year: selectedStartyear.name
        }];
      }
      const response = await createStudent(isData);
      if (response.status === 201) {
        setRegNumber(response.data.user.student_number);
        setIsData({
          full_name: '',
          nic: '',
          sex: '',
          course_type: '',
          dob: '',
          mobile_phone_number: '',
          whatsapp_number: ''
        });
        setSelectedGrade(null);
        setSelectedSubject(null);
        setSelectedClasses([]);
        fetchData();
        setinsertButtonDisabled(false);
        setMoreDisabled(false);
      } else {
        setErrorMessage(response.message);
        setinsertButtonDisabled(false);
        setFailed(true);
        setBasicRegister(false);
        setMoreRegister(false);
        setMoreDisabled(false);
      }
    }
  };

  const [previewImage, setPreviewImage] = useState(defaultprofile);
  const inputImgRef = useRef(null);
  const [isPersonalBioOpen, setPersonalBioOpen] = useState(true);
  const [isEduOpen, setEduOpen] = useState(false);
  const [isProfOpen, setProfOpen] = useState(false);
  const [isOtherOpen, setOtherOpen] = useState(false);
  const [isMoreOpen, setMoreOpen] = useState(false);

  const handleThumbnailImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [qualifications, setQualifications] = useState([{ description: "" }]);
  const [errors, setErrors] = useState([]);

  const handleQualificationInputChange = (index, event) => {
    const values = [...qualifications];
    values[index][event.target.name] = event.target.value;
    setQualifications(values);
    setIsData({ ...isData, qualifications: values });
  };

  const addQualification = () => {
    setQualifications([...qualifications, { description: "" }]);
  };

  const [otherQualifications, setOtherQualifications] = useState([{ description: "" }]);
  const [otherErrors, setOtherErrors] = useState([]);

  const handleOtherQualificationInputChange = (index, event) => {
    const values = [...otherQualifications];
    values[index][event.target.name] = event.target.value;
    setOtherQualifications(values);
    setIsData({ ...isData, other_qualifications: values });
  };

  const addOtherQualification = () => {
    setOtherQualifications([...otherQualifications, { description: "" }]);
  };

  const [exams, setExams] = useState([{ exam: "" }]);
  const [examErrors, setExamErrors] = useState([]);

  const handleJapanseExamInputChange = (index, event) => {
    const values = [...exams];
    values[index][event.target.name] = event.target.value;
    setExams(values);
    setIsData({ ...isData, completed_exams: values });
  };

  const addExams = () => {
    setExams([...exams, { exam: "" }]);
  };

  const [selectedGuardian, setSelectedGuardian] = useState('');
  const [selectedBusinessOption, setSelectedBusinessOption] = useState('');
  const [selectedFamilyBusinessOption, setSelectedFamilyBusinessOption] = useState('');
  const [selectedSponsor, setSelectedSponsor] = useState('');
  const [selectedExams, setSelectedExams] = useState('');
  const [beforeTry, setBeforeTry] = useState('');
  const [loadingOLSubjects, setLoadingOLSubjects] = useState(false);
  const [loadingAlSubjects, setLoadingALSubjects] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  const handleRadioChange = (guardian) => {
    setSelectedGuardian(guardian)
    setIsData({ ...isData, guardian: guardian });
  };

  const handleTypeChange = (type) => {
    setSelectedType(type)
  };

  const filterAttendanceByDate = async (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setStartDate(newDate)
    setIsData((prevData) => ({ ...prevData, guardian_dob: newDate }))
  }

  const [yearListDropdownOpen, setYearsDropdownOpen] = useState(false);
  const [selectedStartyear, setSelectedStartYear] = useState(null);

  const yearList = Array.from({ length: 45 }, (v, i) => {
    return { name: i + 1980 };
  });

  const setDateOfBirth = async (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setBirthDate(newDate);
    setIsData((prevData) => ({ ...prevData, dob: newDate }))
  }

  const getSubjectsOL = async () => {
    setLoadingOLSubjects(true);
    const response = await getOLActiveSubjects();
    setOlSubjects(response);
    setLoadingOLSubjects(false);
  };

  const getSubjectsAL = async () => {
    setLoadingALSubjects(true);
    const response = await getAllActiveSubjects();
    setAlSubjects(response);
    setLoadingALSubjects(false);
  };
  const [checkboxStates, setCheckboxStates] = useState({});

  useEffect(() => {
    if (eduQualifications && eduQualifications.length > 0) {
      const initialState = eduQualifications.reduce(
        (acc, curr) => ({ ...acc, [curr.name]: false }),
        {}
      );
      setCheckboxStates(initialState);
    }
  }, [eduQualifications]);

  const [selectedEduQualifications, setSelectedEduQualifications] = useState({ edu_qualifications: [] })

  const handleCheckboxChange = (value) => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [value.name]: !prevStates[value.name]
    }));

    setSelectedEduQualifications((prevStates) => {
      const isAlreadySelected = prevStates.edu_qualifications.some(
        (qualification) => qualification.id === value.id
      );

      let updatedQualifications;

      if (isAlreadySelected) {
        updatedQualifications = prevStates.edu_qualifications.filter(
          (qualification) => qualification.id !== value.id
        );
      } else {
        updatedQualifications = [
          ...prevStates.edu_qualifications,
          { id: value.id }
        ];
        setIsData((prevData) => ({
          ...prevData,
          edu_qualifications: updatedQualifications,
        }));
      }

      return {
        ...prevStates,
        edu_qualifications: updatedQualifications
      };
    });
  };

  const [selectedOlyear, setSelectedOlyear] = useState(null);

  const handleOlYear = (year) => {
    setSelectedOlyear(year);
    setIsError({ ...isError, o_level_exam_year: "" });
    setIsData({ ...isData, o_level_exam_year: year.name });
    setOlYearDropdownOpen(false);
  };

  const [selectedAlyear, setSelectedAlyear] = useState(null);

  const handleAlYear = (year) => {
    setSelectedAlyear(year);
    setIsError({ ...isError, a_level_exam_year: "" });
    setIsData({ ...isData, a_level_exam_year: year.name });
    setAlYearDropdownOpen(false);
  };

  const range = (start, end, step) => {
    let output = [];
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  };

  const years = range(1950, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const setVisaDetails = (details) => {
  }
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 pt-4 shadow">
          <Form className="ml-4 mb-4 mr-4">
            <div className="mb-4 studentSectionTitles" onClick={() => setPersonalBioOpen(!isPersonalBioOpen)} style={{ cursor: "pointer" }}>
              Personal Details  {!isPersonalBioOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
            </div>
            <Collapse isOpen={isPersonalBioOpen} >
              <Row className="nameWithImage">
                <Col md="12" lg="12">
                  <FormGroup>
                    <Label>Full Name <span style={{ color: "red" }}>*</span></Label>
                    <Input
                      id="full_name"
                      type="text"
                      value={isData.full_name}
                      onChange={handleInputChange}
                      name="full_name"
                    />
                    {isError.full_name && (
                      <p className="text-danger">{isError.full_name}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Name with Initials</Label>
                    <Input
                      id="name_with_initials"
                      type="text"
                      value={isData.name_with_initials}
                      onChange={handleInputChange}
                      name="name_with_initials"
                    />
                    {isError.name_with_initials && (
                      <p className="text-danger">{isError.name_with_initials}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Address</Label>
                    <Input
                      id="address"
                      type="textarea"
                      value={isData.address}
                      onChange={handleInputChange}
                      name="address"
                    />
                    {isError.address && (
                      <p className="text-danger">{isError.address}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>NIC <span style={{ color: "red" }}>*</span></Label>
                    <Input
                      id="nic"
                      type="text"
                      value={isData.nic}
                      onChange={handleInputChange}
                      name="nic"
                    />
                    {isError.nic && (
                      <p className="text-danger">{isError.nic}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Passport</Label>
                    <Input
                      id="passport_number"
                      type="text"
                      value={isData.passport_number}
                      onChange={handleInputChange}
                      name="passport"
                    />
                    {isError.passport_number && (
                      <p className="text-danger">{isError.passport_number}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="6" lg="3">
                  <FormGroup>
                    <Label>Sex <span style={{ color: "red" }}>*</span></Label>  <br />
                    <FormGroup>
                      <Dropdown
                        isOpen={sexDropdownOpen}
                        toggle={() => setSexDropdownOpen(!sexDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedSex
                            ? selectedSex
                            : "Select a Sex"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(Sex).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setSelectedSex(value),
                                setIsData((prevData) => ({ ...prevData, sex: value.toUpperCase() })),
                                setIsError({ ...isError, sex: '' })
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {isError.sex && (
                        <p className="text-danger">{isError.sex}</p>
                      )}
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>Status</Label>  <br />
                    <FormGroup>
                      <Dropdown
                        isOpen={statusDropdownOpen}
                        toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedStatus
                            ? selectedStatus
                            : "Select a Status"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(Status).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setSelectedStatus(value),
                                setIsData((prevData) => ({ ...prevData, marital: value.toUpperCase() })),
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="5">
                  <FormGroup>
                    <Label>Date of Birth <span style={{ color: "red" }}>*</span></Label>  <br />
                    <DatePicker
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      showIcon
                      selected={birthDate}
                      onChange={(date) => {
                        setDateOfBirth(date);
                        setIsError({ ...isError, dob: '' })
                      }}
                      className="dateSelector"
                      icon="fa fa-calendar"
                    />

                    {isError.dob && (
                      <p className="text-danger">{isError.dob}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="4" sm="12">
                  <FormGroup>
                    <Label>Land phone Number</Label>
                    <Input
                      id="lane_phone_number"
                      type="text"
                      value={isData.lane_phone_number}
                      onChange={handleInputChange}
                      name="lane_phone_number"
                    />
                    {isError.lane_phone_number && (
                      <p className="text-danger">{isError.lane_phone_number}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="4" sm="12">
                  <FormGroup>
                    <Label>Mobile Number <span style={{ color: "red" }}>*</span></Label>
                    <Input
                      id="mobile_phone_number"
                      type="text"
                      value={isData.mobile_phone_number}
                      onChange={handleInputChange}
                      name="mobile_phone_number"
                    />
                    {isError.mobile_phone_number && (
                      <p className="text-danger">{isError.mobile_phone_number}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="4" sm="12">
                  <FormGroup>
                    <Label>WhatsApp Number <span style={{ color: "red" }}>*</span></Label>
                    <Input
                      id="whatsapp_number"
                      type="text"
                      value={isData.whatsapp_number}
                      onChange={handleInputChange}
                      name="whatsapp_number"
                    />
                    {isError.whatsapp_number && (
                      <p className="text-danger">{isError.whatsapp_number}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Batch Number</Label>
                    <Input
                      id="batch_number"
                      type="text"
                      value={isData.batch_number}
                      onChange={handleInputChange}
                      name="batch_number"
                    />
                    {isError.batch_number && (
                      <p className="text-danger">{isError.batch_number}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup tag="fieldset" className="fieldSetPayOptions">
                    <Label>Course Type <span style={{ color: "red" }}>*</span></Label>
                    <div className="radioOptions">
                      <FormGroup check>
                        <Input
                          id="inRadio"
                          name="typeRadio"
                          type="radio"
                          checked={selectedType === 'IN'}
                          onChange={() => {
                            handleTypeChange('IN');
                            setIsData((prevData) => ({ ...prevData, course_type: 'IN' }));
                            setIsError({ ...isError, course_type: '' })
                          }}
                          style={{ transform: 'scale(1.5)' }}
                        />
                        {' '}
                        <Label check for="inRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                          In
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          id="outRadio"
                          name="typeRadio"
                          type="radio"
                          checked={selectedType === 'OUT'}
                          onChange={() => {
                            handleTypeChange('OUT');
                            setIsData((prevData) => ({ ...prevData, course_type: 'OUT' }));
                            setIsError({ ...isError, course_type: '' })
                          }}
                          style={{ transform: 'scale(1.5)' }}
                        />
                        {' '}
                        <Label check for="outRadio" className="ml-2" style={{ fontSize: "18px" }} >
                          Out
                        </Label>
                      </FormGroup>
                      {isError.course_type && (
                        <p className="text-danger">{isError.course_type}</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              {regNumber !== null && basicRegister ?
                <div className="regNumberBox">
                  <p>Student Registration Number: {regNumber}</p>
                </div>
                : null}
              <Row>
                <div className="mb-4 ml-3">
                  {regNumber === null && basicRegister ?
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => setMoreOpen(!isMoreOpen)}
                    >
                      More
                    </Button> : null}
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                      insertNewStudent();
                      setBasicRegister(true);
                      setMoreRegister(false);
                    }}
                    disabled={isInsertButtonDisabled}
                  >
                    Registration Number
                  </Button>
                  {regNumber !== null ?
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => window.location.reload()}
                    >
                      New Student
                    </Button>
                    : null}
                </div>
              </Row>
            </Collapse>
            <Collapse className="mb-4" isOpen={isMoreOpen} >
              <div className="mb-4 studentSectionTitles" onClick={() => setEduOpen(!isEduOpen)} style={{ cursor: "pointer" }}>
                Educational Qualifications  {!isEduOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
              </div>
              <Collapse className="mb-4" isOpen={isEduOpen} >
                <Row>
                  <Col md="12" lg="12">
                    <div className="eduChecks">
                      {eduQualifications !== null ? eduQualifications.map((item) => (
                        <FormGroup key={item.id} check inline className="mb-2">
                          <Input
                            className="large-checkbox"
                            type="checkbox"
                            id={item.id}
                            checked={checkboxStates[item.name]}
                            onChange={() => handleCheckboxChange(item)}
                          />
                          <Label className="ml-2 mr-4" check htmlFor={item.id}>
                            {item.name}
                          </Label>
                        </FormGroup>
                      )) : null}
                    </div>
                    <div className="alSection mt-4">
                      <Label style={{ fontWeight: "bold" }}>GCE Ordinary Level Examination</Label>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Exam No</Label>
                            <Input
                              id="o_level_exam_no"
                              type="text"
                              value={isData.o_level_exam_no}
                              onChange={handleInputChange}
                              name="o_level_exam_no"
                            />
                            {isError.o_level_exam_no && (
                              <p className="text-danger">{isError.o_level_exam_no}</p>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Year</Label>
                            <Dropdown
                              isOpen={olYearDropdownOpen}
                              toggle={() =>
                                setOlYearDropdownOpen(!olYearDropdownOpen)
                              }
                            >
                              <DropdownToggle caret>
                                {selectedOlyear
                                  ? selectedOlyear.name
                                  : "Year"}
                              </DropdownToggle>
                              <DropdownMenu
                                style={{ maxHeight: 250, overflowY: "auto" }}
                              >
                                {yearList?.map((year) => (
                                  <DropdownItem
                                    key={year.id}
                                    value={year.id}
                                    onClick={() =>
                                      handleOlYear(year)
                                    }
                                  >
                                    {year.name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label>School</Label>
                        <Input
                          id="o_level_exam_school"
                          type="text"
                          value={isData.o_level_exam_school}
                          onChange={handleInputChange}
                          name="o_level_exam_school"
                        />
                        {isError.o_level_exam_school && (
                          <p className="text-danger">{isError.o_level_exam_school}</p>
                        )}
                      </FormGroup>
                      <div>
                        {selectedOLClasses.length > 0 ?
                          <Table bordered className="align-items-center resultTable mb-4" responsive>
                            <thead>
                              <tr>
                                <th>Subject</th>
                                <th>Grade</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedOLClasses?.map((selectedClass, index) => (
                                <tr key={index}>
                                  <td>{selectedClass.subject}</td>
                                  <td>{selectedClass.grade}  </td>
                                  <td><span style={{ cursor: "pointer", color: "red" }} onClick={() => removeEnrolledOlClass(index)}>Remove</span></td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          : null}
                      </div>
                      <Label>O/L Results</Label>
                      <Row style={{ justifyContent: "space-between" }}>
                        <Col md="4">
                          <FormGroup>
                            <Label>Subject
                              {user?.role.name === 'admin' ?
                                <Link to="/admin/ol-subjects" target="_blank" style={{ cursor: "pointer", color: "black", marginLeft: "5px" }}><GoPlusCircle /></Link>
                                : user?.role.name === 'office assistant' ?
                                  <Link to="/assistant/ol-subjects" target="_blank" style={{ cursor: "pointer", color: "black", marginLeft: "5px" }}><GoPlusCircle /></Link>
                                  : null}
                              {loadingOLSubjects ?
                                <Spinner className="m-10" size={"sm"}></Spinner> : <IoMdRefresh onClick={getSubjectsOL} style={{ cursor: "pointer" }} />
                              }
                            </Label>
                            <br />
                            <Dropdown
                              isOpen={OlsubjectDropdownOpen}
                              toggle={() =>
                                setOlSubjectDropdownOpen(!OlsubjectDropdownOpen)
                              }
                            >
                              <DropdownToggle caret>
                                {selectedOlSubject ? selectedOlSubject.name : "Subject"}
                              </DropdownToggle>
                              {olSubjects && olSubjects.length > 0 ? (
                                <DropdownMenu>
                                  {olSubjects && olSubjects.map((subject) => (
                                    <DropdownItem
                                      key={subject.name}
                                      value={subject.id}
                                      onClick={() => {
                                        setSelectedOlSubject(subject);
                                        setOlSubjectDropdownOpen(false);
                                      }}
                                    >
                                      {subject.name}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              ) : null}
                            </Dropdown>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Grade</Label>
                            <Dropdown
                              isOpen={gradeOlDropdownOpen}
                              toggle={() => setGradeOlDropdownOpen(!gradeOlDropdownOpen)}
                            >
                              <DropdownToggle caret>
                                {selectedOlGrade
                                  ? selectedOlGrade
                                  : "Select a Grade"}
                              </DropdownToggle>
                              <DropdownMenu>
                                {Object.entries(Grades).map(([key, value]) => (
                                  <DropdownItem
                                    key={value}
                                    value={value}
                                    onClick={() => [
                                      setSelectedOLGrade(value)
                                    ]}
                                  >
                                    {value}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="addBtnWithIcon">
                            <Button color="primary" style={{ height: "100%" }} onClick={() => addOLClass()} disabled={selectedOlSubject === null || selectedOlGrade === null}>✓</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="alSection mt-4">
                      <Label style={{ fontWeight: "bold" }}>GCE Advance Level Examination</Label>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Exam No</Label>
                            <Input
                              id="a_level_exam_no"
                              type="text"
                              value={isData.a_level_exam_no}
                              onChange={handleInputChange}
                              name="a_level_exam_no"
                            />
                            {isError.a_level_exam_no && (
                              <p className="text-danger">{isError.a_level_exam_no}</p>
                            )}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Year</Label>
                            <Dropdown
                              isOpen={alYearDropdownOpen}
                              toggle={() =>
                                setAlYearDropdownOpen(!alYearDropdownOpen)
                              }
                            >
                              <DropdownToggle caret>
                                {selectedAlyear
                                  ? selectedAlyear.name
                                  : "Year"}
                              </DropdownToggle>
                              <DropdownMenu
                                style={{ maxHeight: 250, overflowY: "auto" }}
                              >
                                {yearList?.map((year) => (
                                  <DropdownItem
                                    key={year.id}
                                    value={year.id}
                                    onClick={() =>
                                      handleAlYear(year)
                                    }
                                  >
                                    {year.name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label>School</Label>
                        <Input
                          id="a_level_exam_school"
                          type="text"
                          value={isData.a_level_exam_school}
                          onChange={handleInputChange}
                          name="a_level_exam_school"
                        />
                        {isError.a_level_exam_school && (
                          <p className="text-danger">{isError.a_level_exam_school}</p>
                        )}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <div>
                  {selectedClasses.length > 0 ?
                    <Table bordered className="align-items-center resultTable mb-4" responsive>
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>Grade</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedClasses?.map((selectedClass, index) => (
                          <tr key={index}>
                            <td>{selectedClass.subject}</td>
                            <td>{selectedClass.grade}  </td>
                            <td><span style={{ cursor: "pointer", color: "red" }} onClick={() => removeEnrolledClass(index)}>Remove</span></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    : null}
                </div>
                <Label>A/L Results</Label>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col md="4">
                    <FormGroup>
                      <Label>Subject
                        {user?.role.name === 'admin' ?
                          <Link to="/admin/subjects" target="_blank" style={{ cursor: "pointer", color: "black", marginLeft: "5px" }}><GoPlusCircle /></Link>
                          : user?.role.name === 'office assistant' ?
                            <Link to="/assistant/subjects" target="_blank" style={{ cursor: "pointer", color: "black", marginLeft: "5px" }}><GoPlusCircle /></Link>
                            : null}
                        {loadingAlSubjects ?
                          <Spinner className="m-10" size={"sm"}></Spinner> : <IoMdRefresh onClick={getSubjectsAL} style={{ cursor: "pointer" }} />
                        }
                      </Label>
                      <br />
                      <Dropdown
                        isOpen={subjectDropdownOpen}
                        toggle={() =>
                          setSubjectDropdownOpen(!subjectDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedSubject ? selectedSubject.name : "Subject"}
                        </DropdownToggle>
                        {alSubjects && alSubjects.length > 0 ? (
                          <DropdownMenu>
                            {alSubjects && alSubjects.map((subject) => (
                              <DropdownItem
                                key={subject.name}
                                value={subject.id}
                                onClick={() => {
                                  setSelectedSubject(subject);
                                  setSubjectDropdownOpen(false);
                                }}
                              >
                                {subject.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Grade</Label>
                      <Dropdown
                        isOpen={gradeDropdownOpen}
                        toggle={() => setGradeDropdownOpen(!gradeDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedGrade
                            ? selectedGrade
                            : "Select a Grade"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(Grades).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setSelectedGrade(value)
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="addBtnWithIcon">
                      <Button color="primary" style={{ height: "100%" }} onClick={() => addClass()} disabled={selectedSubject === null || selectedGrade === null}>✓</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              <div className="mb-4 studentSectionTitles" onClick={() => setProfOpen(!isProfOpen)} style={{ cursor: "pointer" }}>
                Professional Qualifications  {!isProfOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
              </div>
              <Collapse className="mb-4" isOpen={isProfOpen}>
                <Label>Qualifications</Label>
                <Row className="nameWithImage">
                  {qualifications.map((qualification, index) => (
                    <Col md="10" key={index}>
                      <FormGroup>
                        <Input
                          id={`description${index}`}
                          type="text"
                          value={qualification.description}
                          onChange={(event) => handleQualificationInputChange(index, event)}
                          name="description"
                        />
                        {errors[index] && errors[index].description && (
                          <p className="text-danger">{errors[index].description}</p>
                        )}
                      </FormGroup>
                    </Col>
                  ))}
                  {qualifications.every((qualification) => qualification.description) && (
                    <Col md="2">
                      <i
                        className="fa-solid fa-circle-plus fa-2x mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={addQualification}
                      ></i>
                    </Col>
                  )}
                </Row>
                <Label>Other</Label>
                <Row className="nameWithImage">
                  {otherQualifications.map((qualification, index) => (
                    <Col md="10" key={index}>
                      <FormGroup>
                        <Input
                          id={`description${index}`}
                          type="text"
                          value={qualification.description}
                          onChange={(event) => handleOtherQualificationInputChange(index, event)}
                          name="description"
                        />
                        {otherErrors[index] && otherErrors[index].otherQualification && (
                          <p className="text-danger">{otherErrors[index].otherQualification}</p>
                        )}
                      </FormGroup>
                    </Col>
                  ))}
                  {otherQualifications.every((qualification) => qualification.description) && (
                    <Col md="2">
                      <i
                        className="fa-solid fa-circle-plus fa-2x mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={addOtherQualification}
                      ></i>
                    </Col>
                  )}
                </Row>
              </Collapse>
              <div className="mb-4 studentSectionTitles" onClick={() => setOtherOpen(!isOtherOpen)} style={{ cursor: "pointer" }}>
                Other Information {!isOtherOpen ? <i className="fa-solid fa-chevron-down" /> : <i className="fa-solid fa-chevron-up" />}
              </div>
              <Collapse className="mb-4" isOpen={isOtherOpen} >
                <FormGroup tag="fieldset" className="fieldSetPayOptions ml-1">
                  <div className="radioOptions">
                    <FormGroup check>
                      <Input
                        id="motherRadio"
                        name="guardian"
                        type="radio"
                        checked={selectedGuardian === 'MOTHER'}
                        onChange={() => handleRadioChange('MOTHER')}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="motherRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Mother
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="fatherRadio"
                        name="guardian"
                        type="radio"
                        checked={selectedGuardian === 'FATHER'}
                        onChange={() => handleRadioChange('FATHER')}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="fatherRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Father
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="guardianRadio"
                        name="guardian"
                        type="radio"
                        checked={selectedGuardian === 'GUARDIAN'}
                        onChange={() => handleRadioChange('GUARDIAN')}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="guardianRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Guardian
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Full Name</Label>
                      <Input
                        id="guardian_full_name"
                        type="text"
                        value={isData.guardian_full_name}
                        onChange={handleInputChange}
                        name="guardian_full_name"
                      />
                      {isError.guardianName && (
                        <p className="text-danger">{isError.guardianName}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="5">
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <Input
                        id="guardian_phone_number"
                        type="text"
                        value={isData.guardian_phone_number}
                        onChange={handleInputChange}
                        name="guardian_phone_number"
                      />
                      {isError.guardianPhoneNo && (
                        <p className="text-danger">{isError.guardianPhoneNo}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="7">
                    <FormGroup>
                      <Label>Date of Birth</Label>  <br />
                      <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        showIcon
                        selected={startDate}
                        onChange={(date) => filterAttendanceByDate(date)}
                        icon="fa fa-calendar"
                        className="dateSelector"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Do you have a government registered busiess?</div> <br />
                    <FormGroup check>
                      <Input
                        id="bYesRadio"
                        name="businessReg"
                        type="radio"
                        checked={selectedBusinessOption === 'yes'}
                        onChange={() => {
                          setSelectedBusinessOption('yes');
                          setIsData({
                            ...isData,
                            own_business: true
                          })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="bYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="bNoRadio"
                        name="businessReg"
                        type="radio"
                        checked={selectedBusinessOption === 'no'}
                        onChange={() => {
                          setSelectedBusinessOption('no');
                          setIsData({
                            ...isData,
                            own_business: false
                          })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="bNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        id="businessDescription"
                        type="text"
                        value={isData.own_business_description}
                        onChange={handleInputChange}
                        name="own_business_description"
                      />
                      {isError.businessDescription && (
                        <p className="text-danger">{isError.businessDescription}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Do your mother/father owns a government registered busiess?</div> <br />
                    <FormGroup check>
                      <Input
                        id="familyYesRadio"
                        name="familyBusinessReg"
                        type="radio"
                        checked={selectedFamilyBusinessOption === 'yes'}
                        onChange={() => {
                          setSelectedFamilyBusinessOption('yes');
                          setIsData({ ...isData, guardian_business: true })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="familyYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="familyNoRadio"
                        name="familyBusinessReg"
                        type="radio"
                        checked={selectedFamilyBusinessOption === 'no'}
                        onChange={() => {
                          setSelectedFamilyBusinessOption('no');
                          setIsData({ ...isData, guardian_business: false })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="familyNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        id="familyBusinessDescription"
                        type="text"
                        value={isData.guardian_business_description}
                        onChange={handleInputChange}
                        name="guardian_business_description"
                      />
                      {isError.familyBusinessDescription && (
                        <p className="text-danger">{isError.familyBusinessDescription}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Do you have a sponsor who supports going to Japan?</div> <br />
                    <FormGroup check>
                      <Input
                        id="sponsorYesRadio"
                        name="sponsorDetails"
                        type="radio"
                        checked={selectedSponsor === 'yes'}
                        onChange={() => {
                          setSelectedSponsor('yes');
                          setIsData({ ...isData, have_sponsor: true })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="sponsorYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="sponsorNoRadio"
                        name="sponsorDetails"
                        type="radio"
                        checked={selectedSponsor === 'no'}
                        onChange={() => {
                          setSelectedSponsor('no');
                          setIsData({ ...isData, have_sponsor: false })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="sponsorNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Relationship with the sponsor</Label>
                      <Input
                        id="sponsorRelation"
                        type="text"
                        value={isData.sponsor_relationship}
                        onChange={handleInputChange}
                        name="sponsor_relationship"
                      />
                      {isError.sponsorRelation && (
                        <p className="text-danger">{isError.sponsorRelation}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Do you have faced Japanese examinations before?</div> <br />
                    <FormGroup check>
                      <Input
                        id="examYesRadio"
                        name="examDetails"
                        type="radio"
                        checked={selectedExams === 'yes'}
                        onChange={() => {
                          setSelectedExams('yes');
                          setIsData({ ...isData, faced_japanese_exam: true });
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="examYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="examNoRadio"
                        name="examDetails"
                        type="radio"
                        checked={selectedExams === 'no'}
                        onChange={() => {
                          setSelectedExams('no');
                          setIsData({ ...isData, faced_japanese_exam: false });
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="examNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Label>Exams</Label><br />
                <Row className="nameWithImage">
                  {exams.map((exam, index) => (
                    <Col md="10" key={index}>
                      <FormGroup>
                        <Input
                          id={`exam${index}`}
                          type="text"
                          value={exam.completed_exams}
                          onChange={(event) => handleJapanseExamInputChange(index, event)}
                          name="exam"
                        />
                        {examErrors[index] && examErrors[index].exam && (
                          <p className="text-danger">{examErrors[index].exam}</p>
                        )}
                      </FormGroup>
                    </Col>
                  ))}
                  {exams.every((exam) => exam.exam) && (
                    <Col md="2">
                      <i
                        className="fa-solid fa-circle-plus fa-2x mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={addExams}
                      ></i>
                    </Col>
                  )}
                </Row>
                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                  <div className="radioOptions">
                    <div>Have you tried going to Japan before?</div> <br />
                    <FormGroup check>
                      <Input
                        id="beforeTryYesRadio"
                        name="beforeDetails"
                        type="radio"
                        checked={beforeTry === 'yes'}
                        onChange={() => {
                          setBeforeTry('yes');
                          setIsData({ ...isData, tried_to_visit_japan: true })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="beforeTryYesRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        id="beforeTryNoRadio"
                        name="beforeDetails"
                        type="radio"
                        checked={beforeTry === 'no'}
                        onChange={() => {
                          setBeforeTry('no');
                          setIsData({ ...isData, tried_to_visit_japan: false })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      {' '}
                      <Label check for="beforeTryNoRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label>If you failed to visit, explain the reasons.</Label>
                      <Input
                        id="failedReasons"
                        type="text"
                        value={isData.failed_reason}
                        onChange={handleInputChange}
                        name="failed_reason"
                      />
                      {isError.failedReasons && (
                        <p className="text-danger">{isError.failedReasons}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label>Visa Type</Label>
                      <Dropdown
                        isOpen={visaDropdownOpen}
                        toggle={() => setVisaDropdownOpen(!visaDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedVisaType
                            ? selectedVisaType
                            : "Select a Visa Type"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(VisaTypes).map(([key, value]) => (
                            <DropdownItem
                              key={key}
                              value={value}
                              onClick={() => [
                                setSelectedVisaType(value),
                                setSelectedVisa(key, value),
                                setIsData((prevState) => ({
                                  ...prevState,
                                  failed_visa: {
                                    ...prevState.failed_visa,
                                    visa_type_id: key
                                  }
                                }))
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Year</Label>
                      <Dropdown
                        isOpen={yearListDropdownOpen}
                        toggle={() =>
                          setYearsDropdownOpen(!yearListDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedStartyear
                            ? selectedStartyear.name
                            : "Start Year"}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: 250, overflowY: "auto" }}
                        >
                          {yearList?.map((year) => (
                            <DropdownItem
                              key={year.id}
                              value={year.id}
                              onClick={() => {
                                setSelectedStartYear(year);
                                setIsData((prevState) => ({
                                  ...prevState,
                                  failed_visa: {
                                    ...prevState.failed_visa,
                                    year: year.name
                                  }
                                }));
                              }
                              }
                            >
                              {year.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              {regNumber !== null && moreRegister ?
                <div className="regNumberBox">
                  <p>Student Registration Number: {regNumber}</p>
                </div>
                : null}
              <Button
                color="primary"
                type="button"
                onClick={() => {
                  insertNewStudent();
                  setMoreRegister(true);
                  setBasicRegister(false);
                }}
                disabled={moreDisabled}
              >
                Save
              </Button>
              {regNumber !== null ?
                <Button
                  color="primary"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  New Student
                </Button>
                : null}
            </Collapse>
          </Form>
        </Card>
      </div >
    </>
  );
};

export default Students;
