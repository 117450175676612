import axios from "axios";

const apiUrl = "https://ims-backend.ardillalabs.cloud/api"; 

export const createPayment = async (createStudentFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const body = JSON.stringify(createStudentFormData);
        const responseUser = await axios.post(
          `${apiUrl}/payment/create-payment`,
          body,
          config
        );
  
        if (responseUser.status === 201) {
          resolve(responseUser);
        } else {
          reject(responseUser.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          resolve({
                message: "Network Error",
                status: 404,
                statusText: err.code,
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  };

  export const getPaymentsByStudentId = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const responseUser = await axios.get(
          `${apiUrl}/payment/find-by-student-id/${id}`,
          config
        );
  
        if (responseUser.status === 200) {
          resolve(responseUser);
        } else {
          reject(responseUser.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          resolve({
                message: "Network Error",
                status: 404,
                statusText: err.code,
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  };

  export const getMoneyBalance = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/payment/payment-balance`,
          config
        );
  
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          resolve({
                message: "Network Error",
                status: 404,
                statusText: err.code,
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  };

  export const createVoucher = async (createStudentFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const body = JSON.stringify(createStudentFormData);
        const responseUser = await axios.post(
          `${apiUrl}/voucher/create-voucher`,
          body,
          config
        );
  
        if (responseUser.status === 201) {
          resolve(responseUser);
        } else {
          reject(responseUser.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          resolve({
                message: "Network Error",
                status: 404,
                statusText: err.code,
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  };

  export const filterVouchers = async (bill, date, voucher_number, staff_id, batch_number) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };

        const apiUrl1 = 'https://ims-backend.ardillalabs.cloud/api/voucher';
        let queryParams = [];
  
        if (bill !== undefined && bill !== null) {
          queryParams.push(`bill=${bill}`);
        }
  
        if (date !== undefined && date !== null) {
          queryParams.push(`date=${date}`);
        }
  
        if (voucher_number !==undefined && voucher_number !== null) {
          queryParams.push(`voucher_number=${voucher_number}`);
        }
  
        if (staff_id !== undefined && staff_id !== null) {
          queryParams.push(`staff_id=${staff_id}`);
        }
  
        if (batch_number !==undefined && batch_number !==null) {
          queryParams.push(`batch_number=${batch_number}`);
        }
  
        const queryString = queryParams.join('&');
        const url = apiUrl1 + '?' + queryString;
  
        const response = await axios.get(url, config);
  
        if (response.status === 200) {
          resolve(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.data.message,
                status: err.code,
                success: false,
              },
            },
          });
        }
      }
    });
  };

  export const getExpensesByBatch = async (batch_number) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };

        const apiUrl1 = 'https://ims-backend.ardillalabs.cloud/api/voucher';
        let queryParams = [];
  
        if (batch_number !==undefined) {
          queryParams.push(`batch_number=${batch_number}`);
        }
  
        const queryString = queryParams.join('&');
        const url = apiUrl1 + '?' + queryString;
        const response = await axios.get(url, config);
        if (response.status === 200) {
          resolve(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.data.message,
                status: err.code,
                success: false,
              },
            },
          });
        }
      }
    });
  };

  export const getTotalExpenseByBatch = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/voucher/get-expenses-by-batch-no/${id}`,
          config
        );
  
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          resolve({
                message: "Network Error",
                status: 404,
                statusText: err.code,
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  };

  export const editVoucher = async (id, images, bill) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        };
        const formData = new FormData();
  
        formData.append("id", id);
        for (const image of images) {
          formData.append("docs", image);
        }
        formData.append("bill", bill);
  
        const response = await axios.put(
          `${apiUrl}/voucher/update-voucher`,
          formData,
          config
        );
  
        if (response.data.success === true) {
          resolve(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.data.message,
                status: err.code,
                success: false,
              },
            },
          });
        }
      }
    });
  };

  export const getVoucherByNumber = async (voucherNumber) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/voucher?voucher_number=${voucherNumber}`,
          config
        );
  
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          resolve({
                message: "Network Error",
                status: 404,
                statusText: err.code,
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  };

  export const createTrasaction = async (createStudentFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const responseUser = await axios.post(
          `${apiUrl}/cashier-account/create-cashier-account`,
          createStudentFormData,
          config
        );
  
        if (responseUser.status === 201) {
          resolve(responseUser);
        } else {
          reject(responseUser.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          resolve({
                message: "Network Error",
                status: 404,
                statusText: err.code,
          });
        } else {
          resolve(err.response.data);
        }
      }
    });
  };