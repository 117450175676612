import {
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import { useState, useEffect } from "react";
import { getAllSubjects } from "actions/Subjects";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { addNewSubject } from "actions/Subjects";
import { editSubject } from "actions/Subjects";
import { getStudentsByLimit } from "actions/Students";
import { getSubjectsBySearch } from "actions/Subjects";
import Paginations from "components/Pagination/Paginations";
import { getSubjectsByLimit } from "actions/Subjects";
import { BiEdit } from "react-icons/bi";

const AlSubjects = () => {
  const [allSubjects, setAllSubjects] = useState(null);
  const [isSubjects, setIsSubjects] = useState(false);
  const [isLoadingSubjects, setLoadingSubjects] = useState(true);
  const [editModal, setEditmodal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState({
    id: null,
    name: "",
    status: "",
  });
  const [isData, setData] = useState({
    subjectName: "",
    status: "",
  });
  const [errors, setErrors] = useState({});
  const [editErrors, setEditErrors] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isEdited, setEdit] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip]= useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSubjects, setFilteredSubjects] = useState(allSubjects);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [initialSubject, setInitialSubject] = useState({
    id: null,
    name: "",
    status: "",
  });

  const fetchData = async (itemsPerPge, skip) => {
    try {
      const Subjects = await getSubjectsByLimit(itemsPerPge, skip);
    setPageCount(Subjects.data.pageCount);
      setAllSubjects(Subjects.data.data);
      setLoadingSubjects(false);
      setIsSubjects(true);
    } catch (error) {
      setLoadingSubjects(false);
      setIsSubjects(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);

  const searchSubject = async (searchQuery) => {
    const searched = await getSubjectsBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllSubjects(searched.data.data);
  };

  const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
    const searched = await getSubjectsBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllSubjects(searched.data.data);
    setCurrentPage(skip + 1);
  }

  const handlePagination = async (pageNumber) => {
    if (searchQuery !== " ") {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
    } else {
      const features = await fetchData(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }

  const handleInputChange = (values) => {
    setErrors({ subject: "" });
    setData({
      ...isData,
      ...values,
    });
  };

  // Validate input
  const isValidSubject = (value) => {
    if (value.length < 2) {
      setErrors({
        subject: "Subject name must be at least 2 characters long.",
      });
      return false;
    } else {
      setErrors({ subject: "" });
      return true;
    }
  };

  const insertNewSubject = async () => {
    if (isValidSubject(isData.subjectName)) {
      const response = await addNewSubject(isData.subjectName);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        setData({
          subjectName: "",
        });
        fetchData(itemsPerPge, skip);
      } else {
        setErrorMessage(response.message);
        setFailed(true);
        setData({
          subjectName: "",
        });
      }
    }
  };

  const handleEditClick = (subject) => {
    setSelectedSubject(subject);
    setInitialSubject(subject);
    setEditmodal(true);
  };

  // Validate input
  const isValidEditedSubject = (value) => {
    if (value.name.length < 2) {
      setEditErrors({
        name: "Subject name must be at least 2 characters long.",
      });
      return false;
    } else {
      setEditErrors({ name: "" });
      return true;
    }
  };

  function getChangedValues(selectedSubject) {
    const changedValues = {};
    for (const key in selectedSubject) {
      if (
        key !== "id" &&
        selectedSubject.hasOwnProperty(key) &&
        selectedSubject[key] !== initialSubject[key]
      ) {
        changedValues[key] = selectedSubject[key];
      }

      if (key === "id") {
        changedValues[key] = selectedSubject[key];
      }
    }
    return changedValues;
  }

  const editSubjectDetails = async () => {
    const validityCheck = isValidEditedSubject(selectedSubject);
    if (validityCheck === true) {
      setEdit(false);
      const data = getChangedValues(selectedSubject);
      const body = JSON.stringify(data);
      const response = await editSubject(body);
      if (response.success === true) {       
        setSuccessMessage(response.message);
        setSuccess(true);
        setData({
          subjectName: "",
        });
        await fetchData(itemsPerPge, skip);
        setEditmodal(false);
      } else {
        setFailed(true);
      }
    }
  };

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="6" xs="12">
                <span>
                  <h3 className="mb-0">Add New Subject</h3>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    id="subject"
                    placeholder="Technology"
                    type="text"
                    value={isData.subjectName}
                    required
                    onChange={(e) =>
                      handleInputChange({ subjectName: e.target.value })
                    }
                  />
                  {errors.subject && (
                    <p className="text-danger">{errors.subject}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => insertNewSubject()}
                  disabled={!isData.subjectName}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingSubjects ? (
          <Card>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isSubjects ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage"/>
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-0">Subjects</h3>
              </Col>
              <Col className="justify-content-end" md="4">
                <InputGroup className="mb-3">
                  <Input
                    type="text"
                    placeholder="Search by name"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCurrentPage(1);
                      setCurrentStart(0);
                      searchSubject(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allSubjects?.map((subject, index) => (
                      <tr key={index}>
                        <td>{subject.name}</td>
                        <td>{subject.status ? "Active" : "Inactive"}</td>
                        <td>
                          <Button
                            color="primary"
                            type="button"
                            id="editSubject"
                            onClick={() => handleEditClick(subject)}
                          >
                            <BiEdit />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="editSubject"
                      >
                       Edit Subject
                      </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>  
            <div style={{paddingTop: "10px"}}>
          {pageCount > 1 ?
              <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                currentStart={currentStart}
                setCurrentStart={setCurrentStart}></Paginations>
              : null}
          </div>
          </Card>
        )}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => {
            setEditmodal(false);
            editErrors({ name: "" });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Subject
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setEditmodal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Input
                      id="exampleFormControlInput1"
                      placeholder="Subject Name"
                      type="text"
                      value={selectedSubject ? selectedSubject.name : ""}
                      onChange={(e) => {
                        setSelectedSubject({
                          id: selectedSubject.id,
                          name: e.target.value,
                          status: selectedSubject.status,
                        });
                        setEditErrors('')
                        setEdit(true);
                      }}
                    />
                    {editErrors.name && (
                      <p className="text-danger">{editErrors.name}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    {/* <Inpus */}
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle caret>
                        {selectedSubject
                          ? selectedSubject.status
                            ? "Active"
                            : "Inactive"
                          : "Select Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedSubject({
                              id: selectedSubject.id,
                              name: selectedSubject.name,
                              status: true,
                            });
                            setEdit(true);
                          }}
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedSubject({
                              id: selectedSubject.id,
                              name: selectedSubject.name,
                              status: false,
                            });
                            setEdit(true);
                          }}
                        >
                          Inactive
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button
                  color="primary"
                  type="button"
                  disabled={!isEdited}
                  onClick={() => editSubjectDetails()}
                  style={{ marginLeft: "15px" }}
                >
                  Save
                </Button>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default AlSubjects;
