
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Spinner } from "reactstrap";

const Loader = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Spinner className="m-10">Loading...</Spinner>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default Loader;
