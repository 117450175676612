import { getUser } from "actions/Auth";
import { getAuthorizedList } from "actions/Auth";
import { getStaffList } from "actions/Auth";
import { createVoucher } from "actions/Payments";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
    InputGroup,
    UncontrolledTooltip,
    Label,
    Collapse,
    ButtonGroup,
} from "reactstrap";

const NewVoucher = () => {
    const Category = {
        KITCHEN_ITEM: "KITCHEN_ITEM",
        CLASS: "CLASS",
        HOSTAL: "HOSTAL",
        MARKETING: "MARKETING",
        FOOD: "FOOD",
        OFFICE: "OFFICE",
        TRANSPORT: "TRANSPORT",
        SALARY: "SALARY",
        EXTRA: "EXTRA",
        RENTAL: "RENTAL",
        DASH: "-",
        OTHER: "OTHER",
    }

    const Bill = {
        PENDING: "PENDING",
        OK: "OK",
    }

    const [staffList, setStaffList] = useState([]);
    const [authorizedList, setAuthorizedList] = useState([]);
    const [isCategoryDropDownOpen, setCategoryDropDownOpen] = useState(false);
    const [isStaffDropDownOpen, setStaffDropDownOpen] = useState(false);
    const [isAuthorizedDropDownOpen, setAuthorizedOpen] = useState(false);
    const [isData, setData] = useState({
        staff_id: '',
        category: '',
        amount: '',
        bill: '',
        batch_number: '',
        auth_id: ''
    });
    const [selectedStaffMember, setSelectedStaffMember] = useState(null);
    const [isError, setError] = useState({});
    const [selectedType, setSelectedType] = useState('');
    const [selectedAuthorized, setSelectedAuthorized] = useState(null);
    const [user, setUser] = useState(null);
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");
    const [voucherNumber, setVoucherNumber] = useState(null);
    const [disabledVoucherBtn, setDisabledVoucherBtn] = useState(false);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;

    const handleUpdateStaffList = (response) => {
        const newStaffList = response.map((staffMember) => ({
            id: staffMember.id,
            full_name: staffMember.full_name,
            image_url: staffMember.image_url,
        }));

        setStaffList(newStaffList);
    };

    const fetchData = async () => {
        try {
            const response1 = await getUser();
            setUser(response1);
            const response = await getStaffList();
            if (response.length > 0) {
                handleUpdateStaffList(response);
            }

            const authorized = await getAuthorizedList();
            if (authorized.length > 0) {
                const newAuthorizedList = authorized.map((staffMember) => ({
                    id: staffMember.id,
                    full_name: staffMember.full_name,
                }));
                setAuthorizedList(newAuthorizedList);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleTypeChange = (type) => {
        setSelectedType(type)
    }

    const isValidVoucher = (value) => {
        const errors = {};
        if (isData.staff_id === '') {
            errors.staff_id =
                "Staff Member is required.";
        }
        if (isData.category === '') {
            errors.category =
                "Category is required.";
        }
        if (isData.amount === '') {
            errors.amount =
                "Amount is required.";
        }
        if (isData.bill === '') {
            errors.bill =
                "Bill is required.";
        }
        if (isData.batch_number === '') {
            errors.batch_number =
                "Batch Number is required.";
        }
        if (isData.auth_id === '') {
            errors.auth_id =
                "Authorized Person is required.";
        }
        if (Object.keys(errors).length > 0) {
            setError(errors);
            return false;
        } else {
            return true;
        }
    };

    const addNewVoucher = async () => {
        try {
            isData.sender_id = +(user.id);
            isData.date = newDate;
            if (isValidVoucher(isData)) {
                setDisabledVoucherBtn(true);
                const response = await createVoucher(isData);
                if (response.data.success === true) {
                    setVoucherNumber(response.data.voucher.voucher_number);
                    setDisabledVoucherBtn(false);
                    setData({
                        staff_id: '',
                        category: '',
                        amount: '',
                        bill: '',
                        batch_number: '',
                        auth_id: '',
                        description: ''
                    });
                    setSelectedStaffMember('');
                    setSelectedType('');
                } else {
                    setFailed(true);
                    setErrorMessage(response.message);
                    setDisabledVoucherBtn(false);
                }
            }
        } catch (error) {
            setFailed(true);
            setErrorMessage(error.message);
            setDisabledVoucherBtn(false);
        }
    }
    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Card className="mb-4 pt-4 pb-4 shadow">
                    <Form className="ml-4 mb-4 mr-4">
                        <Row>
                            <Col sm="12" md="6" lg="3">
                                <FormGroup>
                                    <Label>
                                        Staff Member
                                    </Label>
                                    <br />
                                    <FormGroup>
                                        <Dropdown
                                            isOpen={isStaffDropDownOpen}
                                            toggle={() => setStaffDropDownOpen(!isStaffDropDownOpen)}
                                        >
                                            <DropdownToggle caret>
                                                {selectedStaffMember
                                                    ? selectedStaffMember
                                                    : "Select a Staff Member"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {staffList.map((staffMember) => (
                                                    <DropdownItem
                                                        key={staffMember.id}
                                                        value={staffMember.full_name}
                                                        onClick={() => {
                                                            setSelectedStaffMember(staffMember.full_name);
                                                            setData((prevData) => ({
                                                                ...prevData,
                                                                staff_id: Number(staffMember.id),
                                                            }));
                                                            setError({ ...isError, staff_id: '' });
                                                        }}
                                                    >
                                                        {staffMember.full_name}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                        {isError.staff_id && (
                                            <p className="text-danger">{isError.staff_id}</p>
                                        )}
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="6" lg="3">
                                <FormGroup>
                                    <Label>Category</Label>
                                    <FormGroup>
                                        <Dropdown
                                            isOpen={isCategoryDropDownOpen}
                                            toggle={() => setCategoryDropDownOpen(!isCategoryDropDownOpen)}
                                        >
                                            <DropdownToggle caret>
                                                {isData.category
                                                    ? isData.category
                                                    : "Select a Category"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.entries(Category).map(([key, value]) => (
                                                    <DropdownItem
                                                        key={value}
                                                        value={value}
                                                        onClick={() => [
                                                            setData((prevData) => ({ ...prevData, category: value })),
                                                            setError({ ...isError, category: '' }),
                                                        ]}
                                                    >
                                                        {value}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                        {isError.category && (
                                            <p className="text-danger">{isError.category}</p>
                                        )}
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Remark</Label>
                                    <Input
                                        id="remark"
                                        type="textarea"
                                        value={isData.address}
                                        onChange={(e) => setData((prevData) => ({ ...prevData, remark: e.target.value }))}
                                        name="remark"
                                    />
                                    {isError.remark && (
                                        <p className="text-danger">{isError.remark}</p>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" lg="8">
                                <FormGroup>
                                    <Label>Amount</Label>
                                    <Input
                                        id="amount"
                                        type="text"
                                        value={isData.amount}
                                        name="amount"
                                        pattern="\d*"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                setData({ ...isData, amount: Number(value) });
                                                setError({ ...isError, amount: '' });
                                            }
                                        }}
                                    />
                                    {isError.amount && (
                                        <p className="text-danger">{isError.amount}</p>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md="12" lg="4">
                                <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                    <Label>Bill</Label>
                                    <div className="radioOptions">
                                        <FormGroup check>
                                            <Input
                                                id="cashRadio"
                                                name="typeRadio"
                                                type="radio"
                                                checked={selectedType === 'OK'}
                                                onChange={() => {
                                                    handleTypeChange('OK');
                                                    setData((prevData) => ({ ...prevData, bill: 'OK' }));
                                                    setError({ ...isError, bill: '' });
                                                }}
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                            {' '}
                                            <Label check for="cashRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                                                OK
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Input
                                                id="bocRadio"
                                                name="typeRadio"
                                                type="radio"
                                                checked={selectedType === 'PENDING'}
                                                onChange={() => {
                                                    handleTypeChange('PENDING');
                                                    setData((prevData) => ({ ...prevData, bill: 'PENDING' }));
                                                    setError({ ...isError, bill: '' });

                                                }}
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                            {' '}
                                            <Label check for="bocRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }} >
                                                Pending
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    {isError.bill && (
                                        <p className="text-danger">{isError.bill}</p>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Batch Number</Label>
                                    <Input
                                        id="batch_number"
                                        type="text"
                                        value={isData.batch_number}
                                        onChange={(e) => {
                                            setData((prevData) => ({ ...prevData, batch_number: e.target.value }));
                                            setError({ ...isError, batch_number: '' });
                                        }}
                                        name="batch_number"
                                    />
                                    {isError.batch_number && (
                                        <p className="text-danger">{isError.batch_number}</p>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Authorized Person
                                    </Label>
                                    <br />
                                    <FormGroup>
                                        <Dropdown
                                            isOpen={isAuthorizedDropDownOpen}
                                            toggle={() => setAuthorizedOpen(!isAuthorizedDropDownOpen)}
                                        >
                                            <DropdownToggle caret>
                                                {selectedAuthorized
                                                    ? selectedAuthorized
                                                    : "Select an Authorized Member"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {authorizedList.map((staffMember) => (
                                                    <DropdownItem
                                                        key={staffMember.id}
                                                        value={staffMember.full_name}
                                                        onClick={() => {
                                                            setSelectedAuthorized(staffMember.full_name);
                                                            setData((prevData) => ({
                                                                ...prevData,
                                                                auth_id: Number(staffMember.id),
                                                            }));
                                                            setError({ ...isError, auth_id: '' });
                                                        }}
                                                    >
                                                        {staffMember.full_name}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                        {isError.auth_id && (
                                            <p className="text-danger">{isError.auth_id}</p>
                                        )}
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        {voucherNumber !== null ?
                            <div className="regNumberBox">
                                <p>Voucher Number: {voucherNumber}</p>
                            </div>
                            : null}
                        <Row>
                            <Col>
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        addNewVoucher();
                                    }}
                                    disabled={disabledVoucherBtn}
                                >
                                    Create Voucher
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </>
    );
};

export default NewVoucher;
