const SecondaryHeader = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-7 pt-3 pt-md-4">
      </div>
    </>
  );
};

export default SecondaryHeader;
