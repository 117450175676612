import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Progress, Row, Table } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import logo from "../assets/img/brand/favicon.png"
import "../assets/css/student-layout.css";
import { getStudent } from "actions/Students";
import Rating from "react-rating";
import DatePicker from "react-datepicker";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

const Student = () => {
  const allQualifications = [
    { id: 1, name: 'GCE O/L' },
    { id: 2, name: 'GCE A/L' },
    { id: 3, name: 'Diploma' },
    { id: 4, name: 'Degree' },
    { id: 5, name: 'Master' }
  ];

  const { registration_number } = useParams();

  const mainContent = React.useRef(null);
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [isData, setData] = useState({});
  const [receivedQualifications, setReceivedqualification] = useState([]);
  const navigate = useNavigate();
  const [noData, setNoData] = useState(true);
  const [regNumber, setRegNumber] = useState(null);
  const [searchInitial, setInitialSearch] = useState(true);

  const fetchData = async () => {
    try {
      setNoData(false);
      setInitialSearch(false);
      const response = await getStudent(registration_number.toUpperCase());
      setData(response);
      setReceivedqualification(response.student_edu_qualifications?.map(q => q.edu_qualification.name) || [])
      setUserDetails(true);
      setUserLoading(false);
    } catch (error) {
      setNoData(true);
      setUserDetails(true);
      setUserLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (regNumber) {
      navigate(`/student/${regNumber}`);
    }
  };

  useEffect(() => {
    if (registration_number) {
      fetchData();
    }
  }, [registration_number]);

  React.useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const clickHome = () => {
    window.open('https://apesense.lk', '_blank');
  }

  return (
    <div className="student">
      <nav className="navNavbar">
        <div className="nav">
          <div className="logo">
            <img src={logo} alt="logo" className="logoImage" onClick={clickHome}></img>
          </div>
          <div>
            <Button color="primary" type="button" onClick={clickHome}>Home</Button>
          </div>
        </div>
      </nav>
      <div className="searchCard">
        <Card className="studentSearchSection">
              <InputGroup>
          <Input
            id="regNumber"
            placeholder="Registration Number or National Identity Card Number"
            type="text"
            value={regNumber || ''}
            name="regNumber"
            onChange={(e) => setRegNumber(e.target.value)}
          />
        <Button color="primary"
                type="button"
                style={{borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px'}}
                onClick={handleSearch}><i className="fa fa-search" aria-hidden="true"></i></Button>
            
        </InputGroup>
        </Card>
      </div>
      <div className="stuContent" ref={mainContent}>
        {noData ?
          (!searchInitial ?
            <div className="noDataStudent"></div> : '') :
          <>
            <Card className="stuCard">
              <div className="bioSection">
                <SkeletonTheme color="#ffffff" highlightColor="#ffffff">
                  <div className="studentImageSection">
                    {!userLoading ? (
                      <img
                        src={isData?.image_url !== null ? isData.image_url : 'https://ape-sense.s3.amazonaws.com/student-image/image1724930888205'}
                        alt="Selected Preview"
                        className="studentImage"
                      />
                    ) : (
                      <Skeleton width={300} height={400} count={1} />
                    )}
                  </div>
                  <div className="studentDetails">
                    <div className="stuNameWithBadge">
                      {!userLoading ? <h1 className="stuFullName" style={{ color: 'black' }}>{isData?.full_name}</h1> : <Skeleton height={20} width={300} />}
                    </div>
                    {!userLoading ? <h3 className="stuNameInitials">{isData?.name_with_initials}</h3> : <Skeleton height={20} width={300} />}
                    {!userLoading ? <DatePicker
                      showIcon
                      selected={isData?.dob}
                      className="dateSelector selector1"
                      icon="fa fa-calendar"
                      disabled
                      dateFormat="MMMM d, yyyy"
                    /> : <Skeleton height={20} width={100} />}
                    <div className="stuStatus">
                      {!userLoading ? <Badge className="stuBadge" color="primary">{isData?.sex === 'MALE' ? <i className="fa-solid fa-mars"></i> : <i className="fa-solid fa-venus"></i>} {isData.sex}</Badge>
                        : <Skeleton className="stuBadge1" />}
                      {!userLoading ? <Badge className="stuBadge" color="primary">{isData?.marital}</Badge> : <Skeleton className="stuBadge1" />}
                    </div>
                    {!userLoading ? <div className="stuStatus">NIC: {isData?.nic}</div> : <Skeleton />}
                    {!userLoading ? <div className="stuStatus">SID: {isData?.student_number}</div> : <Skeleton />}
                    {!userLoading ? <div className="stuStatus">BATCH {String(isData?.batch_number).padStart(2, '0')}</div> : <Skeleton />}
                  </div>
                </SkeletonTheme>
              </div>
              <div className="svg-background"></div>
              <div className="svg-background2"></div>
            </Card>
            <Card className="stuCard">
              <div className="academicSection">
                <div className="stuTitle">Academic Details</div>
                <div className="stuCardSection">
                  <Card className="hrsCard">
                    <CardHeader className="hrsHeader">{!userLoading ? `Learned Hours` : <Skeleton />}</CardHeader>
                    <CardBody className="learnedHrs">
                      {!userLoading ? (
                        `${isData?.learned_hours !== null ? isData?.learned_hours : 0} hr`
                      ) : (
                        <Skeleton />
                      )}
                    </CardBody>
                  </Card>
                  <Card className="hrsCard">
                    <CardHeader className="hrsHeader">{!userLoading ? `Kanji` : <Skeleton />}</CardHeader>
                    <CardBody className="learnedHrs"> {!userLoading ? (
                      `${isData?.kanji !== null ? isData?.kanji : 0}`
                    ) : (
                      <Skeleton />
                    )}</CardBody>
                  </Card>
                </div>
                <div className="completedSection">
                  <div className="stuSubTitle">Completed Areas</div>
                  {!userLoading ? (
                    isData?.is_listening === false &&
                      isData?.is_alphabet === false &&
                      isData?.is_spoken_practice === false &&
                      isData?.is_irodori_book_1 === false &&
                      isData?.is_irodori_book_2 === false &&
                      isData?.is_irodori_book_3 === false ? (
                      <div>Completed areas are currently unavailable.</div>
                    ) : (
                      // <div className="completedAreas">
                      //   {isData?.is_alphabet && <Badge className="stuBadge" color="primary">Alphabet</Badge>}
                      //   {isData?.is_listening && <Badge className="stuBadge" color="primary">Listening</Badge>}
                      //   {isData?.is_spoken_practice && <Badge className="stuBadge" color="primary">Spoken Practice</Badge>}
                      //   {isData?.is_irodori_book_1 && <Badge className="stuBadge" color="primary">Irodori Book 1</Badge>}
                      //   {isData?.is_irodori_book_2 && <Badge className="stuBadge" color="primary">Irodori Book 2</Badge>}
                      //   {isData?.is_irodori_book_3 && <Badge className="stuBadge" color="primary">Irodori Book 3</Badge>}
                      // </div>                                        
                      <div className="ml-4">
                        <FormGroup check inline className="areaCheck">
                          <Label check className="areaLabel">
                            Alphabet
                          </Label>
                          <Input type="checkbox" className="large-checkbox"
                            checked={isData?.is_alphabet} readOnly />
                        </FormGroup><br />
                        <FormGroup check inline className="areaCheck">
                          <Label check className="areaLabel">
                            Listening
                          </Label>
                          <Input type="checkbox" className="large-checkbox"
                            checked={isData?.is_listening} readOnly />
                        </FormGroup><br />
                        <FormGroup check inline className="areaCheck">
                          <Label check className="areaLabel">
                            Spoken Practice
                          </Label>
                          <Input type="checkbox" className="large-checkbox"
                            checked={isData?.is_spoken_practice} readOnly />
                        </FormGroup><br />
                        <FormGroup check inline className="areaCheck">
                          <Label check className="areaLabel">
                            Irodori Book 1
                          </Label>
                          <Input type="checkbox" className="large-checkbox"
                            checked={isData?.is_irodori_book_1} readOnly />
                        </FormGroup><br />
                        <FormGroup check inline className="areaCheck">
                          <Label check className="areaLabel">
                            Irodori Book 2
                          </Label>
                          <Input type="checkbox" className="large-checkbox"
                            checked={isData?.is_irodori_book_2} readOnly />
                        </FormGroup><br />
                        <FormGroup check inline className="areaCheck">
                          <Label check className="areaLabel">
                            Irodori Book 3
                          </Label>
                          <Input type="checkbox" className="large-checkbox"
                            checked={isData?.is_irodori_book_3} readOnly />
                        </FormGroup>
                      </div>
                    )
                  ) : (
                    <Skeleton />
                  )}
                </div>
                <div className="examResults">
                  <div className="stuSubTitle">Academic Exam Results</div>
                  {!userLoading ? (
                    isData.academic_exam_results.length > 0 ? (
                      isData.academic_exam_results.map((result, index) => (
                        <div key={index}>
                          <p className="stuResultTitle">{result.academic_exam.name}</p>
                          <p className="stuResultMarks">Marks {result.mark}/{result.limit}</p>
                          <Progress
                            className="resultprogress"
                            max={result.limit}
                            value={result.mark}
                            style={{ height: '10px' }}
                          />
                        </div>
                      ))
                    ) : (
                      `Academic exam results are currently unavailable.`
                    )
                  ) : (
                    <Skeleton />
                  )}
                </div>
                <div className="ratingCards">
                  <Card className="hrsCard">
                    <CardHeader className="hrsHeader">{!userLoading ? `Language Skills` : <Skeleton />}</CardHeader>
                    <CardBody className="learnedHrs1" >{!userLoading ?
                      `${parseFloat(isData?.language_skill !== null ? isData?.language_skill : 0).toFixed(1)}` : <Skeleton />}</CardBody>
                    <CardFooter style={{ borderTop: 'none', paddingTop: '0px' }}>
                      {!userLoading ?
                        <Rating
                          emptySymbol="fa-regular fa-star"
                          fullSymbol="fa fa-star"
                          fractions={2}
                          className="custom-rating"
                          initialRating={isData.language_skill}
                          readonly
                        /> : <Skeleton />}
                    </CardFooter>
                  </Card>
                  <Card className="hrsCard">
                    <CardHeader className="hrsHeader">{!userLoading ? `Attitude` : <Skeleton />}</CardHeader>
                    <CardBody className="learnedHrs1">{!userLoading ?
                      `${parseFloat(isData?.attitude !== null ? isData?.attitude : 0).toFixed(1)}` : <Skeleton />}</CardBody>
                    <CardFooter style={{ borderTop: 'none', paddingTop: '0px' }}>
                      {!userLoading ?
                        <Rating
                          emptySymbol="fa-regular fa-star"
                          fullSymbol="fa fa-star"
                          fractions={2}
                          className="custom-rating"
                          initialRating={isData.attitude}
                          readonly
                        /> : <Skeleton />}
                    </CardFooter>
                  </Card>
                  <Card className="hrsCard">
                    <CardHeader className="hrsHeader">{!userLoading ? `Working Skills` : <Skeleton />}</CardHeader>
                    <CardBody className="learnedHrs1">{!userLoading ?
                      `${parseFloat(isData?.working_skill !== null ? isData?.working_skill : 0).toFixed(1)}` : <Skeleton />}</CardBody>
                    <CardFooter style={{ borderTop: 'none', paddingTop: '0px' }}>
                      {!userLoading ? <Rating
                        emptySymbol="fa-regular fa-star"
                        fullSymbol="fa fa-star"
                        fractions={2}
                        className="custom-rating"
                        initialRating={isData.working_skill}
                        readonly
                      /> : <Skeleton />}
                    </CardFooter>
                  </Card>
                  <Card className="hrsCard">
                    <CardHeader className="hrsHeader">{!userLoading ? `Cleanliness` : <Skeleton />}</CardHeader>
                    <CardBody className="learnedHrs1">{!userLoading ?
                      `${parseFloat(isData?.cleanliness !== null ? isData?.cleanliness : 0).toFixed(1)}` : <Skeleton />}</CardBody>
                    <CardFooter style={{ borderTop: 'none', paddingTop: '0px' }}>
                      {!userLoading ? <Rating
                        emptySymbol="fa-regular fa-star"
                        fullSymbol="fa fa-star"
                        fractions={2}
                        className="custom-rating"
                        initialRating={isData.cleanliness}
                        readonly
                      /> : <Skeleton />}
                    </CardFooter>
                  </Card>
                </div>
                <div className="recom">
                  <div className="stuSubTitle">Recommendation</div>
                  <p>{!userLoading ? `${isData?.recommendation !== null ? isData?.recommendation : 'Recommendation comments are unavailable.'}` : <Skeleton />}</p></div>
              </div>
            </Card>
            <div className="eduSection">
              <div className="eduLeft">
                <Card className="stuCard">
                  <div className="olSection">
                    <div className="stuTitle">GCE Ordinary Level Examination</div>
                    <div className="olResults">
                      {!userLoading ?
                        (isData.o_level_results?.length > 0 ?
                          <Table bordered className="align-items-center stuResultTable mb-4" responsive>
                            <thead>
                              <tr>
                                <th className="stuTableSubject">Subject</th>
                                <th className="stuTableGrade stuTableGradeH">Grade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isData.o_level_results?.map((selectedClass, index) => (
                                <tr key={index}>
                                  <td className="stuTableSubject">{(selectedClass.o_level_subject.name).toUpperCase()}</td>
                                  <td className="stuTableGrade">{selectedClass.grade}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          : `Results have not been added.`) : <Skeleton />}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="eduRight">
                <Card className="stuCard">
                  <div className="alSection">
                    <div className="stuTitle">GCE Advanced Level Examination</div>
                    <div className="olResults">
                      {!userLoading ?
                        (isData.a_level_results?.length > 0 ?
                          <Table bordered className="align-items-center stuResultTable mb-4" responsive>
                            <thead>
                              <tr>
                                <th>Subject</th>
                                <th className="stuTableGrade stuTableGradeH">Grade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isData.a_level_results?.map((selectedClass, index) => (
                                <tr key={index}>
                                  <td>{(selectedClass.a_level_subject.name).toUpperCase()}</td>
                                  <td className="stuTableGrade">{selectedClass.grade}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          : `Results have not been added.`) : <Skeleton />}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <Card className="stuCard">
              <div className="stuTitle1">Qualifications</div>
              <div className="eduQualiSection">
                <div className="eduBadges">
                  {!userLoading ?
                    (allQualifications.length > 0 ? (allQualifications?.map((qualification, index) => (
                      // <Badge className="stuBadge" color="primary" key={index}>{qualification.edu_qualification.name}</Badge>
                      <FormGroup key={index} check inline className="ml-3 mb-3">
                        <Input
                          className="large-checkbox studentCheckbox"
                          type="checkbox"
                          id={index}
                          checked={receivedQualifications.includes(qualification.name)}
                          readOnly
                        />
                        <Label className="areaLabel ml-3" check htmlFor={qualification.id}>
                          {qualification.name}
                        </Label>
                      </FormGroup>
                    ))) : `Education qualifications are currently unavailable.`)
                    : <Skeleton />}
                </div>
              </div>
              {!userLoading ?
                (isData?.qualifications.length > 0 ?
                  <div className="profSection">
                    <div className="stuSubTitle1">Professional Qualifications</div>
                    {isData.qualifications?.map((qualification, index) => (
                      <p key={index} className="qualiList"><i className="fa-solid fa-medal"></i> {qualification.description}</p>
                    ))}
                  </div> : null) : <Skeleton />}
              {!userLoading ?
                (isData?.other_qualifications.length > 0 ?
                  <div className="otherSection">
                    <div className="stuSubTitle1">Other Qualifications</div>
                    {!userLoading ? (isData.other_qualifications.length > 0 ? (isData.other_qualifications?.map((qualification, index) => (
                      <p key={index} className="qualiList"><i className="fa-solid fa-medal"></i> {qualification.description}</p>
                    ))) : `Other qualifications are currently unavailable.`) : <Skeleton />}
                  </div> : null) : <Skeleton />}
            </Card>
          </>}
      </div>
      <div className="footer1">
        <div className="footerSection">
          <p>Copyright © 2024 Ape Sense • Design & Developed by
            <Link to={"https://www.ardillalabs.com/"} target="_blank"> Ardillalabs</Link>
          </p>
        </div>
      </div>
      <div className="footerMobile">
        <div className="footerSection">
          <p>Copyright © 2024 Ape Sense <br />Design & Developed by
            <Link to={"https://www.ardillalabs.com/"} target="_blank"> Ardillalabs</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Student;
